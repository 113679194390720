/*######################################################################################################################
                                                        SEARCH PAGE
######################################################################################################################*/
:root {
    --map-overlay-padding: 50px;
    --map-overlay-height: calc(100vh - var(--map-overlay-padding) - var(--search-filter-header-height) - var(--main-banner-height));
}

div#search-filter-collapse-header {
    position: fixed !important;
    top: var(--main-banner-height);
    width: 100%;
    height: var(--search-filter-header-height);
    background-color: var(--main-accent-color);
    z-index: 5;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 15px;
}

#actualize-search-in-calendar {
    margin-left: auto;
}

div#search-page-body {
    display: flex;
    min-height: calc(100vh - var(--main-banner-height));
    position: relative;
}

div#search-result-container {
    width: 50%;
    margin-top: var(--search-filter-header-height);
    height: max-content;
    min-height: calc(100vh - var(--main-banner-height) - var(--search-filter-header-height));
}

div#search-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--main-banner-height) - var(--search-filter-header-height));
}

div#search-loading > img {
    width: 20vw;
}

div#search-result-container > div#no-search-results {
    height: max-content;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

div#search-result-container.loading > div#no-search-results {
    display: none;
}

div#search-result-container > div#no-search-results > img {
    height: 200px;
}

div#search-result-container > div#no-search-results > div#no-search-result-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 1em;
}

div#search-result-container > div#no-search-results > div#no-search-result-description > div#no-search-result-header {
    color: var(--main-accent-color);
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

div#search-result-container > div#no-search-results > div#no-search-result-description > div#no-search-result-content {
    color: gray;
}

div#search-result-container > div#optional-adverts {
    display: grid;
    gap: 1em;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 260px);
    margin-top: auto;
    padding: 10px 20px 30px 20px;
}

#search-result-container > #search-result-header {
    margin-bottom: 20px;
    display: flex;
    position: relative;
    padding: 30px 20px 10px 20px;
}

#search-result-container > #search-result-header > div > div#search-result-location-label {
    color: var(--main-accent-color);
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

#search-result-container > #search-result-header > div#search-sorting {
    margin: auto 0 0 auto;
    position: relative;
}

#search-result-container > #search-result-header > div#search-sorting > button#search-sort-button {
    height: 30px;
}

#search-result-container > #search-result-header > div#search-sorting > div#search-sort-container {
    display: none;
    position: absolute;
    right: 0;
    top: 35px;
    z-index: 100;
    border: 1px solid var(--light-gray-color);
    border-radius: 1em;
    background-color: white;
    padding: 5px;
}

#search-result-container > #search-result-header > div#search-sorting > div#search-sort-container.show {
    display: grid;
}

#search-result-container > #search-result-header > div#search-sorting > div#search-sort-container > button {
    position: relative;
    text-align: left;
    padding: 2px 15px 2px 20px;
    color: gray;
    font-size: var(--small-text);
    border-radius: 12px;
    height: 24px;
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

#search-result-container > #search-result-header > div#search-sorting > div#search-sort-container > button > svg {
    position: absolute;
    left: 5px;
}

#search-result-container > #search-result-header > div#search-sorting > div#search-sort-container > button:hover {
    background-color: var(--main-accent-color);
    color: white;
}

#search-result-scroller {
    display: grid;
    gap: 15px;
    padding: 0 20px 20px 20px;
}

#search-result-scroller > a {
    width: 100% !important;
}

.search-map-container {
    width: 50%;
    background-color: lightblue;
    height: calc(100vh - (var(--main-banner-height) + var(--search-filter-header-height)));
    position: fixed !important;
    top: calc(var(--main-banner-height) + var(--search-filter-header-height));
    left: 50%;
}

.fade-out {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000088;
    z-index: 1;
}

.search-map-container.overlay {
    display: none !important;
    z-index: 2;
    width: calc(100vw - var(--map-overlay-padding));
    height: var(--map-overlay-height);
    left: 25px;
    top: calc(var(--search-filter-header-height) + var(--main-banner-height) + 25px);
    border-radius: 20px;
    box-shadow: var(--container-shadow);
}

.search-map-container.overlay.show {
    display: initial !important;
}

.map-toggle {
    height: 50px;
    min-width: 50px;
    padding: 0 20px;
    z-index: 10;
    position: fixed;
    background-color: #000000DD;
    color: white;
    top: calc(var(--map-overlay-height) + var(--search-filter-header-height) + var(--main-banner-height) - var(--map-overlay-padding));
    right: var(--map-overlay-padding);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.map-toggle > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: 10px;
}
/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (min-width: 951px) and (max-width: 1300px) {

    #search-page .search-result {
        flex-wrap: wrap;
    }

    #search-page .housing-overview {
        padding: 10px;
    }

    #search-page .search-result .main-information-container {
        flex-direction: column;
        width: 100%;
    }

    #search-page .search-result div.left-panel > div.image-slider {
        width: 100%;
        height: 0;
        padding-top: 75%;
    }

    #search-page .search-result div.left-panel > div.image-slider .main-image {
        margin-top: -75%;
        height: unset !important;
    }

    #search-page .search-result .main-information-container .housing-description-container {
        display: none;
    }

    #search-page .search-result .right-panel {
        position: absolute;
        height: calc(100% - 40px);
        top: 20px;
        right: 20px;
    }

    #search-page .search-result .right-panel > button {
        margin: 10px 10px 0 0;
    }

    #search-page .search-result div.middle-panel > div.housing-title-label,
    #search-page .search-result div.right-panel > div.price-container > .data {
        font-size: 100%;
    }

}

@media only screen and (max-width: 950px) {

    #search-result-container {
        width: 100% !important;
    }

}

@media only screen and (max-width: 750px) {

    #search-filter-collapse-header {
        justify-content: center;
    }

}

@media only screen and (max-width: 570px) {

    #search-filter-collapse-header > button > span {
        display: none;
    }

    #search-filter-collapse-header > button {
        font-size: 14px;
        padding: 0;
        width: 32px;
        height: 32px;
    }
}

@media only screen and (max-width: 500px) {

    div#search-filter-collapse-header {
        padding: 0 12px;
        gap: 12px
    }
}

@media only screen and (max-width: 400px) {

    div#search-filter-collapse-header {
        padding: 0 8px;
        gap: 8px;
    }

    #search-page div.calendar-button {
        padding: 0 8px;
    }

    #search-page div.calendar-button > button {
        display: none !important;
    }

    #search-sort-button {
        width: 32px;
        padding: 0;
    }

    #search-sort-button > span {
        display: none;
    }

    #search-result-container > #search-result-header {
        padding: 10px 8px 0 8px;
    }

    #search-result-scroller {
        padding: 0 8px 8px 8px;
    }
}