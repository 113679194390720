div.image-slider {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    width: 400px;
    height: 300px;
}

div.image-slider > div.main-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

div.image-slider > button {
    position: absolute;
    opacity: 0;
    z-index: 1;
    background-color: #00000088;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    font-size: 16px;
    top: calc(50% - 10px);
    transition: .2s ease-in-out;
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

div.image-slider > button:hover {
    opacity: 1;
}

div.image-slider:hover > button {
    opacity: 1;
}

div.image-slider > button:first-child {
    left: 5px;
}

div.image-slider > button:last-child {
    right: 5px;
}