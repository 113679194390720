/*######################################################################################################################
                                                Overview container
######################################################################################################################*/

.booking-overview-container {
    padding: 15px;
    display: grid;
    gap: 25px;
    max-width: 800px;
    min-width: 400px;
    border-radius: 20px;
}

.booking-overview-container > .booking-span-header {
    display: flex;
    position: relative;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.booking-overview-container > div.booking-span-header > div.interval.active {
    color: green;
}

.booking-overview-container > div.booking-span-header > div.housing-title {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    white-space: nowrap;
    overflow: hidden;
}

.booking-overview-container > div.booking-span-header > svg {
    transform: rotate(0);
    transition: transform .3s ease-in-out;
    position: absolute;
    right: 4px;
    top: 4px;
}

.booking-overview-container.open > div.booking-span-header > svg {
    transform: rotate(90deg);
}

.booking-overview-container > div.booking-container-body {
    display: none;

}

.booking-overview-container.open > div.booking-container-body ,
.booking-detail-container {
    display: grid;
    gap: 25px;
}

.booking-overview-container > div.booking-container-body > div.controls {
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.booking-overview-container > div.booking-container-body > div.controls > button > span {
    font-size: 10px;
}

.booking-table-header {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--main-accent-color);
    font-size: 14px;
}

.landlord .booking-table-header {
    color: var(--tertiary-accent-color);
}

.booking-overview-table > table {
    font-size: var(--small-text);
    width: 100%;
}

div.booking-detail-table > button#invoice-creator {
    margin: 2.5em 0 2.5em auto;
}

.booking-overview-table table tr {
    border-bottom: 1px solid var(--light-gray-color);
    height: 30px;
}

table.cost-table tr:first-child > td:not(:last-child),
table.cost-table tr:first-child > td:not(:last-child) {
    text-align: center;
}

.booking-overview-table table tr > td:last-child{
    text-align: right;
    padding-right: 1em;
}

table.cost-table tr:not(:first-child) > td:nth-child(4),
table.cost-table tr:not(:first-child) > td:nth-child(5) {
    text-align: right;
}

table tr.sum > td {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: black !important;
    margin-top: 10px !important;
}

table.transaction-table {
    width: 100%;
    font-size: var(--small-text);
}

table.transaction-table tr > * {
    text-align: left !important;
}

table.transaction-table td:first-child {
    color: black !important;
}

table.transaction-table td.succeeded {
    color: darkgreen !important;
}

table.transaction-table td.failed {
    color: darkred !important;
}

table.transaction-table td.processing {
    color: orange !important;
}

table.transaction-table td.pending {
    color: gray !important;
}

table.transaction-table td > svg {
    margin-left: 2px;
}
/*######################################################################################################################
                                             Booking detail table
######################################################################################################################*/
div.booking-detail-table {
    display: grid;
    gap: 20px;
}

div.booking-detail-table table {
    font-size: var(--small-text);
    width: 100%;
}

div.booking-detail-table .view-housing,
div.booking-detail-table .send-msg {
    margin-left: 5px;
}