div.notification-bell {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: var(--light-gray-color);
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

div.notification-bell > svg {
    font-size: 29px;
}

div.notification-bell > svg.fa-rotate {
    font-size: 20px;
    color: gray;
}

div.notifications > div.notification-marker {
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: var(--main-accent-color);
    color: white;
    font-size: 8px;
    font-family: urbane-rounded, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

#main-banner.landlord div.notifications > div.notification-marker {
    background-color: var(--tertiary-accent-color);
}

div.notification-center {
    top: 3.5em;
    right: calc(50% - 2em);
    overflow: hidden;
    color: black;
    max-height: 300px;
    padding: 8px 0 8px 8px;
    display: grid;
    gap: 10px;
    width: 300px;
}

div.notification-center div.notification-type-label {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: 12px;
    margin-bottom: .5em;
}

div.notification-center div.notification-label {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: .8em;
    color: var(--main-accent-color);
}

div#main-banner.landlord div.notification-center div.notification-label {
    color: var(--tertiary-accent-color);
}

div.notification-center div.notification-main-container {
    display: grid;
    gap: .8em;
    overflow-y: auto;
    max-height: calc(300px - 56px - 1.8em);
    padding-right: 1em;
}

div.no-notifications {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
}

div.no-notifications > img {
    height: 50px;
}

div.notification-center div.notifications-container {
    display: grid;
    gap: .2em;
}

div.notification-center div.notification-placeholder {
    color: gray;
    font-size: .8em;
}

.notification-item {
    padding: 5px;
    width: 275px;
    overflow: hidden;
    font-size: .8em;
    cursor: pointer;
    border-radius: 1em;
    min-height: 50px;
    border: 1px solid var(--light-gray-color);
    display: flex;
    gap: 1em;
    margin-bottom: 0 !important;
    text-align: left;
    position: relative;
}

.notification-item > div.notification-type-icon {
    width: 20px;
    height: 20px;
    border-radius: 1em;
    background-color: var(--main-accent-color);
    color: white;
    position: absolute;
    right: 2px;
    bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.landlord .notification-item > div.notification-type-icon {
    background-color: var(--tertiary-accent-color);
}

div.new-notifications > div.notifications-container > .notification-item {
    background-color: var(--main-accent-color);
    border: 1px solid var(--main-accent-color);
}

div#main-banner.landlord div.new-notifications > div.notifications-container > .notification-item {
    background-color: var(--tertiary-accent-color);
    border: 1px solid var(--tertiary-accent-color);
}

div.new-notifications > div.notifications-container > .notification-item > div.avatar-container > div.notification-type-icon {
    background-color: white;
    color: var(--main-accent-color);
}

div#main-banner.landlord div.new-notifications > div.notifications-container > .notification-item > div.avatar-container > div.notification-type-icon {
    color: var(--tertiary-accent-color);
}

.notification-item > div.notification-content {
    font-size: 10px;
    flex: 1;
    color: #D2D2D2;
    width: 230px;
}

div.new-notifications > div.notifications-container .notification-item > div.notification-content {
    color: black;
}

.notification-item > div.notification-content > div.notification-header {
    position: relative;
    min-height: 14px;
    width: 100%;
}

.notification-item > div.notification-content > div.notification-header > div.notification-timestamp {
    position: absolute;
    right: .5em;
    top: 0;
    font-family: open-sans, sans-serif;
    font-style: italic;
    font-weight: 100;
}

.notification-item > div.notification-content > div.notification-header > div.notification-title {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: 10px;
}