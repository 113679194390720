/*######################################################################################################################
                                         REGISTRATION MODAL STYLING
######################################################################################################################*/
div#main-registration {
    overflow: auto;
}

#main-registration button.outline-button.margin,
#main-registration button.accent-icon-button {
    margin-left: auto;
}

div#main-registration div.modal-body {
    overflow: hidden auto;
}

.account-type-selection {
    display: grid;
    gap: 20px
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-grid {
    justify-content: center;
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-tile:not(.landlord) {
    background-color: var(--main-accent-color-light) !important;
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-tile > div.tile-icon-container {
    color: var(--main-accent-color);
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-tile:not(.disabled):hover {
    background-color: var(--main-accent-color-medium) !important;
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-tile.landlord {
    background-color: var(--tertiary-accent-color-light-transparent);
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-tile.landlord > div.tile-icon-container {
    color: var(--tertiary-accent-color);
}

div#main-registration div.modal-body .account-type-selection .desktop-submenu-tile.landlord:not(.disabled):hover {
    background-color: var(--tertiary-accent-color-medium) !important;
}

div#main-registration #verification-code {
    width: 180px !important;
}

div#main-registration div#registration-welcome {
    display: grid;
}

div#main-registration div#registration-welcome-image {
    width: 15vw;
    height: 15vw;
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/animations/welcome.gif");
    background-position: center;
    background-size: cover;
    justify-self: center;
}

div#main-registration div#registration-welcome-text {
    font-family: open-sans-bold, sans-serif;
    text-align: center;
    font-size: 20px;
}

div#main-registration div#registration-step-1-description {
    width: 90%;
    text-align: center;
    justify-self: center;
}

div#main-registration div#verification-code-group {
    justify-self: center;
}

div#main-registration div#verification-help {
    justify-self: center;
}

div#main-registration div#verification-help button.link-button {
    font-size: var(--small-text);
}

div#main-registration div#registration-address-city-group {
    width: 70%;
    flex: none;
}

div#main-registration div#registration-address-postal-code-group {
    width: 30%;
    flex: none;
}

div#main-registration div#registration-result {
    display: grid;
}

#registration-autocomplete {
    width: 100%;
}

@media only screen and (max-width: 400px) {

    div#main-registration .verification-step {
        flex-direction: column;
        align-items: flex-end;
    }

}