div.loading {
    width: 100%;
    height: 50px;
    justify-content: center;
}

#zimmersoftware-setup-modal form > button.outline-button {
    margin: 0 auto
}

#zimmersoftware-setup table {
    width: 100%;
}

#zimmersoftware-setup table tr > th,
#zimmersoftware-setup table tr > td {
    border-bottom: 1px solid lightgrey;
    padding: 10px 5px;
}

#zimmersoftware-setup table th {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--small-text);
}

#zimmersoftware-setup table td {
    font-size: var(--small-text);
    color: gray;
}

#zimmersoftware-setup table th:last-child, th:nth-child(3) {
    text-align: center;
}

#zimmersoftware-setup table td:last-child > button {
    transition: .1s ease-in-out;
}

#zimmersoftware-setup table td:last-child > button:hover {
    color: var(--tertiary-accent-color);
}

#zimmersoftware-setup table td:nth-child(3) {
    text-align: center;
}

#zimmersoftware-setup table td:nth-child(3) svg.fa-triangle-exclamation {
    color: orange;
    margin-right: 3px;
}

#zimmersoftware-setup table td:nth-child(3) svg.fa-check {
    color: forestgreen;
    margin-right: 3px;
}

#zimmersoftware-setup table td:last-child {
    display: flex;
    justify-content: center;
}

#account-wiring {
    width: 100%;
}

#account-wiring th,
#account-wiring td {
    padding: 5px 10px;
}

#account-wiring th {
    text-align: center;
    font-size: var(--small-text);
    color: gray;
}