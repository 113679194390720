.custom-map-marker {
    min-width: 50px;
    height: 50px;
    background-color: var(--main-accent-color);
    color: white;
    border-radius: 25px;
    padding: 5px ;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    transition: .5s ease-in-out;
    z-index: 0;
}

.custom-map-marker > span {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

.custom-map-marker > img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
}

.custom-map-marker.fade-out {
    background-color: white;
    color: var(--light-gray-color);
    border: 1px solid var(--light-gray-color);
    width: max-content;
}

.custom-map-marker.focus,
.custom-map-marker-perimeter.focus {
    z-index: 100 !important;
}

.custom-map-marker > svg {
    color: white;
    transition: .5s ease-in-out;
}

.custom-map-marker.fade-out > svg {
    color: var(--light-gray-color);
}

.custom-map-marker-perimeter {
    z-index: 0;
    border-radius: 9999px;
    border: 2px solid var(--main-accent-color);
    background-color: var(--main-accent-color-transparent);
    transition: .4s ease-in-out;
}

.custom-map-marker-perimeter.fade-out {
    background-color: #FFFFFF77;
    border-color: white;
}
