/****************************  secret-input-field  ****************************/
.secret-input-field {
    flex-wrap: nowrap;
    width: max-content;
}

.secret-input-field span.input-group-text {
    border-radius: 0 16px 16px 0;
    padding: 0 7px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
}

.secret-input-field input {
    border-radius: 16px 0 0 16px !important;
    border-right: 0;
    width: 100%;
}

.secret-input-field button {
    max-height: 100%;
    color: var(--main-accent-color);
}

.landlord .secret-input-field button {
    color: var(--tertiary-accent-color);
}

/****************************  unit-input-field  ****************************/
.unit-input-field {
    flex-wrap: nowrap;
    width: max-content;
}

.unit-input-field span.input-group-text {
    border-radius: 0 16px 16px 0;
    padding: 0 7px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
}

.unit-input-field input {
    border-radius: 16px 0 0 16px !important;
    border-right: 0;
    max-width: 100px;
}

.unit-input-field svg {
    color: var(--main-accent-color);
}

.unit-input-field button {
    padding: 0;
}

.landlord .unit-input-field svg {
    color: var(--tertiary-accent-color);
}