.custom-toggle {
    width: max-content;
}

.custom-toggle input {
    display: none;
}

.custom-toggle .toggle-container {
    background-color: var(--light-gray-color);
    display: flex;
    align-items: center;
    -webkit-align-items:center;
    position: relative;
    box-shadow: inset gray 0 0 5px;
    border-radius: 9999px;
    transition: .3s ease-in-out;
    height: 20px;
    width: 36px
}

.custom-toggle:not(.disabled) .toggle-container {
    cursor: pointer;
}

.custom-toggle .toggle-container.right {
    background-color: var(--main-accent-color);
}

.landlord .custom-toggle .toggle-container.right {
    background-color: var(--tertiary-accent-color);
}

.custom-toggle .toggle {
    background-color: white;
    z-index: 1;
    position: absolute;
    left: 2px;
    border-radius: 9999px;
    width: 16px;
    height: 16px;
    transition: .1s ease-in-out;
}

.custom-toggle.right .toggle {
    left: calc(50%);
}

.toggle-container.disabled .toggle {
    background-color: #e2e2e2;
    border: 1px solid #b0b0b0;
}

.toggle-container.left .toggle {
    #animation: to-left 0.3s cubic-bezier(.18, .89, .32, 1.28) both;
    left: 2px;
}

.toggle-container.right .toggle {
    #animation: to-right 0.3s cubic-bezier(.18, .89, .32, 1.28) both;
    left: 50%;
}

@keyframes to-right {
    from {
        left: 2px;
    }
    to {
        left: calc(50%);
    }
}
@keyframes to-left {
    from {
        left: calc(50%);
    }
    to {
        left: 2px;
    }
}
