.carousel-grid {
    display: flex;
    position: relative;
    align-items: center;
}

.carousel-grid > button {
    position: absolute;
    font-size: 30px;
    color: gray;
    height: 100%;
    top: 0;
}

.carousel-grid > button.prev-slide {
    left: -40px;
}

.carousel-grid > button.next-slide {
    right: -40px;
}

@media only screen and (max-width: 1000px) {

    .carousel-grid > button {
        position: initial;
    }

}

@media only screen and (max-width: 500px) {

    .carousel-grid {
        width: 100%;
    }

}