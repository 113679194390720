.dragSortList {
    display: flex;
    gap: 3px;
}

.vertical {
    flex-direction: column;
}

.dragSortItem {
    cursor: grab;
    padding: 5px;
    border: 2px solid gray;
    border-radius: 8px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.dragSortItem > * {
    pointer-events: none;
}

.dragged {
    cursor: grabbing;
}

.dragTarget {
    border: 2px solid var(--tertiary-accent-color);
}