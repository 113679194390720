/*######################################################################################################################
                                          FAQ, TIPS AND TRICKS, LEGAL NOTICE
######################################################################################################################*/
.help-page.container {
    padding: 50px 0 20px 0;
}

.help-page.container h1 {
    color: var(--main-accent-color);
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
}

div.category-selector {
    display: grid;
    gap: 20px;
}

div.category-selector > div.form-group > input {
    display: none;
}

div.category-selector > div.form-group > input:checked + label {
    background-color: var(--main-accent-color);
    color: white;
    border: 1px solid var(--main-accent-color);
}

div.category-selector > div.form-group > label {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    margin: 0;
    text-align: center;
    width: 100%;
    height: 32px;
    display: grid;
    align-items: center;
    border-radius: 9999px;
    border: 1px solid black;
    color: black;
}

div.category-selector > div.form-group > input[type=radio] + label:before {
    display: none !important;
}

div.category-selector > div.form-group > input[type=radio]:checked + label:before {
    display: none !important;
}

div.category-selector > div.form-group > input[type=radio] + label:after {
    display: none !important;
}

div.category-selector > div.form-group > input[type=radio]:checked + label:after {
    display: none !important;
}

div.accordion-site {
    display: flex;
    gap: 25px;
}

div.accordion-site div.left-panel {
    display: grid;
    width: 280px;
    gap: 25px;
    height: max-content;
}

div.accordion-site div.left-panel > div.accordion-site-image {
    width: 100%;
    height: 280px;
    background-color: var(--quaternary-accent-color);
    border-radius: 9999px;
    overflow: hidden;
}

div.accordion-site div.left-panel > div.accordion-site-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.accordion-site div.right-panel {
    display: grid;
    flex: 1;
    gap: 10px;
}

div.accordion-site div.right-panel div.accordion-item {
    border-bottom: 1px solid lightgray;
}

div.accordion-site div.right-panel button.accordion-button {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    width: 100%;
    text-align: left;
    display: flex;
    position: relative;
}

input.keyword-search {
    width: 100%;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 1200px) {
    .help-page.container {
        padding: 50px 25px 20px 25px;
        max-width: unset;
    }
}

@media only screen and (max-width: 1100px) {

    div.accordion-site div.left-panel {
        width: 200px;
    }

    div.accordion-site div.left-panel > div.accordion-site-image {
        width: 100%;
        height: 200px;
        background-color: var(--tertiary-accent-color);
        border-radius: 9999px;
        overflow: hidden;
    }

}

@media only screen and (max-width: 750px) {

    div.accordion-site div.left-panel {
        width: 180px;
    }

    div.accordion-site div.left-panel > div.accordion-site-image {
        width: 180px;
        height: 180px;
        background-color: var(--tertiary-accent-color);
        border-radius: 9999px;
        overflow: hidden;
    }

}

@media only screen and (max-width: 650px) {

    div.accordion-site {
        flex-direction: column;
    }

    div.accordion-site div.left-panel {
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }

    div.accordion-site div.left-panel > h1 {
        grid-row: 1;
        grid-column: 1 / span 2;
    }

    div.accordion-site div.left-panel > div.accordion-site-image {
        grid-row: 2;
        grid-column: 1;
        justify-self: center;
    }

    div.accordion-site div.left-panel > div.category-selector {
        grid-row: 2;
        grid-column: 2;
        width: 100%;
        justify-self: center;
        align-self: center;
        height: max-content;
    }

}

@media only screen and (max-width: 500px) {
    .help-page.container {
        padding: 30px 10px 20px 10px;
        max-width: unset;
    }
}