.widget {
    width: 100%;
    height: 100%;
}

/************************************************** widget header ******************************************************/
.widget > div.widget-header {
    display: flex;
    align-items: center;
    font-size: var(--small-text);
    width: 100%;
    height: var(--widget-header-height);
    padding: 0 10px;
}

.widget > div.widget-header > span {
    font-size: var(--small-text);
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

.widget > div.widget-header > svg {
    margin-right: 5px;
    color: var(--main-accent-color);
    font-size: 18px;
}

.landlord .widget > div.widget-header > svg {
    color : var(--tertiary-accent-color);
}

/*************************************************** widget body ******************************************************/

.widget > .widget-body {
    flex: 1;
    height: calc(100% - var(--widget-header-height) - var(--widget-footer-height));
}

.widget.no-header:not(.no-footer) > .widget-body {
    height: calc(100% - var(--widget-footer-height));
}

.widget.no-footer:not(.no-header) > .widget-body {
    height: calc(100% - var(--widget-header-height));
}

.widget.no-header.no-footer > .widget-body {
    height: 100%;
}

.widget > .widget-body.edit,
.widget > .widget-body.loading,
.widget > .widget-body.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget > .widget-body.loading > svg {
    color: var(--main-accent-color);
    opacity: .5;
    font-size: 40px;
}

.widget > .widget-body .statistic-display {
    box-shadow: none !important;
}

/************************************************** widget footer *****************************************************/
.widget.loading > div.widget-footer > * {
    display: none;
}


.widget > div.widget-footer {
    color: var(--main-accent-color);
    width: 100%;
    height: var(--widget-footer-height);
    padding: 10px 10px;
}

.widget > div.widget-footer button {
    height: 24px !important;
    margin-left: auto;
}

.widget > div.widget-footer button > span {
    font-size: var(--button-small-font);
}

.widget > div.widget-footer button > svg {
    font-size: 12px;
}

.widget > div.widget-footer .accent-radio-button {
    height: 24px !important;
}

.widget > div.widget-footer .accent-radio-button > label {
    padding: 3px 10px;
    font-size: 10px;
}

.widget div.widget-footer.horizontal {
    display: flex;
    align-items: center;
    gap: 1em;
}

/************************************************ carousel widgets ****************************************************/
.widget-body .carousel {
    height: 100%;
}

.widget-body .carousel > .carousel-inner {
    height: 100%;
}

.widget-body .carousel .carousel-item {
    height: 100%;
}

.widget-body .carousel .carousel-item > .carousel-caption {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    padding: 0;
    text-align: justify;
}

.widget-body .carousel .carousel-item > .carousel-caption .roomjack-headline {
    font-size: 12px;
    color: var(--main-accent-color);
    margin-bottom: 5px;
}

.landlord .widget-body .carousel .carousel-item > .carousel-caption .roomjack-headline {
    color: var(--tertiary-accent-color);
}

.widget-body .carousel > a {
    color: gray !important;
}

/************************************************** message widget ****************************************************/

.widget-grid {
    overflow-y: auto;
    display: grid;
    grid-gap: .4em;
    padding: .5em;
    height: min-content;
    max-height: 100%;
}

.msg-preview-container {
    display: flex;
    height: 40px;
    box-shadow: var(--container-shadow-light);
    padding: 3px 10px 3px 3px;
    border-radius: 25px;
    width: 100%;
    align-items: center;
    color: gray;
    cursor: pointer;
    gap: 8px;
    text-align: left;
}

.msg-preview-container.new {
    background-color: var(--main-accent-color);
    color: black;
}

.landlord .msg-preview-container.new {
    background-color: var(--tertiary-accent-color);
}

.msg-preview-container .message-preview-data {
    flex: 1;
    position: relative;
}

.msg-preview-container div.message-preview-data > .name-container {
    font-size: 11px;
    font-weight: bold;
}

.msg-preview-container .message-info-container {
    font-size: 10px;
    display: flex;
    width: 100%;
}

.msg-preview-container .message-info-container > .message-container {
    overflow: hidden;
    flex: 1;
}

.msg-preview-container .message-info-container > .message-container span {
    white-space: nowrap;
}

.msg-preview-container .message-info-container > .message-container span > svg {
    margin-right: 3px;
}

.msg-preview-container .message-info-container > .message-container > span.highlight {
    color: white;
    text-decoration: underline;
    margin: 0 3px;
}

.msg-preview-container .message-info-container > .timestamp-container {
    margin-left: auto;
    width: max-content;
    font-family: open-sans-italic, sans-serif;
}

.msg-preview-container .message-preview-data .unread-counter {
    color: white;
    position: absolute;
    top: 3px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-family: urbane-rounded, sans-serif;
}

.landlord .msg-preview-container .message-preview-data .unread-counter {
    background-color: var(--tertiary-accent-color);
}

/************************************************** todo_list widget **************************************************/
.widget-body.todo-list {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0 20px;
}

.widget-body.todo-list > .todo-counter {
    display: flex;
    flex-direction: column;
    height: 120px;
    background-color: var(--tertiary-accent-color-light-transparent);
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
    flex: 1;
    max-width: 120px;
    min-width: 120px;
}

.widget-body.todo-list > div.todo-counter.not-zero {
    background-color: var(--tertiary-accent-color);
}

.widget-body.todo-list > div.todo-counter svg {
    font-size: 30px;
    color: var(--tertiary-accent-color);
}

.widget-body.todo-list > div.todo-counter.not-zero svg {
    color: white;
}

.widget-body.todo-list > div.todo-counter div.counter {
    font-size: 20px;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
    color: var(--tertiary-accent-color);
    line-height: 16px;
}

.widget-body.todo-list > div.todo-counter.not-zero div.counter {
    color: white;
}

.widget-body.todo-list > div.todo-counter > div.label {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
    font-size: 12px;
}

/****************************************** customer satisfaction widget **********************************************/
/********************************************** click-booking widget **************************************************/
.widget-body.customer-satisfaction,
.widget-body.click-booking {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
}

/************************************************ booking info widget *************************************************/
/************************************************ booker info widget **************************************************/
.widget-body.booker-info,
.widget-body.booking-info {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.widget-body div.booking-overview-table {
    width: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25px;
    flex: 1;
    overflow-y: auto;
}

.widget-body div.previous-booking-info,
.widget-body div.next-booking-info {
    height: 100%;
    width: 25px;
    display: flex;
    align-items: center;
    position: absolute;
}

.widget-body .previous-booking-info {
    justify-content: left;
}

.widget-body > .next-booking-info {
    justify-content: right;
}

.widget-body.booker-info td:first-child,
.widget-body.booking-info td:first-child {
    min-width: 150px;
    width: 150px;
}

.widget-body.booker-info td > svg,
.widget-body.booking-info td > svg {
    margin-right: 5px;
    color: var(--main-accent-color);
}

.landlord .widget-body.booker-info td > svg,
.landlord .widget-body.booking-info td > svg {
    color: var(--tertiary-accent-color);
}

.widget-body td:last-child > button.link-button,
.widget-body td:last-child > a,
.widget-body td:last-child > a > span {
    margin-left: 3px;
}

.widget-body.booking-info .horizontal-form-group {
    width: max-content;
    margin-left: auto;
}

.widget-body.booking-info .horizontal-form-group > input {
    height: 24px !important;
}

.widget-body.booking-info .horizontal-form-group > button {
    width: 24px !important;
    height: 24px !important;
}

/************************************************** search widget *****************************************************/
.widget-body .search-box {
    display: grid;
    gap: 10px;
    background-color: var(--main-accent-color);
    border-radius: 16px;
    width: max-content;
    height: max-content;
    justify-items: center;
    padding: 10px;
}

.widget-body .search-box .custom-dropdown {
    width: 100%;
}

/****************************************** personalize dashboard widget **********************************************/
.widget-body.personalize-dashboard {
    padding: 0 20px;
}

.widget-body.personalize-dashboard .roomjack-container-header {
    text-align: center;
}

.widget-body.personalize-dashboard path {
    fill: transparent;
    stroke: black;
    stroke-width: 3px;
    stroke-linecap: round;
}

.widget-body.personalize-dashboard path.line {
    stroke-dasharray: 10;
}

/*********************************************** create advert widget *************************************************/
.widget-body.create-advert {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.widget-body img {
    height: 100px;
}

@media only screen and (max-width: 1320px) {

    .widget .interval-button-container {
        display: none;
    }

}

@media only screen and (max-width: 1250px) {

    .widget div.statistic-display > div.statistic-display-headline {
        height: unset;
    }

    .widget div.rating-average-container > div.rating-value > svg {
        font-size: 25px;
    }

    .widget div.rating-average-container > div.rating-value > span {
        font-size: 25px;
    }

}

@media only screen and (max-width: 1100px) {

    .widget-body .carousel .carousel-item > .carousel-caption {
        text-align: left;
    }

    .widget > .widget-body.customer-satisfaction,
    .widget > .widget-body.click-booking  {
        flex-wrap: wrap;
    }

    .widget-body.booker-info td .horizontal-form-group input,
    .widget-body.booking-info td .horizontal-form-group input {
        width: 150px;
    }

    .widget-body div.booking-overview-table {
        margin: 0;
    }

}

@media only screen and (max-width: 750px) {

    .widget-body.booker-info td:first-child,
    .widget-body.booking-info td:first-child {
        min-width: 50px;
        width: 50px;
    }

    .widget-body.booker-info td:first-child > span,
    .widget-body.booking-info td:first-child > span {
        display: none;
    }

}