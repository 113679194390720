/*######################################################################################################################
                                           REPORT MODAL STYLING
######################################################################################################################*/
.report-modal div.report-modal-body {
    width: 100%;
}

.report-modal div.report-modal-body > div.form-group {
    display: flex;
    align-items: center;
}

.report-modal div.report-modal-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.report-modal .report-name-block {
    display: flex;
    gap: 10px;
}

.report-modal .public-session-fieldset input[name=email] {
    width: 300px;
}

.report-modal .public-session-fieldset div.phone-input-field {
    width: 300px;
}

.report-modal div.process-result-image {
    border-radius: 9999px;
    overflow: hidden;
}

.send-report {
    margin-left: auto;
}