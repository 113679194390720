/*######################################################################################################################
                                                    EXPOSE STYLING
######################################################################################################################*/
#expose {
    display: block;
    height: max-content;
    min-height: calc(100vh - var(--main-banner-height));
    padding: 50px 0 20px 0;
}

#expose > div.container {
    position: relative;
    display: grid;
    gap: 25px;
}

#expose > div.container > div {
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 25px;
}

#expose > div.container .expose-main-column {
    display: grid;
    gap: 25px;
    height: max-content;
}

#expose > div.container .expose-side-column {
    display: grid;
    height: max-content;
}

#expose > div.container > .expose-header {
    position: relative;
    background-color: white;
    grid-column-start: 1;
}

#expose > div.container > .expose-header > div.expose-main-column {
    gap: unset !important;
}

#expose > div.container > .expose-header > div.advert-registration-no {
    font-size: .8em;
    position: absolute;
    right: 0;
    top: 2.2em;
    color: var(--light-gray-color);
}

#expose > div.container > .expose-header .housing-type-label {
    font-size: .8em;
    color: #b0b0b0;
}

#expose > div.container > .expose-header h1 {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

#expose > div.container > .expose-header .housing-good-to-know {
    display: flex;
    gap: 2em;
}

#expose > div.container > .expose-header .housing-good-to-know > div > svg{
    margin-right: .2em;
    color: #b0b0b0;
}

#expose > div.container > .expose-header .housing-good-to-know > div:first-child > svg{
    color: var(--main-accent-color);
}

#expose > div.container > .expose-header .housing-good-to-know > div > span {
    margin-right: .3em;
    color: #b0b0b0;
    font-size: .7em;
    text-transform: uppercase;
}

#expose > div.container > .expose-header .housing-good-to-know > div > span:last-child {
    margin-right: .3em;
    color: black;
    font-family: urbane-rounded, sans-serif;
    font-size: .8em;
    text-transform: initial;
}

#expose > div.container > .expose-header > div.expose-side-column {
    display: flex;
    height: 100%;
    align-items: flex-end;
    gap: 1em;
}

#expose > div.container > .expose-header > div.expose-main-column div.small-screen-header-buttons {
    display: none;
}

.expose-header > div.expose-side-column > button,
.expose-header > div.expose-main-column div.small-screen-header-buttons > button {
    display: flex;
    flex: initial;
    width: max-content;
    gap: .3em;
    align-items: center;
    justify-self: flex-end;
    cursor: pointer;
}

.expose-header > div.expose-side-column > button:first-child{
    margin-left: auto;
}

.expose-header > div.expose-side-column > button > svg,
.expose-header > div.expose-main-column div.small-screen-header-buttons > button > svg {
    color: var(--main-accent-color);
    font-size: 16px;
}

div#main-body.landlord .expose-header > div.expose-side-column > button > svg,
div#main-body.landlord .expose-header > div.expose-main-column div.small-screen-header-buttons > button > svg{
    color: var(--tertiary-accent-color);
}

.expose-header > div.expose-side-column > button > svg.inner:not(.selected),
.expose-header > div.expose-main-column div.small-screen-header-buttons > button > svg.inner:not(.selected) {
    opacity: 0;
}

.expose-header > div.expose-side-column > button > svg.inner,
.expose-header > div.expose-main-column div.small-screen-header-buttons > button > svg.inner {
    font-size: 16px;
    top: 0;
    left: 0;
}

.expose-header > div.expose-side-column > button > span,
.expose-header > div.expose-main-column div.small-screen-header-buttons > button > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: .7em;
}

#expose > div.container > .housing-image-viewer {
    position: relative;
    background-color: white;
    z-index: 1;
    grid-column-start: 1;
}

#expose > div.container > .housing-image-viewer > div.expose-main-column {
    width: 100%;
    height: 525px;
    border-radius: 12px;
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/content-images/placeholder-image.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
    box-shadow: var(--container-shadow);
    overflow: hidden;
}

#expose > div.container > .housing-image-viewer > div.expose-main-column > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#expose > div.container > .housing-image-viewer > div.expose-side-column {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

#expose > div.container > .housing-image-viewer > div.expose-side-column > div.next-image {
    border-radius: 12px;
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/content-images/placeholder-image.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
    box-shadow: var(--container-shadow);
    overflow: hidden;
    height: 250px;
}

#expose > div.container > .housing-image-viewer > div.expose-main-column > img,
#expose > div.container > .housing-image-viewer > div.expose-side-column > div.next-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

#expose div.housing-special-suitability {
    display: flex;
    padding: 0 !important;
}

#expose div.housing-special-suitability > div {
    width: 100%;
    height: 100%;
}

#expose div.housing-special-suitability > div.left-side {
    text-transform: uppercase;
    padding: 1em 0 1em 1em;
    display: flex;
    flex-direction: column;
}

#expose div.housing-special-suitability > div.left-side div.pre-text {
    font-family: urbane-rounded, sans-serif;
    font-size: .8em;
}

#expose div.housing-special-suitability > div.left-side div.role-label {
    font-family: urbane-rounded, sans-serif;
    font-size: 1.2em;
    color: white;
}

#expose div.housing-special-suitability > div.left-side div.control-bar {
    display: flex;
    gap: .5em;
    margin-top: auto;
    padding-top: 10px;
}

#expose div.housing-special-suitability > div.left-side div.control-bar div.form-group {
    width: 12px;
    height: 12px;
}

#expose div.housing-special-suitability > div.left-side div.control-bar div.form-group > label::before {
    background-color: #00000044 !important;
    width: 12px;
    height: 12px;
    border: none;
    cursor: pointer;
    left: 0 !important;
    top: 0 !important;
}

#expose div.housing-special-suitability > div.left-side div.control-bar div.form-group > label::after {
    background-color: white !important;
    width: 12px;
    height: 12px;
    left: 0 !important;
    top: 0 !important;
}

#expose div.housing-special-suitability > div.role-image-container > img {
    height: 110px;
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: center;
}

#expose > div.container > div.housing-detail-container > div.expose-main-column > div.roomjack-container {
    word-break: break-word;
}

#expose > div.container > div.housing-detail-container > div.expose-main-column > div.roomjack-container > h4 {
    color: var(--main-accent-color);
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    margin-bottom: 1em;
}

#expose div.housing-description {
    display: grid;
    gap: 20px;
    color: gray;
    font-size: var(--small-text);
}

#expose div.housing-description > button {
    display: none;
    justify-self: center;
}


#expose div.block > div.housing-text-container {
    color: #b0b0b0b0;
}

#expose .housing-detail-container > div.expose-side-column {
    height: 100% !important;
}

#expose .jack-out-container {
    width: 100%;
    border-radius: 16px;
    background-color: var(--main-accent-color);
    position: sticky;
    top: calc(var(--main-banner-height) + 2em);
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--container-shadow);
    height: 350px;
}

#expose .jack-out-container > div.price-per-unit-label {
    color: var(--secondary-accent-color);
    font-size: var(--small-text);
}

#expose .jack-out-container > div.price-per-unit {
    text-transform: uppercase;
    color: white;
    font-family: urbane-rounded, sans-serif;
    font-size: 24px;
}

#expose .jack-out-container > div.price-sum-label {
    color: var(--secondary-accent-color);
    font-size: var(--small-text);
    margin-bottom: 30px;
}

#expose .jack-out-container > div.calendar {
    align-self: center;
    height: max-content;
}

#expose div.jack-out-container > .availability-message-container {
    color: white;
    font-size: .8em;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    margin-top: 20px;
    width: 80%;
    text-align: center;
    align-self: center;
}

#expose .jack-out-container > button.check-availability-button,
#expose .jack-out-container > button.start-booking-checkout-button {
    align-self: center;
    margin-top: auto;
}

#expose .jack-out-container > button:not(.start-booking-checkout-button):not(.check-availability-button){
    display: none;
    position: absolute;
    top: 10px;
    color: white;
    font-size: 14px;
}

#expose .jack-out-container > button.show-jack-out {
    right: 10px;
}

#expose .jack-out-container > button.hide-jack-out {
    right: 0;
    width: 40px !important;
}

#expose div.interactive {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: calc(350px * 1.05) calc(350px * 1.05);
}

#expose div.interactive > div {
    box-shadow: var(--container-shadow);
}

#expose .housing-tile {
    border-radius: 1em;
    display: grid;
    grid-template-columns: 100%;
    padding: 25px;
    width: 100%;
    height: 100%;
    gap: 15px;
}

#expose .housing-tile {
    border-radius: 1em;
}

#expose .housing-tile > * {
    justify-self: center;
}

#expose .housing-tile > img:not(.rounded) {
    width: 64%;
    aspect-ratio: 1;
    background-size: contain;
}

#expose .housing-tile > div.owner-avatar {
    width: 128px;
    height: 128px;
    border-radius: 9999px !important;
    background-repeat: no-repeat;
    background-color: white;
    cursor: pointer;
}

#expose .housing-tile > div.owner-avatar > a {
    width: 100%;
    height: 100%;
    display: block;
}

#expose .housing-tile.share > div.image-mask {
    width: 128px;
    height: 160px;
    margin-top: -2em;
    border-radius: 0 0 9999px 9999px !important;
    overflow: hidden;
    position: relative;
}

#expose .housing-tile.share > div.image-mask > div.image-background {
    background-color: white;
    border-radius: 9999px !important;
    width: 100%;
    height: 128px;
    position: absolute;
    bottom: 0;
}

#expose .housing-tile.share > div.image-mask > img.rounded {
    width: 128px;
    height: 140px;
    object-fit: contain;
    position: absolute;
    bottom: 0;
}

#expose .housing-tile > .housing-tile-label {
    font-size: 18px;
    font-family: urbane-rounded, sans-serif;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

#expose .housing-tile > .owner-languages {
    font-size: var(--small-text);
    color: white !important;
    text-align: center;
}

#expose .housing-tile > button {
    font-size: var(--button-small-font);
    margin-top: auto;
}

#expose .housing-tile > .housing-tile-detail-container {
    display: flex;
    gap: .5em;
    justify-content: center;
}

#expose .housing-tile.blueprint {
    background-color: #f8f9fa;
}

#expose .housing-tile.map {
    grid-column: span 2;
}

#expose .housing-tile.contact {
    background-color: var(--tertiary-accent-color);
}

#expose .housing-tile.share {
    background-color: var(--main-accent-color);
}

#expose .housing-tile.share > .housing-tile-detail-container {
    color: white;
}

#expose div.housing-equipment-grid:not(.empty),
#expose div.characteristics-grid:not(.empty),
#expose div.housing-house-rules-grid:not(.empty) {
    display: grid;
    grid-template-columns: minmax(300px, 50%) minmax(300px, 50%);
    margin-bottom: 1em;
    row-gap: 20px;
}

#expose div.housing-equipment-grid:not(.empty) > button,
#expose div.characteristics-grid:not(.empty) > button,
#expose div.housing-house-rules-grid:not(.empty) > button {
    grid-column: 1 / span 2;
    width: max-content;
    justify-self: center;
}

#expose div.housing-house-rules-grid > * {
    grid-column-start: 1;
}


#expose div.housing-equipment-grid > div.housing-equipment-item {
    display: flex;
    gap: .5em;
    align-items: center;
    font-weight: bolder;
}

#expose div.housing-equipment-grid > div.housing-equipment-item > svg {
    color: var(--tertiary-accent-color);
}

#expose .housing-review-grid {
    height: max-content;
    width: 100%;
    position: inherit;
    background-color: white;
    display: flex;
    gap: 25px;
}

#expose .housing-review-grid > div {
    width: 100%;
    display: grid;
    gap: 25px;
}

#expose table.progress-bar-table tr {
    display: flex;
    width: 100%;
    align-items: center;
}

#expose table.progress-bar-table tr > td:first-child {
    width: 240px;
}

#expose table.progress-bar-table tr > td:nth-child(2) {
    flex: 1;
}

#expose table.progress-bar-table tr > td:last-child {
    font-family: urbane-rounded, sans-serif;
    width: 30px;
}

#expose div.housing-review-container > div.review-viewer {
    grid-column: 1 / span 2;
}

/*############################################## SHARE MODAL STYLING #################################################*/
div.share-modal div.modal-body {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

div.share-modal div.modal-body > label {
    margin-top: 1em;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
}

div.share-modal div.modal-body > div {
    display: flex;
    gap:.5em;
}

div.share-modal div.modal-body > div > input {
    flex: 1;
}

a.share-button {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color .2s ease-in, background-color .2s ease-in;
    border-radius: 9999px;
    font-size: var(--button-small-font);
    text-decoration: none;
}

a.share-button > span {
    font-family: open-sans-bold, sans-serif;
    transition: color .2s ease-in, background-color .2s ease-in;
    color: white !important;
    font-size: var(--small-text);
}

a.share-button > svg {
    color: white !important;
    position: absolute;
    left: 30px;
    font-size: 12px;
}

a.share-button.facebook {
    background-color: var(--facebook-color);
}

a.share-button.whatsapp {
    background-color: var(--whatsapp-color);
}

a.share-button.twitter {
    background-color: var(--twitter-color);
}

a.share-button.email {
    background-color: var(--main-accent-color);
    color: black;
}

a.share-button:hover {
    text-decoration: none;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 1200px) {

    #expose > div.container {
        margin: 0;
        width: 100%;
        max-width: 100%;
        padding: 0 25px;
    }

}

@media only screen and (max-width: 990px) {

    #expose > div.container > div:not(.housing-special-suitability) {
        grid-template-columns: 1fr;
    }

    #expose > div.container > div > div.expose-side-column:not(.jack-out) {
        display: none !important;
    }

    #expose > div.container > .expose-header > .expose-main-column > h1 {
        max-width: 70%;
    }

    .expose-header > div.expose-main-column div.small-screen-header-buttons {
        display: flex !important;
        height: 100% !important;
        align-items: flex-end;
        gap: 1em;
        margin-left: auto;
    }

    #expose > div.container > div > div.expose-side-column.jack-out {
        position: fixed;
        bottom: 0;
        right: 0;
        height: max-content !important;
        z-index: 9;
        margin-left: -25px;
        display: block;
    }

    #expose .jack-out-container {
        border-radius: 16px 0 0 0;
        box-shadow: var(--container-shadow-dark);
    }

    #expose .jack-out-container:not(.collapsed) > button.hide-jack-out {
        display: initial;
    }

    #expose .jack-out-container.collapsed > button.show-jack-out {
        display: initial;
    }

    #expose .jack-out-container {
        margin-left: auto;
    }

    #expose .jack-out-container,
    #expose .jack-out-container > * {
        transition: .1s ease-in-out;
        transform-origin: bottom right;
    }

    #expose .jack-out-container.collapsed {
        height: max-content;
    }

    #expose .jack-out-container.collapsed > *:not(button.show-jack-out):not(.price-per-unit-label):not(.price-per-unit):not(.price-sum-label) {
        display: none;
    }

}

@media only screen and (max-width: 750px) {

    .expose-header > div.expose-main-column div.small-screen-header-buttons > button > span {
        display: none;
    }

    #expose div.housing-description > button {
        display: initial;
    }

}

@media only screen and (max-width: 700px) {

    #expose div.housing-equipment-grid:not(.empty),
    #expose div.characteristics-grid:not(.empty),
    #expose div.housing-house-rules-grid:not(.empty) {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 1em;
        row-gap: 20px;
    }

}

@media only screen and (max-width: 650px) {

    #expose div.housing-special-suitability .role-image-container {
        display: none;
    }

    #expose > div.container > .housing-image-viewer > div.expose-main-column {
        height: 400px;
    }

    #expose div.interactive > .housing-tile {
        grid-column: span 2 !important;
    }

}

@media only screen and (max-width: 600px) {

    #expose div.housing-good-to-know > div > span:nth-child(2) {
        display: none;
    }

}

@media only screen and (max-width: 500px) {

    #expose div.advert-registration-no {
        display: none;
    }

    #expose > div.container > .housing-image-viewer > div.expose-main-column {
        height: 320px;
    }

    #expose > div.container > div > div.expose-side-column.jack-out {
        width: 100%;
        margin-left: -25px;
    }

    #expose .jack-out-container {
        border-radius: 16px 16px 0 0;
    }

}