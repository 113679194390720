/*######################################################################################################################
                                            CONTENT PAGE STYLING
######################################################################################################################*/
div.content-page {
    padding: 5em 0;
}

div.content-page > div.container {
    max-width: 912px;
}

div.content-page h1 {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--main-accent-color);
}

div.content-page div.area > div > strong:not(:first-child)::before {
    content: "\a";
    white-space: pre;
}

div.content-page div.content-description {
    font-size: 18px;
    color: gray;
}

div.content-page p {
    color: gray;
    font-size: 18px !important;
    text-align: justify;
}

div.content-page div.feature-text-content {
    font-size: 18px !important;
}

div.content-page li {
    color: gray;
}

div#main-body.landlord div.content-page h1 {
    color: var(--tertiary-accent-color);
}

div.content-page h2 {
    margin-top: 2em;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: black;
    text-align: center;
}

div.content-page div#to-advert-process {
    display: flex;
    justify-content: center;
    margin-top: 2em;
}

div#content-b2b > div.container > h1 {
    text-align: center;
    color: black;
}

div#content-b2b > div.container >  div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div#content-b2b > div.container > div > p {
    margin-top: 5em;
}

div#content-b2b > div.container > div > div.image-pull-in {
    margin: 3em 0;
}

#content-b2b > div.container > div > button {
    font-size: 1em;
    height: max-content !important;
    width: max-content;
    padding: 8px 16px;
}

div.content-page-headline-text {
    margin-top: 2em;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: black;
    text-align: center;
    font-size: 1.5em;
}

div#rent-responsible #to-advert-process > button {
    font-size: .8em;
    padding: 1em 2em;
    height: max-content !important;
}

#content-legal-notice {
    padding: 2em 0;
}

#content-legal-notice h1 {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

#content-legal-notice > div {
    margin-top: 2em;
}

#content-legal-notice div > h2 {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: left !important;
}

#content-legal-notice p {
    color: gray;
}

#content-linz,
#content-roomjack-is-green,
#content-heidenheim,
#content-horb {
    margin-top: -5em;
}

#content-linz h1,
#content-roomjack-is-green h1,
#content-heidenheim h1,
#content-horb h1 {
    margin: 80px 0 50px 0;
}

.content-page div.parallax {
    height: 500px;
    background-size: cover;
    background-position: center bottom;
}

#content-roomjack-is-green .roomjack-feature:first-child {
    padding: 0 0 30px 0;
}

.content-page div.parallax > span.parallax-text {
    position: relative;
}

.content-page div.parallax > span.parallax-text > span:not(.copyright) {
    font-family: urbane-rounded, sans-serif;
    color: white;
    position: relative;
}

#content-linz div.content-description:first-child {
    text-align: justify;
}

#content-linz div.content-description:last-child {
    text-align: center;
}

#content-linz div.content-page-block p {
    text-align: justify;
    margin-top: 2em;
}

#content-roomjack-is-green h1 {
    color: black !important;
    text-align: center;
}

#content-roomjack-is-green p {
    margin-top: 2em;
    text-align: justify;
}

#content-heidenheim .content-description,
#content-horb .content-description {
    margin-bottom: 30px;
}

div.image-pull-in {
    display: grid;
    gap: 1.5em;
    width: 100%;
}

div.image-pull-in > div.image-pull-in-row {
    display: flex;
    height: 318px;
    gap: 1.5em;
    align-items: center;
    position: relative;
    width: 100%;
}

div.image-pull-in > div.image-pull-in-row:first-child {
    align-items: flex-end;
}

div.image-pull-in > div.image-pull-in-row:last-child {
    align-items: flex-start;
}

div.image-pull-in > div.image-pull-in-row > img {
    background-repeat: no-repeat;
    object-fit: contain;
    border-radius: 1em;
    transition: .7s ease-in-out;
    position: absolute;
}

div.image-pull-in > div.image-pull-in-row > img.big {
    width: 450px;
    height: 318px;
}

div.image-pull-in > div.image-pull-in-row > img.small {
    width: 210px;
    height: 265px;
}

div.image-pull-in > div.image-pull-in-row > img.big {
    right: 75%;
}

div.image-pull-in > div.image-pull-in-row > img.small {
    right: 100%;
}

div.image-pull-in > div.image-pull-in-row > img.small + img.big {
    left: 90%;
}

div.image-pull-in > div.image-pull-in-row > img.big + img.small {
    left: 90%;
}

div.image-pull-in:hover > div.image-pull-in-row > img.big {
    right: calc(45% + .75em);
}

div.image-pull-in:hover > div.image-pull-in-row > img.small {
    right: calc(55% + .75em);
}

div.image-pull-in:hover > div.image-pull-in-row > img.big + img.small {
    left: calc(55% + .75em);
}

div.image-pull-in:hover > div.image-pull-in-row > img.small + img.big {
    left: calc(45% + .75em);
}

#partners h2 {
    margin-bottom: 20px;
}

#partners .partner-container {
    text-align: center;
}

#partners .partner-container img {
    width: 200px;
}

@media only screen and (max-width: 950px) {

    #content-linz h1,
    #content-roomjack-is-green h1,
    #content-heidenheim h1,
    #content-horb h1 {
        margin: 60px 0 40px 0;
    }

    .content-page div.parallax {
        height: 400px;
    }

    .content-page .parallax-text {
        font-size: 30px;
    }

}

@media only screen and (max-width: 750px) {

    #content-linz h1,
    #content-roomjack-is-green h1,
    #content-heidenheim h1,
    #content-horb h1 {
        margin: 50px 0 30px 0;
    }

    .content-page div.parallax {
        height: 350px;
    }

    .content-page .parallax-text {
        font-size: 27px;
    }

}

@media only screen and (max-width: 600px) {

    .content-page .parallax-text {
        text-align: center;
    }

    #content-roomjack-is-green .parallax-text {
        width: 60%;
    }
}

@media only screen and (max-width: 500px) {

    #content-linz h1,
    #content-roomjack-is-green h1,
    #content-heidenheim h1,
    #content-horb h1 {
        margin: 40px 0 40px 0;
    }

    .content-page div.parallax {
        height: 300px;
    }
}