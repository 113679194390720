.booking-modal .modal-body .container-fluid {
    display: flex !important;
    gap: 25px;
    height: max-content;
    padding: 0 !important;
}

.booking-modal .modal-body .container-fluid > * {
    height: max-content;
    max-width: 50%;
}

.booking-modal .modal-body .basic-booking-information {
    display: grid;
    gap: 25px;
}

.booking-data {
    grid-column: 1;
    grid-row: 1;
}

.booking-modal .booking-payment {
    grid-column: 1;
    grid-row: 2;
    display: grid;
    gap: 10px;
}

.booking-cancellation-conditions {
    grid-column: 1;
    grid-row: 3;
}

.booking-data > table {
    width: 100%;
}

.booking-data > table td {
    width: 50%;
    padding: 10px 0;
}

.booking-data > table td:last-child {
    text-align: left;
}

.booking-data > .description-container {
    margin-top: 20px;
}

.booking-cancellation-conditions > div:not(.roomjack-container-header):not(:last-child) {
    margin-bottom: 15px;
}

.booking-modal .slide-container {
    grid-column: 2;
    position: sticky;
    top: 25px;
    display: grid;
    gap: 25px;
    grid-row: 1 / span 3;
}

.booking-checkout-overview {
    display: grid;
    gap: 10px;
}

.booking-checkout-overview .booking-overview-listing table {
    width: 100%;
}

.booking-checkout-overview .booking-overview-listing table tr:last-child {
    border-top: 1px solid lightgray;
}

.booking-checkout-overview .booking-overview-listing table tr > td {
    padding: 5px 0;
}

.booking-checkout-overview .booking-overview-listing table tr > td:last-child {
    text-align: right;
}

.booking-checkout-overview .booking-overview-listing table tr:not(.sum) > td {
    font-size: var(--small-text);
    color: gray;
}

.booking-modal .discount-container {
    display: grid;
    gap: 5px;
}

.booking-modal .discount-container > .description-container {
    text-align: center;
}

.booking-modal .discount-container input {
    flex: 1;
}

.booking-checkout-overview > .cancellation-condition {
    text-align: center;
}

.booking-checkout-overview > button {
    width: max-content;
    justify-self: center;
    padding: 15px;
}

.booking-modal .payment-security-notice {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking-modal .payment-security-notice > svg {
    margin: 0 20px;
    font-size: 50px;
    color: var(--main-accent-color);
}

.booking-modal .payment-security-notice > div {
    color: gray;
}

.charge-overview table {
    width: 100%;
    margin-bottom: 20px;
}

.charge-overview table th {
    color: black;
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
}

.charge-overview table tr > * {
    padding: 5px 0;
}

.charge-overview table tr > *:first-child {
    width: auto;
}

.charge-overview table tr > *:last-child {
    width: max-content;
    text-align: right;
}

.booking-modal .roomjack-container-header {
    margin-bottom: 0 !important;
}

table tr.sum > td {
    padding-top: 10px !important;
}

@media only screen and (max-width: 992px) {

    .booking-modal .modal-body .container-fluid > * {
        max-width: 100%;
    }

    .booking-modal .modal-body .container-fluid {
        flex-wrap: wrap;
    }

}

@media only screen and (max-width: 500px) {

    .booking-modal .payment-security-notice > div {
        font-size: var(--small-text);
    }

}