#main-recovery div.modal-body button {
    width: 100%;
}

#recovery-mail-info {
    text-align: justify;
}

#recovery-mail-message {
    padding: 0 12px;
}