#main-login div.modal-header > h4 {
    color: var(--main-accent-color);
}

#main-login div.modal.landlord div.modal-header > h4 {
    color: var(--tertiary-accent-color);
}

#main-login div.modal-body > div {
    display: grid;
    gap : 20px;
    justify-items: center;
}

#main-login div.modal-body > div > form {
    width: 100%;
}

#main-login div.modal-body > div > form > div.login-input {
    display: grid;
}

#main-login div.modal-body .secret-input-field {
    width: 100% !important;
}

#login-button {
    width: 100%;
}

#forgot-password {
    font-size: var(--small-text);
    text-align: center;
    color: var(--main-accent-color);
    margin: 0 auto;
}

#forgot-password:hover {
    text-decoration: underline;
}

#to-registration {
    background-color: var(--main-accent-color);
    height: 32px;
    border-radius: 16px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#to-registration > svg {
    color: white;
}

#to-registration > span {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--small-text);
}

#login-message {
    text-align: center;
}

div.reactivation-container {
    display: grid;
    gap: 8px;
}

#reactivation-description {
    text-transform: initial;
    text-align: justify;
    padding: 0 12px;
}

#reactivation-input {
    width: 70%;
    justify-self: center;
}