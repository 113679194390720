/*######################################################################################################################
                                                 PHONE INPUT
######################################################################################################################*/
.phone-input-field {
    display: flex;
}

.phone-input-field input {
    width: calc(100% - 33px);
    border-radius: 0 9999px 9999px 0 !important;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}

.phone-input-field .input-group-text {
    border-radius: 9999px 0 0 9999px !important;
    background-color: white !important;
}