div#four-o-four {
    display: flex;
    width: 100%;
    gap: 20px;
    height: calc(100vh - var(--main-banner-height));
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--main-accent-color-light);
}

div#four-o-four div#four-o-four-image-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    height: max-content;
}

div#four-o-four-image-container > div#plug-left {
    width: 30vw;
}

div#four-o-four-image-container > div#plug-left > img {
    width: 100%;
}

div#four-o-four-image-container > div#four-o-four-image {
    position: relative;
    width: 40vw;
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 20px;
    height: max-content;
}

div#four-o-four-image-container > div#four-o-four-image > img {
    width: 100%;
}

div#four-o-four-image-container > div#plug-right {
    width: 30vw;
}

div#four-o-four-image-container > div#plug-right > img {
    width: 100%;
}

div#four-o-four-text-container {
    display: grid;
    gap: 30px;
    justify-items: center;
}

div#four-o-four-text-container > div#four-o-four-message {
    width: 100%;
    text-align: center;
}

div#four-o-four-text-container > div#four-o-four-message > div:first-child {
    font-family: urbane-rounded, sans-serif;
    font-size: 30px;
    color: var(--main-accent-color);
}

div#four-o-four-text-container > div#four-o-four-message > div:last-child {
    font-family: urbane-rounded-light, sans-serif;
    font-size: 20px;
    color: lightslategray;
}

#four-o-four .outline-button {
    width: max-content;
    height: 42px;
    padding: 16px;
    gap: 10px;
}

#four-o-four .outline-button > svg {
    font-size: 22px;
}

#four-o-four .outline-button > span {
    font-size: 12px;
}

a#four-o-four-home-button {
    background-color: var(--dark-gray-color);
}

@media only screen and (max-width: 750px) {

    #four-o-four-text-container > .horizontal-form-group {
        flex-direction: column;
    }

}