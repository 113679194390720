@import 'bootstrap/dist/css/bootstrap.min.css';

/*######################################################################################################################
|                                                 GLOBAL STYLING                                                       |
######################################################################################################################*/

:root {
    --main-accent-color: #D86E36;
    --secondary-accent-color: #5C2727;
    --tertiary-accent-color: #CB8D37;
    --quaternary-accent-color: #E1C1A6;
    --main-accent-color-bright: #F1E8DF;
    --main-gray-color: #888888;
    --light-gray-color: #D2D2D2;
    --accept-color: #68AB55;
    --statistics-yellow: #E9B400;
    --statistics-brown: #985025;
    --color-boost: #874ACC;
    --dark-gray-color: #3D4451;

    --main-accent-color-shadow: #833E19;
    --secondary-accent-color-shadow: #251615;
    --tertiary-accent-color-shadow: #75511f;

    --main-accent-color-light: #F8F3ED;
    --secondary-accent-color-light: #5C272755;
    --tertiary-accent-color-light: #F8F3ED;

    --main-accent-color-transparent: #D86E3618;

    --main-accent-color-medium: #D86E3688;
    --secondary-accent-color-medium: #5C272788;
    --tertiary-accent-color-medium: #CB8D3788;
    --light-gray-color-medium: #D2D2D255;

    --tertiary-accent-color-light-transparent: #CB8D3744;

    --facebook-color: #3B5998;
    --whatsapp-color: #4AC959;
    --twitter-color: #1DA1F2;

    --main-banner-height: 80px;
    --main-search-bar-height: 120px;
    --search-filter-header-height: 60px;
    --side-menu-width: 176px;
    --profile-menu-width: 260px;
    --subpage-side-menu-small-screen-collapsed-width: 35px;
    --side-menu-width-collapsed: 3em;
    --widget-header-height: 40px;
    --widget-footer-height: 44px;

    --container-shadow: 8px 8px 16px rgba(100, 50, 0, 0.25), -6px -6px 12px rgba(100, 60, 0, 0.2);
    --container-shadow-dark: 8px 8px 16px rgba(25, 10, 0, 0.25), -6px -6px 12px rgba(50, 50, 50, 0.3);
    --container-shadow-light: 4px 4px 8px rgba(100, 50, 0, 0.25), -3px -3px 6px rgba(200, 200, 200, 0.3);
    --container-shadow-inset: inset 8px 8px 16px rgba(100, 50, 0, 0.25), inset -6px -6px 12px rgba(200, 200, 200, 0.3);
    --container-highlight-shadow: 8px 8px 16px rgba(0, 25, 50, 0.25), -6px -6px 12px rgba(200, 200, 200, 0.3);
    --main-accent-inset: inset 1px 1px 10px var(--main-accent-color-shadow);
    --secondary-accent-inset: inset 1px 1px 10px var(--secondary-accent-color-shadow);

    --button-small-font: 9px;
    --small-text: 13px;

    --room-box-width: 200px;
    --room-box-height: 140px;
    --room-box-gap: 15px;
}

/*######################################################################################################################
                                                GLOBAL SETTINGS
######################################################################################################################*/
@font-face {
    font-family:"open-sans-bold";

    src:url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:700;
}

@font-face {
    font-family:"open-sans-medium";

    src:url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:500;
}

@font-face {
    font-family:"open-sans-light";

    src:url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8939f9/00000000000000007735a061/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:100;
}

@font-face {
    font-family:"open-sans-bold-italic";

    src:url("https://use.typekit.net/af/cb3467/00000000000000007735a069/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/cb3467/00000000000000007735a069/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/cb3467/00000000000000007735a069/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");

    font-display:auto;

    font-style:italic;

    font-weight:700;
}

@font-face {
    font-family:"open-sans-italic";

    src:url("https://use.typekit.net/af/d4e28f/00000000000000007735a072/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/d4e28f/00000000000000007735a072/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/d4e28f/00000000000000007735a072/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");

    font-display:auto;

    font-style:italic;

    font-weight:400;
}

@font-face {
    font-family:"open-sans";

    src:url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f18587/00000000000000007735a07a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:400;
}

@font-face {
    font-family:"open-sans-condensed-bold";

    src:url("https://use.typekit.net/af/2dfb40/00000000000000007735a05d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2dfb40/00000000000000007735a05d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2dfb40/00000000000000007735a05d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:700;
}

@font-face {
    font-family:"open-sans-condensed";

    src:url("https://use.typekit.net/af/066173/00000000000000007735a05f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/066173/00000000000000007735a05f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/066173/00000000000000007735a05f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:300;
}

@font-face {
    font-family:"open-sans-condensed-italic";

    src:url("https://use.typekit.net/af/e117fb/00000000000000007735a062/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/e117fb/00000000000000007735a062/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/e117fb/00000000000000007735a062/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");

    font-display:auto;

    font-style:italic;

    font-weight:300;
}

@font-face {
    font-family:"urbane-rounded-light";

    src:url("https://use.typekit.net/af/4b2ee1/00000000000000007735bfa1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/4b2ee1/00000000000000007735bfa1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/4b2ee1/00000000000000007735bfa1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");

    font-display: auto;

    font-style:normal;

    font-weight:300;
}

@font-face {
    font-family:"urbane-rounded";

    src:url("https://use.typekit.net/af/9e805f/00000000000000007735bfa2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/9e805f/00000000000000007735bfa2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/9e805f/00000000000000007735bfa2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");

    font-display:auto;

    font-style:normal;

    font-weight:700;
}

body {
    margin: 0;
}

* {
    font-family: "open-sans", sans-serif;
    outline: none;
}

h1 {
    font-size: 35px !important;
}

h2 {
    font-size: 30px !important;
}

h3 {
    font-size: 25px !important;
}

h4 {
    font-size: 20px !important;
}

h5 {
    font-size: 16px !important;
}

h6 {
    font-size: 14px !important;
}

button {
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
}

a {
    text-decoration: none !important;
    width: max-content;
}

a:not(.outline-button) {
    color: var(--main-accent-color) !important;
}

a:not(.outline-button):hover {
    color: var(--main-accent-color-shadow) !important;
}

.landlord a:not(.outline-button) {
    color: var(--tertiary-accent-color) !important;
}

.landlord a:not(.outline-button):hover {
    color: var(--tertiary-accent-color-shadow) !important;
}

a[class=outline-button] > span {
    color: white !important;
}

.accordion-item {
    border: unset;
    outline: none;
    background-color: white;
}

.accordion-button {
    background-color: transparent !important;
    box-shadow: none !important;
    border-color: transparent !important;
}

.accordion-button:not(.collapsed) {
    color: black;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

.accordion-item > div > button {
    font-size: 16px;
}

button.send-msg {
    color: var(--main-accent-color);
}

.landlord button.send-msg {
    color: var(--tertiary-accent-color);
}

button.link-button {
    color: var(--main-accent-color);
    padding: 0;
}

.landlord button.link-button {
    color: var(--tertiary-accent-color);
}

button.link-button:disabled {
    color: gray;
}

button.link-button:disabled > svg{
    color: gray;
}

button.link-button:hover:not(:disabled) {
    color: var(--main-accent-color-shadow);
}

.accent-icon-button {
    color: white !important;
    background-color: var(--secondary-accent-color);
    height: 2rem !important;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    border: 0;
    outline: none;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--button-small-font);
}

.accent-icon-button:disabled {
    background-color: gray;
    color: var(--light-gray-color);
}

.accent-icon-button > span {
    font-family: urbane-rounded, sans-serif;
    font-size: 12px;
    color: white !important;
}

.accent-icon-button:not(:disabled):active {
    box-shadow: var(--secondary-accent-inset);
}

.accent-icon-button.fill {
    width: 100%;
}

.accent-icon-button svg{
    color: var(--tertiary-accent-color) !important;
    margin-right: .5em;
}

.accent-icon-button:disabled {
    background-color: dimgray;
}

.accent-icon-button:disabled svg{
    color: var(--light-gray-color);
}

.session-payment-button {
    width: 200px;
    height: 50px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.session-payment-button .loading > span {
    font-family: open-sans-bold, sans-serif;
}

.session-payment-button > img {
    height: 26px;
}

.outline-button {
    height: 2rem;
    border-radius: 9999px;
    border: 1px white solid;
    color: white;
    padding: 1em 2em;
    background-color: rgba(0,0,0,0);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--button-small-font);
    letter-spacing: 1px;
    transition: all .2s ease-in-out;
    gap: .5em;
    text-decoration: none !important;
}

.outline-button > a {
    font-family: urbane-rounded, sans-serif;
    transition: all .2s ease-in-out;
    font-size: var(--button-small-font);
    letter-spacing: 1px;
    text-decoration: none !important;
    color: white !important;
}

.outline-button > span {
    font-family: urbane-rounded, sans-serif;
    transition: all .2s ease-in-out;
    font-size: var(--button-small-font);
    letter-spacing: 1px;
    text-decoration: none !important;
}

.outline-button svg{
    color: white;
    transition: all .2s ease-in-out !important;
}

.outline-button:disabled {
    background-color: lightgrey !important;
    border-color: lightgrey !important;
    cursor: auto !important;
    color: gray !important;
}

.outline-button:disabled svg{
    color: gray !important;
}

.outline-button.black {
    border-color: black;
    color: black;
}

.outline-button.black svg{
    color: black;
}

.outline-button.fill {
    width: 100%;
}

.outline-button.accent {
    color: var(--main-accent-color);
    background-color: white;
    border: 1px solid var(--main-accent-color);
}

.landlord .outline-button.accent {
    color: var(--tertiary-accent-color);
    border-color: var(--tertiary-accent-color);
}

.outline-button.accent svg{
    color: var(--main-accent-color);
}

.landlord .outline-button.accent svg{
    color: var(--tertiary-accent-color);
}

.landlord .outline-button.accent.filled svg{
    color: white;
}

.outline-button.accent:hover {
    color: white;
    background-color: var(--main-accent-color);
}

.landlord .outline-button.accent:hover {
    color: white;
    background-color: var(--tertiary-accent-color);
}

.outline-button.accent:not(:disabled):hover > svg{
    color: white !important;
}

.outline-button.accent:active:not(:disabled) {
    border-color: white;
    box-shadow: var(--main-accent-inset);
}

.outline-button.accent.filled {
    color: white;
    background-color: var(--main-accent-color);
    border: 1px solid var(--main-accent-color);
}

.outline-button.accent.filled > svg{
    color: white;
}

.landlord .outline-button.accent.filled {
    color: white;
    background-color: var(--tertiary-accent-color);
    border: 1px solid var(--tertiary-accent-color);
}

.outline-button.gray {
    color: var(--light-gray-color);
    background-color: white;
    border: 1px solid var(--light-gray-color);
}

.outline-button.gray:not(.filled) > svg{
    color: var(--light-gray-color);
}

.outline-button.gray.filled {
    color: white;
    background-color: var(--light-gray-color);
}

.outline-button.gray:not(.filled):not(:disabled):hover {
    color: white;
    background-color: var(--light-gray-color);
}

.outline-button.gray:not(.filled):not(:disabled):hover > svg{
    color: white;
}

.outline-button.gray.filled:active:not(:disabled) {
    border-color: white;
    box-shadow: var(--container-shadow-inset);
}

.outline-button.accent.secondary {
    color: var(--secondary-accent-color);
    background-color: white;
    border: 1px solid var(--secondary-accent-color);
}

.outline-button.accent.secondary svg{
    color: var(--secondary-accent-color);
}

.outline-button.accent.secondary:hover {
    color: white;
    background-color: var(--secondary-accent-color);
}

.outline-button.accept {
    color: var(--accept-color);
    border-color: var(--accept-color);
    background-color: white;
}

.outline-button.accept:not(:disabled):hover {
    color: white;
    background-color: var(--accept-color);
}

.outline-button.accept svg{
    color: var(--accept-color);
}

.outline-button.accept:not(disabled):hover svg{
    color: white;
}

.outline-button.decline {
    color: darkred;
    border-color: darkred;
}

.outline-button.decline svg{
    color: darkred;
}

.outline-button.decline:not(:disabled):hover svg{
    color: white;
}

.outline-button.decline:not(:disabled):hover {
    color: white;
    background-color: darkred;
}

button.accept:not(.outline-button) {
    height: 2rem;
    border-radius: 9999px;
    border: none;
    color: white;
    padding: 1em 2em;
    background-color: var(--accept-color);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: .6em;
    letter-spacing: .1px;
}

button.accept:disabled {
    background-color: dimgray;
    border-color: dimgray;
    cursor: auto;
    color: white;
}

button.accept:active:not(:disabled) {
    box-shadow: inset 2px 2px 10px #77777755, inset -4px -4px 10px #55555555;
}

button.fetch-button > div.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.accent-radio-button {
    width: max-content;
    height: 32px;
}

.accent-radio-button > input {
    display: none;
}

.accent-radio-button > input + label {
    width: max-content;
    display: flex;
    align-items: center;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    background-color: var(--main-accent-color-light);
    color: black;
    font-size: var(--small-text);
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 4px;
    height: 100%;
}

.landlord .accent-radio-button > input + label {
    background-color: var(--tertiary-accent-color-light-transparent);
}

.accent-radio-button > input:checked + label {
    background-color: var(--main-accent-color);
    color: white;
}

.landlord .accent-radio-button > input:checked + label {
    background-color: var(--tertiary-accent-color);
    color: black;
}

.accent-radio-button > input:disabled + label {
    background-color: lightgray;
    color: white;
    cursor: default;
}

.accent-radio-button > input:checked:disabled + label {
    background-color: gray;
    color: white;
}

.simple-icon-button {
    width: 32px;
    height: 32px;
    padding: 5px;
    border-radius: 16px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.simple-icon-button.border {
    border: 1px black solid !important;
}

.interval-button-container {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
}

.form-control::placeholder {
    color: lightgrey !important;
    font-size: 1rem;
    font-family: open-sans-italic, sans-serif;
}

.form-control:not(textarea):not(.prefix) {
    border-radius: 9999px;
}

.form-control.prefix {
    border-radius: 0 9999px 9999px 0 !important;
}

.form-control.borderless:not(textarea):not(.clock) {
    border: none !important;
}



input[type=checkbox].invalid {
    outline: 2px groove red;
}

input::placeholder {
    color: lightgrey;
    font-family: open-sans-italic, sans-serif;
    opacity: 1 !important;
}

input:focus {
    border: 1px solid var(--main-accent-color-medium) !important;
}

input.form-control {
    border-radius: 8px;
}

input.form-control:focus {
    box-shadow: none;
}

input.form-control:focus::placeholder {
    display: none;
}

input:not([type=checkbox]):not([type=radio]) {
    border: 1px solid lightgray;
    border-radius: 16px;
    padding: 0 10px;
    font-size: var(--small-text) !important;
    height: 32px;
}

input.invalid {
    border: 1px solid red !important;
}

textarea {
    background-color: #f4f4f4 !important;
    border: none !important;
    border-radius: 16px 16px 0 16px;
    width: 100%;
    padding: 4px 8px;
    outline: none !important;
    box-shadow: none !important;
    font-size: var(--small-text);
}

textarea.invalid {
    border: 1px solid red !important;
}

::placeholder {
    font-size: .8rem !important;
}

input.form-control.clock {
    text-align: center;
}

span.loading {
    width: 100%;
    font-size: 1.5em;
    text-align: center;
}

.fa-fast-spin {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

ul:not(.bulleted) {
    list-style-type: none;
}

.tooltip-inner {
    max-width: 500px !important;
}

div.teaser-container {
    position: relative;
}

div.teaser-container:hover > div.teaser-background-image {
    background-size: 120%;
}

div.teaser-container > div.teaser-background-image {
    width: 100%;
    height: 100%;
    transition: 3s ease-in-out;
    background-size: 100%;
    background-position: center;
    position:absolute;
    z-index: -1;
}

div.teaser-container > div.container {
    display: grid;
    height: 100%;
}

div.teaser-container div.teaser-header-container,
div.teaser-container div.teaser-header-container > div {
    font-family: urbane-rounded, sans-serif;
    color: white;
    font-size: 37px;
}

div.teaser-container div.teaser-header-container > div {
    line-height: 37px;
}

div.teaser-container div.teaser-description-container {
    color: white;
}

div.teaser-container button.outline-button {
    width: max-content;
}

div.roomjack-is-green {
    height: 550px;
}

div.roomjack-is-green > div.container {
    grid-template-columns: 1fr 1fr;
}

div.roomjack-is-green > div.container > div {
    height: 550px;
    display: flex;
    flex-direction: column;
}

div.roomjack-is-green .left-side {
    justify-content: center;
    gap: 20px;
}

div.roomjack-is-green .right-side {
    justify-content: flex-end;
    align-items: end;
}

div.roomjack-is-green .left-side > a {
    margin-top: 20px;
}

div.roomjack-is-green div.teaser-background-image {
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/content-images/landscape.jpg");
    background-repeat: no-repeat;
    background-size: cover !important;
}

div.roomjack-is-green img {
    height: 85%;
}

div.roomjack-is-green div.teaser-description-container {
    font-size: 14px;
}

div.background-scroll-image {
    width: 100vw;
    position: relative;
    display: flex;
}

div.parallax {
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    left: 0;
    top: 0;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

div.parallax .parallax-text {
    color: white;
    font-family: urbane-rounded, sans-serif;
    z-index: 1;
    font-size: 40px;
}

span.image-copyright-label {
    color: white;
    bottom: 8px;
    right: 8px;
    position: absolute;
    background-color: rgba(0,0,0, .5);
    padding: 2px;
    border-radius: 5px;
    opacity: .7;
    font-size: .6em;
}

div.news-room {
    padding: 3em;
}

.news-box {
    display: grid;
    width: 100%;
    justify-items: center;
    padding: 5px;
}

.news-box > * {
    width: 400px !important;
}

.news-box div.news-image-container {
    height: 250px;
    position: relative;
    border-radius: 1em;
    background-position-x: 50%;
    background-position-y: 50%;
    transition: 1s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
}

.news-box div.news-date-label {
    color: gray;
    font-size: 11px;
    margin-top: 1rem;
}

.news-box div.news-title-label {
    font-family: urbane-rounded, sans-serif;
    color: black !important;
    font-size: 18px;
    margin-bottom: 15px;
}

.news-box div.news-description {
    color: gray !important;
    font-size: 12px;
    text-align: justify;
}

.news-box div.news-read-more {
    margin-top: 5px;
    font-family: urbane-rounded, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    color: var(--main-accent-color) !important;
}

.landlord .news-box div.news-read-more {
    color: var(--tertiary-accent-color) !important;
}

.description-header {
    font-size: var(--small-text);
    font-family: open-sans-bold, sans-serif;
}

.description-container {
    color: gray;
    font-size: var(--small-text);
}

.description-container > svg:first-child {
    margin-right: 5px;
}

.horizontal-form-group {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.horizontal-form-group.wrap {
    flex-wrap: wrap;
}

div.process-modal-result-container {
    display: grid;
    gap: 25px;
}

div.process-result-image {
    width: 100%;
    display: flex;
    justify-content: center;
}

div.process-result-image > img {
    width: 200px;
    height: 200px;
    object-position: center;
    object-fit: contain;
    border-radius: 9999px;
}

div.process-result-image > div.result-icon-container {
    width: 150px;
    height: 150px;
    border-radius: 9999px;
    background-color: var(--main-accent-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

div.process-result-message-box {
    display: grid;
    gap: 1em;
    text-align: center;
    text-transform: uppercase;
    color: black;
    font-family: urbane-rounded, sans-serif !important;
    justify-items: center;
}

div.process-result-message-box div.header {
    text-align: center;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    color: black;
}

div.process-result-message-box > div:not(.header) {
    text-transform: initial;
    color: gray;
}

label.v-form-label {
    margin-left: 12px;
}

label > svg.fa-info-circle {
    color: var(--main-accent-color)
}

div#main-body.landlord label > svg.fa-info-circle {
    color: var(--tertiary-accent-color)
}

div.carousel-item > div.carousel-item-grid {
    display: grid;
    gap: 1.5em;
    justify-items: center;
    padding: 40px;
    justify-content: center;
}

div.carousel a.carousel-control-prev > svg,
div.carousel a.carousel-control-next > svg {
    color: gray !important;
    font-size: 30px;
}

div.loading {
    width: max-content;
    display: flex;
    align-items: center;
}

div.loading > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    margin-left: 5px;
}

div.loading > svg {
    color: var(--main-accent-color);
}

.landlord div.loading > svg {
    color: var(--tertiary-accent-color);
}

.placeholder {
    opacity: 1;
}

div.input-group-prepend > div.input-group-text {
    border-radius: 9999px 0 0 9999px !important;
    background-color: white !important;
}

div.input-group-append > div.input-group-text,
span.input-group-append > span.input-group-text {
    border-radius: 0 9999px 9999px 0 !important;
    padding: .375rem .8rem .375rem .5rem;
    background-color: white !important;
    border-left: none !important;
}

div.input-group-append > div.input-group-text > svg,
span.input-group-append > span.input-group-text > svg {
    color: var(--main-accent-color);
}

div.roomjack-feature {
    width: 100%;
    padding: 30px 0;
    display: flex;
    gap: 1.5em;
    justify-content: center;
    align-items: center;
}

div.roomjack-feature.inverted {
    flex-direction: row-reverse;
}

div.roomjack-feature > div.feature-image-container {
    position: relative;
    overflow: hidden;
    width: 50%;
}

div.roomjack-feature > div.feature-image-container > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
}

div.roomjack-feature > div.feature-content-container {
    width: 50%;
}

div.roomjack-feature.full-width  > div.feature-content-container {
    width: 100%;
}

div.roomjack-feature > div.feature-content-container > div.feature-text-content {
    color: gray;
    font-size: 17px;
    text-align: justify;
}

.pac-container {
    z-index: 10000 !important;
}

.pac-container:after {
    content:none !important;
}

.c-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-flex.auto-gap {
    gap: .5em;
}

.checkbox-grid {
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
    overflow-y: auto;
    max-height: calc(100vh - 350px);
}

div.marker {
    height: 16px;
    border-radius: 8px;
    background-color: var(--main-accent-color);
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: .8em;
    font-weight: bolder;
    padding: 4px
}

.landlord div.marker {
    background-color: var(--tertiary-accent-color);
}

.review-container {
    display: flex;
    gap: 3px;
    align-items: center;
}

.review-container > svg {
    color: var(--main-accent-color) !important;
}

.review-container > span:nth-child(2) {
    font-family: urbane-rounded, sans-serif;
    color: black !important;
    line-height: 16px;
}

.review-container > span:last-child {
    color: gray;
    font-size: var(--small-text);
}
/*######################################################################################################################
                                    SUBMENU GRID
######################################################################################################################*/

div.desktop-submenu-grid {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: repeat(auto-fit, 120px);
    grid-gap: 25px;
    position: relative;
}

.desktop-submenu-tile {
    width: 120px;
    height: 120px;
    background-color: var(--main-accent-color-light);
    border-radius: 1em;
    padding: 8px;
    word-wrap: break-word;
    display: grid;
    gap: 5px;
    text-decoration: none !important;
    position: relative;
}

.landlord .desktop-submenu-tile {
    background-color: var(--tertiary-accent-color-light-transparent);
}

.desktop-submenu-tile:hover > div.tile-icon-container {
    filter: brightness(1000);
}

.desktop-submenu-tile.selectable.selected > div.tile-icon-container {
    filter: brightness(1000);
}

.desktop-submenu-tile:not(.disabled):hover {
    background-color: var(--main-accent-color-medium);
    cursor: pointer;
}

.landlord .desktop-submenu-tile:not(.disabled):hover {
    background-color: var(--tertiary-accent-color-medium);
}

.desktop-submenu-tile.selectable.selected {
    background-color: var(--main-accent-color);
}

.landlord .desktop-submenu-tile.selectable.selected  {
    background-color: var(--tertiary-accent-color);
}

.desktop-submenu-tile > div.tile-icon-container {
    font-size: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-accent-color);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.landlord .desktop-submenu-tile > div.tile-icon-container {
    color: var(--tertiary-accent-color);;
}

.desktop-submenu-tile.selectable.selected > div.tile-icon-container {
    color: white
}

.desktop-submenu-tile:hover > div.tile-icon-container {
    color: white
}

.desktop-submenu-tile > div.tile-text-container {
    font-size: .6em;
    text-align: center;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: black !important;
    text-decoration: none !important;
    align-self: center;
}

.desktop-submenu-tile > .tile-icon-container + .tile-text-container {
    align-self: unset;
    height: 40%;
}

.desktop-submenu-tile > div.tile-icon-container > div {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 40px;
    height: 40px;
}

.desktop-submenu-tile.disabled {
    background-color: var(--light-gray-color-medium) !important;
}

.desktop-submenu-tile.disabled.selected {
    background-color: var(--light-gray-color) !important;
}

.desktop-submenu-tile.disabled > div.tile-icon-container {
    color: gray;
    filter: saturate(0);
}

.desktop-submenu-tile.disabled > div.tile-text-container {
    color: gray;
}

.roomjack-container {
    padding: 15px;
    border-radius: 1em;
    box-shadow: var(--container-shadow);
}

.roomjack-container.borderless {
    box-shadow: none;
}

.roomjack-container.invalid {
    border: 2px red solid;
}

.roomjack-container.loading {
    width: 500px;
    height: 350px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.roomjack-container.loading > img {
    width: 30%;
}

.roomjack-container-header {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    margin-bottom: 10px;
    color: var(--main-accent-color);
}

.landlord .roomjack-container-header {
    color: var(--tertiary-accent-color);
}

.roomjack-headline,
.roomjack-headline > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

.roomjack-headline.accent {
    color: var(--main-accent-color);
}

.landlord .roomjack-headline.accent {
    color: var(--tertiary-accent-color);
}

.roomjack-headline > span + svg {
    color: gray;
    margin-left: 5px;
}

img.flag {
    width: 24px;
    height: 18px;
    border-radius: 4px;
}

div.form-group {
    margin-bottom: 0;
    position: relative;
    line-height: initial !important;
}

div.form-group.v {
    display: grid;
    gap: 5px;
}

div.form-group > label {
    color: gray;
    font-size: var(--small-text);
}

div.pagination {
    display: flex;
    justify-content: center;
    gap: 1em;
    padding: 20px;
}

div.pagination > div.page-placeholder {
    color: gray;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.pagination > button {
    border: none;
    background-color: white;
    outline: none;
    color: gray;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.pagination > button.selected {
    background-color: var(--main-accent-color);
    color: white;
}

.landlord div.pagination > button.selected {
    background-color: var(--tertiary-accent-color);
}

div.pagination > button:disabled {
    color: var(--light-gray-color);
}

.roomjack-grid-container {
    display: grid;
    gap: 25px;
}
/*********************************************** Custom checkbox ******************************************************/
div.form-group > input[type=checkbox] {
    position: absolute;
    opacity: 0;
}

div.form-group > input[type=checkbox] + label {
    padding-left: 20px !important;;
    font-size: 12px !important;
    color: gray !important;
    line-height: 16px;
    position: relative;
    margin-bottom: 0 !important;
}

div.form-group > input[type=checkbox] + label::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 16px !important;
    height: 16px !important;
    border-radius: 5px;
    border: 1px solid black;
}

div.form-group > input[type=checkbox]:disabled + label::before {
    border: 1px solid var(--light-gray-color);
}

div.form-group > input[type=checkbox] + label::after {
    content: '\f00c';
    font: var(--fa-font-light);
    display: none;
}

div.form-group > input[type=checkbox]:not(:checked) + label > svg.fa-check {
    position: absolute;
    top: 2px;
    left: 3px;
    font-size: 13px !important;
    line-height: 16px;
    transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    transform: scale(0, 0);
    opacity: 0;
}

div.form-group > input[type=checkbox]:checked + label > svg.fa-check {
    position: absolute;
    top: 2px;
    left: 3px;
    transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    transform: scale(1, 1);
    opacity: 1;
    color: black !important;
}

/********************************************* Custom radio button ****************************************************/
div.form-group {
    display: flex;
    align-items: center;
}

div.form-group > input[type=radio] {
    position: absolute;
    opacity: 0;
}

div.form-group > input[type=radio] + label {
    margin-left: 20px;
    margin-bottom: 0 !important;
}

div.form-group > input[type=radio] + label:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--light-gray-color);
}

div.form-group > input[type=radio]:checked + label:before {
    width: 16px;
    height: 16px;
    border: none;
    background-color: black;
}

div.form-group > input[type=radio]:checked:disabled + label:before {
    background-color: gray;
}

div.form-group > input[type=radio] + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
    transform: scale(0, 0);
    opacity: 0;
    transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

div.form-group > input[type=radio]:checked + label:after {
    transform: scale(1, 1);
    opacity: 1;
}

.no-data-container {
    width: 400px;
    max-width: 950px;
    height: max-content;
    display: grid;
    gap: 20px;
    justify-items: center;
}

.no-data-container > img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    background-repeat: no-repeat;
}

.no-data-container > .description-container {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    text-align: center;
}

/*#######################################  General modal styling  ####################################################*/
div.modal-header {
    border-bottom: none;
}

div.modal-header h4 {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

div.modal-footer {
    border-top: none;
}

div.modal-body .modal-headline {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--small-text);
}

/*#######################################  Element loading animation  ################################################*/

.shine {
    overflow: clip;
    position: relative;
}

/*.shine:after {
    content: ' ';
    display: block;
    position: absolute;
    overflow: hidden;
    width: 80px;
    height: 300%;
    background-image: linear-gradient(to left, transparent, transparent, white, transparent, transparent, transparent, transparent);
    transform-origin: center;
    animation: shining 2s ease-in-out infinite;
    transform: rotate(30deg) translate(0, -30%);
}*/

@keyframes shining {
    from {
        left: -150%;
    }
    to {
        left: 200%;
    }
}

div.doughnut-center-label {
    display: flex;
    flex-direction: column;
    height: max-content;
    align-items: center;
    justify-content: center;
    position: absolute;
}

div.doughnut-center-label > div {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

div.doughnut-center-label > div:first-child {
    color: var(--tertiary-accent-color);
}

div.doughnut-center-label > div:last-child {
    font-size: var(--small-text);
}

.payment-handler {
    position: relative;
    display: grid;
    gap: 10px;
}

.payment-handler .cancel-session {
    position: absolute;
    right: 0;
    top: 0;
}

.stripe-link {
    font-size: var(--small-text);
}

.advert-publication-modal .process-result-image svg {
    font-size: 40px;
}

.upload-bar {
    height: 24px;
    width: 300px;
    border: 1px solid var(--main-accent-color);
    border-radius: 12px;
    text-align: center;
    position: relative;
}

.upload-bar-progress {
    background-color: var(--main-accent-color);
    height: 24px;
    border-radius: 12px;
}

.landlord .upload-bar {
    border: 1px solid var(--tertiary-accent-color);
}

.landlord .upload-bar-progress {
    background-color: var(--tertiary-accent-color)
}

.upload-bar .upload-bar-indicator {
    position: absolute;
    font-family: urbane-rounded, sans-serif;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/*######################################################################################################################
                                             MAIN CONTAINER SETUP
######################################################################################################################*/

/*############################################  Main banner  #########################################################*/
div#main-banner {
    position: absolute;
    color: white;
    padding: 1em 3em;
    height: var(--main-banner-height);
    top: 0;
    width: 100%;
    display: flex;
    z-index: 5;
}

div#main-banner.lifted {
    box-shadow: var(--container-shadow);
}

div#main-banner.sticky {
    position: fixed !important;
    top: 0;
    background-color: white;
    z-index: 10;
}

div#main-banner > div {
    display: flex;
    width: 100%;
    align-items: center;
}

div#main-banner.sticky button.outline-button {
    border-color: var(--main-accent-color);
    color: var(--main-accent-color);
}

div#main-banner.sticky.landlord button.outline-button {
    border-color: var(--tertiary-accent-color);
    color: var(--tertiary-accent-color);
}

div#main-banner-logo-container {
    height: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    cursor: pointer;
}

div#main-banner-logo-container img#main-banner-logo {
    height: 40px;
}

div#main-banner-toggle-container {
    position: relative;
    margin-left: auto;
    z-index: 11;
    border: 1px solid #e2e2e2;
    border-radius: 9999px;
    display: flex;
    gap: .3em;
    padding: .3em;
}

div#main-banner #main-banner-mode-switcher {
    margin-left: auto;
    z-index: 11;
    height: 36px;
    text-decoration: none;
}

div#main-banner-toggle-container button {
    color: white;
    font-family: urbane-rounded, sans-serif;
    width: 80px;
    height: 20px;
    text-transform: uppercase;
    margin-bottom: unset;
    text-align: center;
    font-size: 11px;
    transition: .5s linear;
    z-index: 5;
}

div#main-banner-toggle-container div.mode-toggle-background {
    width: 80px;
    position: absolute;
    height: 20px;
    border-radius: 10px;
    transition: .5s ease-in-out;
}

div#main-banner-toggle-container div.mode-toggle-background.renter {
    left: .5em;
    background-color: var(--main-accent-color);
}

div#main-banner-toggle-container div.mode-toggle-background.landlord {
    left: calc(.6em + 80px);
    background-color: var(--tertiary-accent-color);
}

div#main-banner-control-container {
    height: 100%;
    margin-left: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 11;
    gap: .8em;
}

#avatar-icon {
    font-size: 1.7em;
    color: white;
}

div#main-banner #main-banner-avatar-container {
    display: flex;
    gap: .8em;
    align-items: center;
    cursor: pointer;
}

div#main-banner #main-banner-avatar-container > #avatar-button > .avatar-container {
    cursor: pointer !important;
}

div#main-banner #main-banner-avatar-container > #welcome-text {
    font-size: .8em;
}

div#main-banner #main-banner-avatar-container > #welcome-text > span.prefix {
    font-family: urbane-rounded-light, sans-serif;
    color: white;
}

div#main-banner #main-banner-avatar-container > #welcome-text > span.data {
    color: var(--main-accent-color);
    font-family: urbane-rounded, sans-serif;
    margin-left: .1em;
    text-transform: uppercase;
}

div#main-banner.landlord #main-banner-avatar-container > #welcome-text > span.data {
    color: var(--tertiary-accent-color);
}

div#main-banner #main-banner-avatar-container > #welcome-text > span.suffix {
    font-family: urbane-rounded, sans-serif;
    color: white;
    margin-left: -.2em;
}

div#main-banner div#main-banner-control-container.public > #main-banner-avatar-container > #welcome-text {
    font-size: .6em !important;
}

div#main-banner div#main-banner-control-container.public > #main-banner-avatar-container  > #welcome-text > span.prefix {
    font-family: urbane-rounded, sans-serif !important;
    color: white;
    font-size: 10px !important;
}

div#main-banner.sticky div#main-banner-control-container.public > #main-banner-avatar-container  > #welcome-text > span.prefix {
    color: var(--main-accent-color);
}

div#main-banner.sticky.landlord div#main-banner-control-container.public > #main-banner-avatar-container  > #welcome-text > span.prefix {
    color: var(--tertiary-accent-color);
}

div#main-banner div#main-banner-control-container.public > #main-banner-avatar-container  > #welcome-text > span.data {
    display: none;
}

div#main-banner div#main-banner-control-container.public > #main-banner-avatar-container > #welcome-text > span.suffix {
    display: none;
}

div#main-banner.sticky #main-banner-avatar-container > #welcome-text > span.prefix {
    color: black;
}

div#main-banner.sticky > div#main-banner-control-container.public > #main-banner-avatar-container > #welcome-text > span.prefix {
    color: var(--main-accent-color);
}

div#main-banner.sticky.landlord > div#main-banner-control-container.public > #main-banner-avatar-container > #welcome-text > span.prefix {
    color: var(--tertiary-accent-color);
}

div#main-banner.sticky #main-banner-avatar-container > #welcome-text > span.suffix {
    color: black;
}

div#main-banner.sticky div#main-banner-toggle-container > button:not(.selected) {
    color: var(--light-gray-color) !important;
}

div#main-banner.sticky #avatar-icon {
    color: var(--main-accent-color);
}

div#main-banner.sticky.landlord #avatar-icon {
    color: var(--tertiary-accent-color);
}

div.main-banner-content-container {
    background-color: white;
    position: absolute;
    border-radius: 1em;
    box-shadow: var(--container-shadow);
    color: dimgrey;
    padding: 1em;
}

div#main-banner-menu-container {
    top: 3.5em;
    right: calc(50% - 2em);
    width: max-content;
    display: grid;
    gap: 10px;
}

div#main-banner-menu-container #main-banner-menu-mode-toggle {
    display: none;
}

.main-banner-menu-entry {
    height: 2em;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
    color: gray;
}

.main-banner-menu-entry > svg {
    margin-right: .5em;
    transition: .2s ease-in-out;
    font-size: 20px;
}

.main-banner-menu-entry:hover > svg {
    color: var(--main-accent-color);
}

#main-banner.landlord .main-banner-menu-entry:hover > svg {
    color: var(--tertiary-accent-color);
}

.main-banner-menu-entry > span {
    font-family: urbane-rounded-light, sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    transition: .2s ease-in-out;
}

.main-banner-menu-entry:hover > span {
    color: black;
}

/*############################################   Main body   #########################################################*/
div#main-body {
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - var(--main-banner-height));
}

div#main-banner.sticky + div#main-body {
    margin-top: var(--main-banner-height);
}

div#main-body div.subpage-container {
    display: flex;
}

/*###########################################   Main footer   ########################################################*/
div#main-page-footer {
    background-color: var(--secondary-accent-color);
    padding: 90px 25px 35px 25px;
    color: white;
    position: relative
}

div#main-page-footer > div.container > div#main-page-footer-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: center;
    row-gap: 90px;
}

div#main-page-footer > div.container > div#main-page-footer-links div.footer-column-header {
    color: var(--main-accent-color);
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    margin-bottom: 15px;
}

div#main-page-footer ul {
    padding-left: 0;

}

div#main-page-footer > div.container #main-page-footer-logo-container {
    grid-row-start: 1;
    grid-column-start: 1;
}

div#main-page-footer > div.container #main-page-footer-logo-container > img {
    object-fit: contain;
    background-repeat: no-repeat;
    width: 120px;
    padding-bottom: 40px;
}

div#main-page-footer > div.container div.version-block {
    grid-row-start: 2;
    grid-column-start: 1;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 3px;
    white-space: nowrap;
}

div#main-page-footer > div.container div.landlord-block {
    grid-row-start: 1;
    grid-column-start: 2;
}

div#main-page-footer > div.container div.legal-links {
    grid-row-start: 2;
    grid-column: 2 / 4;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}

div#main-page-footer > div.container div.community-block {
    grid-row-start: 1;
    grid-column-start: 3;
}

div#main-page-footer > div.container div.support-block {
    grid-row-start: 1;
    grid-column-start: 4;
}

div#main-page-footer > div.container div.social-media-block {
    grid-row-start: 2;
    grid-column-start: 4;
    font-size: 12px;
}

div#main-page-footer a,
div#main-page-footer button.link-button {
    color: white !important;
    font-size: 12px;
    text-align: left;
}

div#main-page-footer a {
    text-decoration: none;
}

div#main-page-footer a:hover {
    text-decoration: underline;
}

div#main-page-footer div#main-page-footer-about {
    margin-top: 40px;
    display: flex;
    gap: 10px 20px;
    font-size: var(--small-text);
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

div#main-page-footer #follow-us {
    display: flex;
    gap: 10px;
    align-items: center;
}

div#main-page-footer #follow-us > a {
    color: var(--tertiary-accent-color) !important;
    font-size: 150%;
}

.review-modal .modal-body,
.review-modal form {
    display: grid;
    gap: 20px;
}

.review-modal table {
    width: 100%;
}

.review-modal table td {
    width: 50%;
}

.review-modal table td:first-child {
    text-align: right;
    padding-right: 10px;
}

.review-modal table td:last-child {
    padding-left: 10px;
}

/*############################################  Mode-Switch-Animation  ###############################################*/
.mode-switch-animation {
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
}

.thin-bar {
    width: calc(50% / 9);
    height: 100%;
    right: calc(125% + 50% / 3);
    position: absolute;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: .4s;
}

.thin-bar.renter {
    animation-name: thin-bar-to-guest-mode;
}

.thin-bar.landlord {
    animation-name: thin-bar-to-landlord-mode;
}

.medium-bar {
    width: calc(50% / 3);
    height: 100%;
    right: 125%;
    position: absolute;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: .2s;
}

.medium-bar.renter {
    animation-name: medium-bar-to-guest-mode;
}

.medium-bar.landlord {
    animation-name: medium-bar-to-landlord-mode;
}

.thick-bar {
    width: 25%;
    height: 100%;
    right: 100%;
    position: absolute;
    animation-duration: 2s;
    animation-timing-function: ease;
}

.thick-bar.renter {
    animation-name: thick-bar-to-guest-mode;
}

.thick-bar.landlord {
    animation-name: thick-bar-to-landlord-mode;
}

@keyframes thin-bar-to-landlord-mode {
    0% {
        right: calc(125% + 50% / 3);
        background-color: var(--main-accent-color);
    }
    50% {
        right: calc(50% / 3 + 25%);
        background-color: var(--tertiary-accent-color);
    }
    80% {
        right: calc(50% / 3 + 25%);
        background-color: var(--tertiary-accent-color);
    }
    100% {
        right: calc(-50% / 9);
        background-color: var(--tertiary-accent-color);
    }
}

@keyframes medium-bar-to-landlord-mode {
    0% {
        right: 125%;
        background-color: var(--main-accent-color);
    }
    50% {
        right: 25%;
        background-color: var(--tertiary-accent-color);
    }
    80% {
        right: 25%;
        background-color: var(--tertiary-accent-color);
    }
    100% {
        right: calc(-50% / 9 - 50% / 3);
        background-color: var(--tertiary-accent-color);
    }
}

@keyframes thick-bar-to-landlord-mode {
    0% {
        right: 100%;
        background-color: var(--main-accent-color);
    }
    50% {
        right: 0;
        background-color: var(--tertiary-accent-color);
    }
    80% {
        right: 0;
        background-color: var(--tertiary-accent-color);
    }
    100% {
        right: calc(-50% / 9 - 50% / 3 - 25%);
        background-color: var(--tertiary-accent-color);
    }
}

@keyframes thin-bar-to-guest-mode {
    0% {
        right: calc(-25% - 50% / 3 - 50% / 9);
        background-color: var(--tertiary-accent-color);
    }
    50% {
        right: calc(75% - 50% / 3 - 50% / 9);
        background-color: var(--main-accent-color);
    }
    80% {
        right: calc(75% - 50% / 3 - 50% / 9);
        background-color: var(--main-accent-color);
    }
    100% {
        right: 100%;
        background-color: var(--main-accent-color);
    }
}

@keyframes medium-bar-to-guest-mode {
    0% {
        right: calc(-25% - 50% / 3);
        background-color: var(--tertiary-accent-color);
    }
    50% {
        right: calc(75% - 50% / 3);
        background-color: var(--main-accent-color);
    }
    80% {
        right: calc(75% - 50% / 3);
        background-color: var(--main-accent-color);
    }
    100% {
        right: calc(100% + 50% / 9);
        background-color: var(--main-accent-color);
    }
}

@keyframes thick-bar-to-guest-mode {
    0% {
        right: -25%;
        background-color: var(--tertiary-accent-color);
    }
    50% {
        right: 75%;
        background-color: var(--main-accent-color);
    }
    80% {
        right: 75%;
        background-color: var(--main-accent-color);
    }
    100% {
        right: calc(100% + 50% / 3 + 50% / 9);
        background-color: var(--main-accent-color);
    }
}

.new-version-alert .modal-body,
.browser-support-alert .modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
    gap: 20px;
}

.new-version-alert img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.browser-support-alert img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 9999px;
}

.browser-support-alert .modal-body > .description-container {
    text-align: justify;
}

.new-version-alert .modal-footer,
.browser-support-alert .modal-footer {
    justify-content: center;
}

/*#########################################  Common landing page styling  ############################################*/
.landing-page .container {
    max-width: 960px;
}

.landing-page-carousel-header {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 550px;
}

.landing-page-carousel-header div.carousel {
    position: relative;
    height: 100%;
}

.landing-page-carousel-header div.carousel .carousel-inner {
    height: 100%;
}

.landing-page-carousel-header div.carousel-inner .carousel-caption {
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: unset;
    padding: 0;
}

.landing-page-carousel-header div.carousel-inner .carousel-caption > .container {
    display: grid;
    height: 100%;
}

.landing-page-carousel-header div.carousel-inner .carousel-caption > .container .carousel-header-info-container {
    align-self: center;
}

.landing-page-carousel-header div.carousel-inner > div.carousel-item {
    height: 100%;
    transition: transform 2s ease, -webkit-transform 2s ease;
}

.landing-page-carousel-header div.carousel-inner > div.carousel-item > img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

div.header-static-image-container {
    position:absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

div.header-static-image-container > .container {
    display: flex;
    align-items: flex-end;
    justify-content: right;
    height: 100%;
}

div.header-static-image-container img.header-image-container {
    object-fit: contain;
    object-position: center;
}

div.carousel-header-info-container > div {
    font-family: urbane-rounded, sans-serif;
    color: white;
    text-align: left;
    font-size: 40px;
    line-height: 42px;
}

div.carousel-header-info-container > div.accent {
    color: var(--main-accent-color);
}

.ai-assistant-container {
    position: absolute;
    height: 100%;
    right: 20px;
    width: max-content;
}
/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}

@media only screen and (max-width: 1199px) {

    div.carousel-header-info-container > div {
        font-size: 36px;
        line-height: 38px;
    }
}

@media only screen and (max-width: 1100px) {

    div.carousel-header-info-container > div {
        font-size: 34px;
        line-height: 36px;
    }

}

@media only screen and (max-width: 990px) {

    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 25px !important;
    }

    h3 {
        font-size: 20px !important;
    }

    h4 {
        font-size: 16px !important;
    }

    h5 {
        font-size: 15px !important;
    }

    div.header-static-image-container > img.header-image-container {
        position: absolute;
        object-fit: contain;
        object-position: center;
        height: 70%;
        right: 7%;
        bottom: 0;
    }

    div#main-banner {
        padding: 1em;
    }

    div.area-item > div.area-item-title,
    div.area-item > div.area-item-info {
        font-size: 80%;
    }

    div#main-page-footer > div.container {
        padding: 0 !important;
    }

    div#main-page-footer > div.container > div#main-page-footer-links {
        row-gap: 30px;
    }

    div#main-page-footer > div.container div.community-block {
        grid-row-start: 2;
        grid-column-start: 1;
    }

    div#main-page-footer > div.container div.support-block {
        grid-row-start: 2;
        grid-column-start: 2;
    }

    div#main-page-footer > div.container div.legal-links {
        grid-row-start: 3;
        grid-column: 1 / 3;
    }

    div#main-page-footer > div.container div.version-block {
        grid-row-start: 4;
        grid-column-start: 1;
    }

    div#main-page-footer > div.container div.social-media-block {
        grid-row-start: 4;
        grid-column-start: 2;
    }
}

@media only screen and (max-width: 950px) {

    .landing-page-carousel-header {
        height: 500px;
    }

    div.carousel-header-info-container > div {
        font-size: 30px;
        line-height: 32px;
    }

    div.desktop-submenu-grid {
        justify-content: center;
    }

    .interval-button-container {
        justify-content: center;
    }

    .accordion-item > div > button {
        font-size: 14px;
    }

    div.roomjack-is-green > div.container {
        grid-template-columns: 2fr 1fr;
    }

    div.teaser-container .left-side  {
        margin-left: 50px;
    }

    div.roomjack-is-green img {
        height: auto;
        width: 80%;
        margin-right: 50px;
    }

    .parallax-text {
        font-size: 30px !important;
    }

    .news-box > * {
        width: 300px !important;
    }

    .news-box div.news-image-container {
        height: 200px;
    }

}

@media only screen and (max-width: 750px) {

    h1 {
        font-size: 27px !important;
    }

    h2 {
        font-size: 22px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    div.carousel-header-info-container > div {
        font-size: 28px;
        line-height: 30px;
    }

    div#main-banner-menu-container #main-banner-menu-mode-toggle {
        display: flex;
    }

    button#main-banner-mode-switcher {
        display: none;
    }

    div#main-banner-toggle-container {
        display: none !important;
    }

    div#main-banner-control-container {
        margin-left: auto !important;
    }

    .landing-page-carousel-header {
        height: 450px;
    }

    .hard-fact-column {
        padding: 15px 0 !important;
        max-width: 100% !important;
    }

    div.roomjack-feature {
        padding: 1em 0;
        flex-direction: column;
    }

    div.roomjack-feature.inverted {
        flex-direction: column;
    }

    div.roomjack-feature > div {
        width: 80% !important;
    }

    div#main-registration div#registration-welcome-image {
        width: 30vw !important;
        height: 30vw !important;
    }

    div#main-page-footer > div.container > div#main-page-footer-links {
        justify-content: start;
    }

    .accordion-item > div > button {
        font-size: 12px;
    }

    div.teaser-container div.teaser-header-container,
    div.teaser-container div.teaser-header-container > div {
        font-family: urbane-rounded, sans-serif;
        color: white;
        font-size: 34px;
    }

    div.teaser-container div.teaser-header-container > div {
        line-height: 34px;
    }

    div.roomjack-is-green > div.container {
        grid-template-columns: unset;
    }

    div.roomjack-is-green > div.container > div {
        height: unset;
    }

    div.teaser-container .left-side  {
        margin-left: 30px;
    }

    div.teaser-container .right-side {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    div.roomjack-is-green img {
        margin-right: 30px;
        width: auto;
        height: 200px;
    }

    .parallax-text {
        font-size: 27px !important;
    }
}

@media only screen and (max-width: 500px) {

    h1 {
        font-size: 25px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 16px !important;
    }

    div.carousel-header-info-container > div {
        font-size: 24px;
        line-height: 26px;
    }

    #welcome-text {
        display: none;
    }

    .accordion-item > div > button {
        font-size: 10px;
    }

    .review-modal table td {
        font-size: 12px;
    }

    .review-modal table td button > svg {
        font-size: 16px;
    }

    .container {
        padding: 0 25px !important;
    }

    .landing-page-carousel-header {
        height: 350px;
    }

    div.news-room {
        padding: 20px 0;
    }

    div.teaser-container div.teaser-header-container,
    div.teaser-container div.teaser-header-container > div {
        font-family: urbane-rounded, sans-serif;
        color: white;
        font-size: 30px;
    }

    div.teaser-container div.teaser-header-container > div {
        line-height: 30px;
    }

    div.roomjack-is-green div.teaser-description-container {
        font-size: 12px;
    }

    div#main-page-footer > div.container > div#main-page-footer-links {
        grid-template-columns: repeat(auto-fit, 200px);
    }

    div#main-page-footer > div.container div.social-media-block {
        grid-row-start: 5;
        grid-column-start: 1;
    }

    .parallax-text {
        font-size: 25px !important;
    }

    .accent-radio-button > input + label {
        font-size: 12px;
        padding: 8px;
    }

}

@media only screen and (max-width: 400px) {

    h1 {
        font-size: 20px !important;
    }

    h2 {
        font-size: 18px !important;
    }

    h3 {
        font-size: 15px !important;
    }

    .container {
        padding: 0 10px !important;
    }

    button.accent-icon-button > svg {
        font-size: var(--button-small-font);
    }

    button.accent-icon-button > span {
        font-size: var(--button-small-font);
    }

    .landing-page-carousel-header {
        height: 250px;
    }

    div.carousel-header-info-container > div {
        font-size: 18px;
        line-height: 20px;
    }

    div.roomjack-is-green {
        height: 300px;
    }

    div.teaser-container .left-side  {
        padding-top: 30px;
        margin-left: 0;
        justify-content: flex-start;
    }

    div.teaser-container div.teaser-header-container,
    div.teaser-container div.teaser-header-container > div {
        font-family: urbane-rounded, sans-serif;
        color: white;
        font-size: 26px;
    }

    div.teaser-container div.teaser-header-container > div {
        line-height: 26px;
    }

    div.roomjack-is-green div.teaser-description-container {
        font-size: 12px;
    }

    div.roomjack-is-green img {
        margin-right: 28px;
        height: 150px;
    }

    div#main-page-footer > div.container > div#main-page-footer-links {
        grid-template-columns: repeat(auto-fit, 160px);
    }

    div#main-page-footer > div.container > div#main-page-footer-links div.footer-column-header {
        font-size: 14px;
    }

    div#main-page-footer > div.container div.version-block {
        grid-column: 1 / 3;
    }

    .accent-radio-button > input + label {
        font-size: 10px;
        padding: 6px;
    }

}