.alert-modal div.modal-body {
    color: gray;
    font-size: var(--small-text);
    padding: 3em 2em;
}

.alert-modal div.alert-modal-image {
    display: grid;
    width: 100%;
    margin-bottom: 20px;
}

.alert-modal div.alert-modal-image.icon {
    height: 100px !important;
    width: 100px !important;
    border-radius: 50px;
    background-color: var(--main-accent-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 auto;
}

.alert-modal.landlord div.alert-modal-image.icon {
    background-color: var(--tertiary-accent-color);
}

.alert-modal div.alert-modal-image.icon > svg {
    font-size: 50px;
}

.alert-modal .alert-modal-image > img {
    max-width: 35%;
    justify-self: center;
    max-height: 200px;
}

.alert-modal div.alert-modal-image.rounded img {
    border-radius: 9999px;
}

.alert-modal div.modal-body ul {
    margin-top: 10px;
}