/*######################################################################################################################
                                                MESSAGING MODAL STYLING
######################################################################################################################*/
button.send-message {
    margin-left: auto;
}

.message-sending-error {
    display: grid;
    gap: 20px;
    justify-items: center;
    text-align: center;
}

.message-sending-error > .error-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
    background-repeat: no-repeat;
}