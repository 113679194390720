.custom-dropdown {
    position: relative;
    width: max-content;
}

.custom-dropdown .dropdown-toggle {
    background-color: white;
    outline: none;
    box-shadow: none;
    height: 32px;
    border: 1px solid lightgray;
    border-radius: 16px;
    color: black;
    padding: 0 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    white-space: nowrap;
    font-size: var(--small-text);
    width: 100%;
}

.custom-dropdown.invalid .dropdown-toggle {
    border: 1px solid red;
}

.custom-dropdown .dropdown-toggle > .color-box {
    width: 16px;
    height: 16px;
    border-radius: 5px;
}

.custom-dropdown .dropdown-input {
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: -1;
}

.custom-dropdown .dropdown-toggle > svg.decoration {
    color: var(--main-accent-color);
    margin-right: 5px;
}

div#main-body.landlord .custom-dropdown .dropdown-toggle > svg.decoration {
    color: var(--tertiary-accent-color);
}

.custom-dropdown .dropdown-toggle::after {
    display: none;
}

.custom-dropdown .dropdown-toggle > span {
    margin-right: 5px;
}

.custom-dropdown .dropdown-toggle > svg.arrow {
    margin-left: auto;
}

.custom-dropdown .dropdown-toggle.disabled > svg {
    color: lightgray;
}

.custom-dropdown .dropdown-toggle > span.placeholder {
    color: var(--light-gray-color);
    background-color: white;
    font-family: open-sans-italic, sans-serif;
    font-size: var(--small-text);
    cursor: default;
}

.custom-dropdown .dropdown-toggle.invalid {
    border-color: red;
}

div.dropdown-menu {
    display: initial;
    position: absolute;
    top: 35px;
    border: 1px solid lightgray;
    border-radius: 16px;
    z-index: 9;
    overflow-y: scroll;
    max-height: 130px;
    padding: 5px !important;
}

div.dropdown-menu > div.dropdown-item {
    display: flex;
    padding: 0 5px;
    height: 24px;
    font-size: var(--small-text);
    border-radius: 8px;
    align-items: center;
}

div.dropdown-menu > div.dropdown-item > span {
    margin-left: 5px;
}

div.dropdown-menu > div.dropdown-item > input {
    display: none;
}

div.dropdown-menu > div.dropdown-item > input + label {
    display: flex;
    align-items: center;
    gap: 10px;
}

div.dropdown-menu .dropdown-item:hover {
    background-color: var(--main-accent-color) !important;
    color: white !important;
}

.landlord div.dropdown-menu .dropdown-item:hover {
    background-color: var(--tertiary-accent-color)  !important;
}

div.dropdown-menu > div.dropdown-item:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

div.dropdown-menu > div.dropdown-item > label > div.icon-box {
    width: 16px;
    height: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.dropdown-menu > div.dropdown-item > label.checked > div.icon-box.color > svg {
    color: white;
    font-size: 10px;
}

div.dropdown-menu > div.dropdown-item > label:not(.checked) svg {
    color: transparent;
}

.custom-dropdown.multi-select a.dropdown-item::before {
    border-radius: 4px;
    border: 1px solid gray;
}

.custom-dropdown.multi-select a.dropdown-item:hover::before {
    border: 1px solid white;
}

.custom-dropdown a.dropdown-item.selected::before {
    content: '\f00c' !important;
}

div.dropdown-menu > div.dropdown-item .multi-dropdown-checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

div.dropdown-menu > div.dropdown-item:hover .multi-dropdown-checkbox,
div.dropdown-menu > div.dropdown-item:active .multi-dropdown-checkbox {
    border-color: white;
}