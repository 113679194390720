div.statistic-bar-bg {
    height: 5px;
    background-color: var(--light-gray-color);
    width: 100%;
    border-radius: 1em;
}

div.statistic-bar-bg > div.statistic-bar-fg {
    height: 5px;
    background-color: var(--main-accent-color);
    border-radius: 1em;
    transition: .5s ease-in-out;
}

.landlord div.statistic-bar-bg > div.statistic-bar-fg {
    background-color: var(--tertiary-accent-color);
}

.rating-detail-container,
.rating-distribution-container {
    width: 100%;
    display: grid;
    justify-items: center;
}

table.progress-bar-table {
    max-width: 400px;
}

table.progress-bar-table td:first-child {
    font-size: var(--small-text);
    white-space: nowrap;
}

table.progress-bar-table td:nth-child(2) {
    width: 100%;
}

table.progress-bar-table td:last-child {
    font-size: var(--small-text);
    font-family: open-sans-bold, sans-serif;
}

table.progress-bar-table {
    width: 100%;
}

table.progress-bar-table tr > td:first-child {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

table.progress-bar-table tr > td:first-child > svg {
    color: var(--main-accent-color);
    margin-left: .2em
}

table.progress-bar-table tr > td:nth-child(2) {
    padding: 0 1em;
}

div.rating-average-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 150px;
}

div.rating-average-container > div.rating-value > svg {
    font-size: 35px;
    color: var(--statistics-yellow);
}

div.rating-average-container > div.rating-value > span {
    font-size: 35px;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

div.rating-average-container > div.rating-count {
    font-family: urbane-rounded-light, sans-serif;
    font-size: var(--small-text);
}

.rating-distribution-container table.progress-bar-table tr > td:first-child {
    text-align: right;
    white-space: nowrap;
}

.rating-distribution-container table.progress-bar-table tr > td:first-child > svg {
    color: var(--main-accent-color);
}

.landlord .rating-distribution-container table.progress-bar-table tr > td:first-child > svg {
    color: var(--tertiary-accent-color);
}

.rating-distribution-container table.progress-bar-table tr > td:nth-child(2) {
    width: 100%;
}

.rating-distribution-container table.progress-bar-table tr > td:last-child {
    color: gray;
    font-size: var(--small-text);
    white-space: nowrap;
    font-family: open-sans, sans-serif;
}