/*#######################################   Renter landing page   ####################################################*/
div#rlp-search-header > div.carousel {
    background-color: var(--main-accent-color-light);
}

div#rlp-header {
    height: 100% !important;
}

div#rlp-header div.carousel-header-info-container {
    width: 45%;
}

div#rlp-header .header-static-image-container img.header-image-container {
    width: 55%;
}

div#rlp-search-container {
    display: flex;
    justify-content: center;
    height: var(--main-search-bar-height);
    margin-top: -60px;
    margin-bottom: 60px;
}

div#rlp-search {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    display: flex;
    color: dimgrey;
    text-align: left;
    padding: 0 2em;
    position: relative;
    align-items: center;
    font-size: 1em !important;
    background-color: var(--main-accent-color);
    gap: 1.5em;
    z-index: 2;
    justify-content: center;
}

div#rlp-search div.custom-calendar {
    top: calc(var(--main-search-bar-height) + 1em);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

button#rlp-search-button {
    height: 32px !important;
}

div#hard-facts {
    background-color: white;
    margin-bottom: 50px;
}

div#hard-facts h1 {
    color: var(--main-accent-color);
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
}

div#hard-facts div#hard-facts-description {
    color: gray;
    margin: 30px 0 40px 0;
    text-align: justify;
    font-size: 16px;
}

div#hard-facts h2 {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
}

div#hard-facts div.hard-fact-row {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: center;
}

.hard-fact-column {
    float: left;
    color: dimgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
}

.hard-fact-column > img {
    width: 180px;
    height: 180px;
    border-radius: 90px;
    margin-bottom: 30px;
    object-fit: cover;
}

.hard-fact-column > .hard-fact-column-title {
    color: var(--main-accent-color);
    font-size: 1.2em;
    margin-bottom: 1.2em;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

.hard-fact-column > .hard-fact-column-content {
    font-size: 13px;
    text-align: justify;
}

div#rlp-recommendations {
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/backgrounds/hg_citymap.jpg");
    background-size: cover;
    min-height: 500px;
}

div#rlp-recommendations > div.container {
    padding: 50px 25px 25px 25px;
    position: relative;
}

div#rlp-recommendations > div.container > .area-title {
    color: white;
}

div#rlp-recommendations .carousel-grid > button {
    color: white !important;
}

.area-title {
    text-align: center;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

div#renter-landing-page > div > div.container > .area-description {
    color: gray;
    text-align: center;
    font-size: 120%;
}

div#renter-landing-page > div > div.container > .area-description.italic {
    font-style: italic;
}

.area-item {
    display: grid;
    grid-gap: 10px;
    justify-items: center;
    padding: 5px;
    width: 100%;
}

.area-item.fetching {
    border-radius: 16px;
}

.area-item:not(.fetching):not(.empty) {
    cursor: pointer;
}

.area-item > div.area-image {
    background-color: lightgrey;
    background-size: cover;
    background-position: center;
    border-radius: 9999px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    width: 160px;
    height: 160px;
    overflow: hidden;
}

.area-item > div.area-image > img {
    width: 100%;
    height: 100%;
}

.area-item > div.area-image > svg {
    display: none;
    color: white;
    font-size: 50px;
}

.area-item.fetching > div.area-image > svg {
    display: initial;
}

.area-item > div.area-item-title {
    font-weight: bold;
    color: black;
    text-align: center;
    font-size: 100%;
    max-width: 100%;
    justify-self: center;
}

div#rlp-recommendations .area-item > div.area-item-title {
    color: white !important;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: 14px;
}

.area-item > div.area-item-info {
    color: black;
    text-align: center;
    font-size: 100%;
}

div#rlp-recommendations .area-item > div.area-item-info {
    color: white;
}

.area-item.fetching > div.area-item-title,
.area-item.empty > div.area-item-title {
    background-color: lightgrey;
    width: 100%;
    border-radius: 1em;
    color: transparent;
    height: 24px;
}

.area-item.fetching > div.area-item-info,
.area-item.empty > div.area-item-info {
    background-color: lightgrey;
    width: 70%;
    border-radius: 1em;
    color: transparent;
    height: 20px;
}

div#rlp-recommendations > div.container > div.area-grid {
    display: grid;
    grid-template-columns: repeat(3, 160px);
    gap: 2em;
    justify-content: center;
}

div#rlp-special-search-categories {
    margin: 2em 0;
}

div#rlp-recommendations div.carousel > a.carousel-control-prev,
div#rlp-recommendations div.carousel > a.carousel-control-next,
div#rlp-special-search div.carousel > a.carousel-control-prev,
div#rlp-special-search div.carousel > a.carousel-control-next {
    width: max-content;
    height: 100%;
}

div#rlp-recommendations div.carousel > a.carousel-control-prev > svg,
div#rlp-recommendations div.carousel > a.carousel-control-next > svg {
    color: white !important;
}

div#rlp-special-search {
    background-color: var(--main-accent-color-light);
}

div#rlp-special-search .area-title {
    color: var(--main-accent-color);
}

div#rlp-special-search > div.container {
    padding: 4em 1em 2em 1em;
}

#renter-landing-page > .parallax {
    height: 550px;
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/backgrounds/Zitatelement.jpeg");
    background-attachment: fixed;
    position: relative;
}

#renter-landing-page > .parallax::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--main-accent-color);
    opacity: .5;
    z-index: 1;
    width: 100%;
    height: 100%;
}

#renter-landing-page > .parallax .parallax-text {
    text-align: center;
    z-index: 2;
    position: relative;
}

#renter-landing-page > .parallax .container > .description-container {
    color: white;
    text-align: center;
    margin-top: 20px;
    z-index: 2;
    position: relative;
}

.landing-page-rental-info {
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/landlord-landing-page/backgrounds/hg_vermieten.jpg");
    background-size: cover;
    color: white;
}

.landing-page-rental-info > .container {
    padding: 6em 1em;
    position: relative;
}

.landing-page-rental-info-container {
    width: 40%;
    align-items: center;
}

.landing-page-rental-info-container > div > div.header {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: 40px;
    line-height: 100%;
}

.landing-page-rental-info-container > div  > div.header.small {
    font-size: 20px;
}

#landlord-landing-page .landing-page-rental-info-container > div  > div.header {
    white-space: nowrap !important;
    text-transform: initial !important;
    margin-bottom: 30px;
}

#landlord-landing-page .landing-page-rental-info-container > div  > div.header:not(.small) {
    font-size: 50px;
}

#landlord-landing-page .landing-page-rental-info-container > div  > div.header.small {
    font-size: 18px;
}

.landing-page-rental-info-container > div > div:not(.header) {
    font-family: 'open-sans', sans-serif;
    font-size: 20px;
    margin: 35px 0;
}

.landing-page-rental-info-container > div  > a > svg {
    font-size: 16px;
    margin-right: 5px;
}

.landing-page-rental-info-container > div  > a > span {
    white-space: nowrap;
}


.landing-page-rental-info-image-container {
    position: absolute;
    right: -40px;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
}

.landing-page-rental-info-image-container > img {
    height: 450px;
}

#landlord-landing-page .landing-page-rental-info-image-container {
    right: 0;
}

#landlord-landing-page .landing-page-rental-info-image-container {
    bottom: -68px;
}

#landlord-landing-page .landing-page-rental-info-image-container > img {
    height: 280px;
}

#landlord-news-room .carousel-grid,
#renter-landing-page .carousel-grid {
    margin: 50px 0;
}

/*#######################################   Landlord landing page   ##################################################*/
div#llp-header {
    height: 100% !important;
}

div#llp-carousel-header div.carousel {
    background-color: var(--tertiary-accent-color-light);
}

div#llp-header div.carousel-inner > div.carousel-item > img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

div#llp-header .header-static-image-container img.header-image-container {
    height: 75%;
}

#llp-start-container {
    position: relative;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: -100px;
    z-index: 2;
    margin-bottom: 100px;
    tab-index: 0;
}

#llp-start-container > div {
    background-color: white;
    border-radius: 20px;
    box-shadow: var(--container-shadow-light);
    display: flex;
    gap: 20px;
}

#llp-start-container > div.create-advert-container {
    grid-column: span 2;
    padding: 30px 50px;
}

#llp-start-container > div.create-advert-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

#llp-start-container > div.create-advert-container > div > button {
    margin-top: 30px;
    width: max-content;
    padding: 0 20px;
}

#llp-start-container > div.create-advert-container > img {
    height: 300px;
    background-repeat: no-repeat;
    margin-left: auto;
}

.registration-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: .2s ease-in-out;
    padding: 30px;
}

.registration-container:hover {
    transform: translate(0, -30px);
}

.registration-container:hover + .registration-container,
.registration-container:has(+ .registration-container:hover) {
    opacity: .5;
}

.create-advert-container .prefix,
.create-advert-container .label,
.registration-container .prefix,
.registration-container .label {
    color: var(--main-accent-color);
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

.create-advert-container .prefix,
.create-advert-container .label,
.registration-container.b2b .prefix,
.registration-container.b2b .label {
    color: var(--tertiary-accent-color);
}

.create-advert-container .label,
.registration-container .label {
    font-size: 34px;
    line-height: 34px;
}

.registration-container > img {
    height: 200px;
    object-position: center;
    object-fit: contain;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

div#llp-roomjack-features > div.container {
    max-width: 952px;
}

div#llp-roomjack-features > div.container > h1 {
    font-family: urbane-rounded, sans-serif;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.3em;
}

div#llp-roomjack-features .roomjack-feature .roomjack-headline {
    margin-bottom: 20px;
}

div#llp-become-b2b {
    background-image: url("https://roomjack.blob.core.windows.net/roomjack/renter-landing-page/backgrounds/hg_citymap.jpg");
    background-size: cover;
    color: white;
    height: 500px;
}

div#llp-become-b2b > .container {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

div#llp-become-b2b > .container > .landing-page-rental-info-container div.header {
    text-transform: uppercase !important;
    white-space: break-spaces !important;
}

div#llp-become-b2b > .container > .landing-page-rental-info-image-container {
    bottom: 0;
}

div#llp-become-b2b > .container > .landing-page-rental-info-image-container > img {
    height: 450px
}

div#llp-roomjack-features .roomjack-feature .roomjack-headline {
    margin-bottom: 20px;
}

div#llp-roomjack-functions > div.container {
    padding: 50px 0;
    #border-top: 1px solid #e2e2e2;
}

div#llp-roomjack-functions > div.container > h2 {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

div#llp-roomjack-functions div#llp-function-carousel > a {
    height: 100%;
    width: 50px;
    color: gray !important;
}

#llp-function-carousel div.carousel-item {
    display: flex;
    justify-content: center;
}

#llp-function-carousel div.carousel-item > div.carousel-item-grid {
    grid-template-columns: repeat(auto-fit, 280px);
    padding: 40px 0;
    gap: 20px;
    width: max-content;
}

div.roomjack-function-wrapper {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

div.roomjack-function {
    box-shadow: var(--container-shadow-light);
    border-radius: 1em;
    background-color: white;
    height: 320px;
    width: 280px;
    padding: 1em;
    transform: translate(0, 0);
    transition: .3s ease-in-out;
}

div.roomjack-function:hover {
    transform: translate(0, -15px);
}

div.roomjack-function > div.roomjack-function-icon {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 2px solid var(--tertiary-accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5em;
}

div.roomjack-function > div.roomjack-function-icon > svg {
    color: var(--tertiary-accent-color);
    font-size: 1.5em;
}

div.roomjack-function > h6 {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--tertiary-accent-color);
    text-align: left;
    height: 40px;
}

div.roomjack-function > div.roomjack-function-text {
    color: gray;
    font-size: 13px;
    text-align: left;
    height: max-content;
}

div#llp-testimonials div.carousel-caption {
    height: max-content;
}

div#llp-testimonials div.carousel-caption > div {
    height: max-content;
}

div#llp-testimonials div.carousel-item img {
    width: 100%;
    max-height: 700px;
    object-fit: cover;
}

div#llp-testimonials div#llp-testimonial-item-1 img,
div#llp-testimonials div#llp-testimonial-item-2 img,
div#llp-testimonials div#llp-testimonial-item-3 img {
    object-position: top;
}

div#llp-testimonials div#llp-testimonial-item-4 img {
    object-position: 0 33%;
}

div#llp-testimonials div.carousel-caption > div > div.llp-testimonial-content {
    text-transform: uppercase;
    color: var(--tertiary-accent-color);
    text-align: left;
    font-family: urbane-rounded, sans-serif;
    font-size: 1vw;
}

div#llp-testimonials div.carousel-caption > div > div.llp-testimonial-name {
    text-align: left;
    font-size: .6vw;
    margin-top: 20px;
}

div#llp-testimonials .carousel-caption {
    position: absolute;
    width: 20%;
    background-color: #000000AA;
    padding: 2%;
    border-radius: 16px;
}

div#llp-testimonials div#llp-testimonial-item-1 div.carousel-caption,
div#llp-testimonials div#llp-testimonial-item-2 div.carousel-caption {
    left: 10%;
    top: 10%;
}

div#llp-testimonials div#llp-testimonial-item-3 div.carousel-caption,
div#llp-testimonials div#llp-testimonial-item-4 div.carousel-caption,
div#llp-testimonials div#llp-testimonial-item-5 div.carousel-caption {
    left: unset !important;
    bottom: unset !important;
    right: 10%;
    top: 10%;
}

div#llp-testimonials div.carousel-indicators {
    gap: 8px;
    align-items: center;
    height: max-content;
}

div#llp-testimonials div.carousel-indicators > button {
    height: 9px;
    width: 9px;
    border-radius: 5px;
    overflow: clip;
    border: none !important;
}

div#llp-testimonials div.carousel-indicators > button.active {
    height: 5px;
    width: 25px;
}

#landlord-landing-page .landing-page-rental-info-image-container > img {
    height: 400px;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 1199px) {

    div#llp-header div.carousel-image-container {
        height: 60% !important;
        width: 55% !important;
        margin-top: auto;
    }
}

@media only screen and (max-width: 1050px) {
    .landing-page-rental-info-image-container > img {
        height: 400px;
    }

    .landing-page-rental-info-image-container {
        right: 25px;
    }
}

@media only screen and (max-width: 990px) {

    div#llp-header .header-static-image-container img.header-image-container {
        height: 65%;
    }

    #llp-start-container {
        margin-top: -80px;
        margin-bottom: 80px;
    }

    .registration-container > img {
        height: 180px;
    }

    .create-advert-container .label,
    .registration-container .label {
        font-size: 30px;
        line-height: 30px;
    }

    div.hard-fact-column > img {
        width: 180px;
        height: 180px;
    }

    div.hard-fact-column > .hard-fact-column-title {
        text-align: center;
    }
}

@media only screen and (max-width: 950px) {

    #llp-start-container > div.create-advert-container > img {
        height: 200px;
    }

    div#llp-roomjack-functions > div.container > h2 {
        text-align: center;
    }

    div.roomjack-function:hover {
        transform: translate(0, 0) !important;
    }

    .landing-page .container {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    div#rlp-recommendations {
        min-height: max-content;
    }

    #rlp-recommendations > .container {
        padding: 40px 25px 0 25px !important;
    }

    #renter-landing-page .parallax {
        height: 400px;
    }

    div#llp-become-b2b > .container > .landing-page-rental-info-image-container > img,
    .landing-page-rental-info-image-container > img {
        height: 350px;
    }
}

@media only screen and (max-width: 850px) {

    div#rlp-search-container .calendar,
    div#rlp-search-container #rlp-guest-count-container {
        display: none;
    }

    div#rlp-search-container #rlp-search-location-container  {
        flex: 1;
    }

    div#rlp-search-container #rlp-search-location-container > .custom-dropdown {
        width: 100%;
    }

    div#rlp-search-container > div#rlp-search > button {
        width: 32px;
    }

    div#rlp-search-container > div#rlp-search > button > svg {
        color: white;
        font-size: 16px;
        margin: 0;
        font-weight: 700;
    }

    div#rlp-search-container > div#rlp-search > button > span {
        display: none;
    }

}

@media only screen and (max-width: 750px) {

    .registration-container {
        width: 300px;
    }

    .registration-container button > span:nth-child(2) {
        display: none;
    }

    #llp-start-container > div.create-advert-container > img {
        height: 180px;
    }

    #llp-start-container {
        margin-top: -70px;
        margin-bottom: 70px;
        justify-items: center;
    }

    #llp-start-container > div.create-advert-container {
        padding: 30px;
    }

    .registration-container > img {
        height: 150px;
    }

    .create-advert-container .label,
    .registration-container .label {
        font-size: 25px;
        line-height: 25px;
    }

    div#rlp-header div.carousel-caption {
        height: 100% !important;
    }

    div#hard-facts > h1 {
        margin-top: 40px;
    }

    div#llp-become-b2b > .container > .landing-page-rental-info-image-container > img,
    .landing-page-rental-info-image-container > img {
        height: 300px;
    }

    #landlord-landing-page .landing-page-rental-info-image-container > img {
        height: 200px;
    }

    #landlord-landing-page .landing-page-rental-info-image-container {
        bottom: -34px;
    }

    .landing-page-rental-info-container > div > div.header {
        font-size: 30px;
    }

    .landing-page-rental-info-container > div  > div.header.small {
        font-size: 16px;
    }

    .landing-page-rental-info-container > div > div:not(.header) {
        font-size: 16px;
    }

    div.llp-header-info-container > div {
        font-size: 3vw !important;
    }

    div#llp-header div.carousel-image-container {
        height: 50% !important;
        margin-top: auto;
    }

    div#llp-roomjack-features div.feature-text-content {
        font-size: 16px;
    }

    #renter-landing-page .parallax {
        height: 350px;
    }
}

@media only screen and (max-width: 700px) {

    .registration-container {
        width: 280px;
        padding: 20px;
    }

    .create-advert-container .label,
    .registration-container .label {
        font-size: 20px;
        line-height: 20px;
    }

    div#llp-become-b2b > .container > .landing-page-rental-info-image-container > img,
    .landing-page-rental-info-image-container > img {
        height: 200px;
    }

}

@media only screen and (max-width: 650px) {

    .registration-container {
        width: 250px;
        padding: 15px;
    }

    .create-advert-container .label,
    .registration-container .label {
        font-size: 20px;
        line-height: 20px;
    }

}

@media only screen and (max-width: 600px) {

    #llp-start-container > div.create-advert-container > img {
        height: 150px;
    }

    #llp-start-container > div.create-advert-container {
        padding: 20px;
    }

    div#hard-facts div#hard-facts-description {
        margin: 20px 0;
    }

    div#hard-facts div.hard-fact-row {
        gap: 10px;
    }

    .hard-fact-column {
        width: 100%;
    }

}

@media only screen and (max-width: 550px) {

    .registration-container {
        width: 200px;
        padding: 15px;
    }

    .registration-container > img {
        height: 100px;
    }

    .create-advert-container .label,
    .registration-container .label {
        font-size: 16px;
        line-height: 16px;
    }

}

@media only screen and (max-width: 500px) {

    .landing-page .container {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    #llp-start-container > div.create-advert-container > img {
        height: 120px;
    }

    div#llp-header .header-static-image-container img.header-image-container {
        height: 55%;
    }

    #llp-start-container {
        margin-top: -50px;
        margin-bottom: 50px;
    }

    .registration-container {
        padding: 10px;
        width: 175px;
    }

    .registration-container > img {
        height: 80px;
    }

    .create-advert-container .prefix,
    .registration-container .prefix {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 3px;
    }

    .create-advert-container .label,
    .registration-container .label {
        font-size: 13px;
        line-height: 14px;
    }

    #rlp-header div.carousel-header-info-container {
        margin-top: -40px;
        width: 90% !important;
    }

    #rlp-search-container {
        margin-top: -35px !important;
        margin-bottom: 35px !important;
        height: 70px !important;
    }

    #rlp-search {
        padding: 15px;
    }

    #rlp-special-search > .container {
        padding: 25px !important;
    }

    #rlp-special-search .area-description {
        font-size: 14px !important;
    }

    .landing-page-rental-info > .container {
        padding: 40px 25px !important;
    }

    .landing-page-rental-info-container > div > div:not(.header) {
        font-size: 14px;
    }

    div#hard-facts > h1 {
        margin-top: 30px;
    }

    div#llp-roomjack-functions > div.container {
        padding: 20px 0 !important;
    }

    #renter-landing-page .parallax {
        height: 300px;
    }

    #landlord-landing-page .landing-page-rental-info-image-container {
        bottom: -26px;
    }

    .landing-page-rental-info-image-container > img ,
    #landlord-landing-page .landing-page-rental-info-image-container > img {
        height: 150px;
    }

    #landlord-landing-page .landing-page-rental-info-container > div > div.header.small {
        font-size: 14px;
    }

    #landlord-landing-page .landing-page-rental-info-container > div > div.header:not(.small) {
        font-size: 40px;
    }

}

@media only screen and (max-width: 400px) {

    #rlp-recommendations > .container,
    #renter-news-room > .container,
    #landlord-news-room > .container,
    #rlp-special-search > .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .landing-page-rental-info-container {
        width: 100%;
    }

    .landing-page-rental-info-container > div > div:not(.header) {
        margin: 35px 0 50px 0;
    }

    .landing-page-rental-info-container a.outline-button {
        padding: 0 10px;
    }

    #rlp-header div.carousel-header-info-container {
        width: 100% !important;
    }

    #llp-start-container {
        margin-top: -30px;
        margin-bottom: 30px;
    }

    #llp-start-container > div.create-advert-container > img {
        height: 100px;
    }

    .registration-container {
        width: 160px;
    }

}

@media only screen and (max-width: 350px) {

    .registration-container {
        width: 130px;
    }

    .registration-container .label {
        font-size: 11px;
    }

    .registration-container .prefix {
        font-size: 10px;
    }

    .landing-page-rental-info-image-container > img,
    #landlord-landing-page .landing-page-rental-info-image-container > img  {
        height: 110px;
    }

    div.roomjack-function {
        height: 350px;
    }

    #llp-start-container > div.create-advert-container > img {
        height: 80px;
    }

}

@media (any-hover: none) {

    .registration-container:hover {
        transform: unset;
    }

}