div.calendar {
    position: relative;
    display: grid;
    width: max-content;
    height: 32px;
}

div.calendar.always-show {
    height: max-content;
}

div.calendar * {
    user-select: none;
}

div.calendar:not(.hide) {
    background-color: lightgrey;
    padding: 5px;
    border-radius: 20px;
    display: grid;
    gap: 5px;
    justify-items: center;
}

/*######################################################################################################################
                                                    CALENDAR BUTTONS
######################################################################################################################*/

div.calendar-button-container {
    display: flex;
    align-items: center;
}

.calendar-button-container.labeled {
    display: grid;
    position: relative;
}

div.calendar-button-container div.calendar-button {
    flex: 1;
    height: 32px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-right: 20px;
    padding-left: 5px;
    border: 2px solid white;
}

.calendar-button-container.labeled > div.start.time-label {
    grid-column-start: 1;
    grid-row-start: 1;
}

.calendar-button-container.labeled > div.gap {
    grid-column-start: 2;
    grid-row-start: 1;
}

.calendar-button-container.labeled > div.end.time-label {
    grid-column-start: 3;
    grid-row-start: 1;
}

.calendar-button-container div.start {
    grid-column-start: 1;
    grid-row-start: 1;
    border-radius: 9999px 0 0 9999px;
    -webkit-border-radius: 9999px 0 0 9999px;
}

.calendar-button-container.labeled > div.start {
    grid-row-start: 2;
}

.calendar-button-container > div.calendar-dash {
    grid-column-start: 2;
    grid-row-start: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    height: 32px;
    padding: 0 .1em;
}

.calendar-button-container.labeled > div.calendar-dash {
    grid-row-start: 2;
}

.calendar-button-container div.end {
    grid-column-start: 3;
    grid-row-start: 1;
    border-radius: 0 9999px 9999px 0;
    -webkit-border-radius: 0 9999px 9999px 0;
}

.calendar-button-container.labeled > div.end {
    grid-row-start: 2;
}

.calendar-button-container.labeled div.time-label {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: var(--button-small-font);
    margin-bottom: 8px;
}

div.calendar div.calendar-button-container div.calendar-button.selected {
    height: 48px;
    margin: -8px 0 -8px 0;
}

div.calendar-button-container div.calendar-button.empty > span {
    color: lightgrey !important;
    font-family: open-sans-italic, sans-serif !important;
    text-align: left;
}

div.calendar-button-container div.calendar-button > svg + span {
    margin-left: 20px;
}

div.calendar-button-container div.calendar-button > button {
    display: none;
    position: absolute;
    right: .2em;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: var(--small-text);
}

div.calendar-button-container div.calendar-button > span {
    text-align: center;
    width: 80px;
    font-size: var(--small-text);
}

div.calendar-button-container div.calendar-button > button > svg {
    font-size: 1.2em;
}

div.calendar-button-container div.calendar-button:not(.empty) > button {
    display: inline-grid;
}

div.calendar-button-container div.calendar-button:first-child > svg {
    color: var(--main-accent-color);
    position: absolute;
    width: 1em;
    left: .5em;
}

.landlord div.calendar-button-container div.calendar-button:first-child > svg {
    color: var(--tertiary-accent-color);
}

/*######################################################################################################################
                                                    CALENDAR CONTAINER
######################################################################################################################*/
div.calendar-container {
    background-color: white;
    border-radius: 15px;
    width: max-content;
    padding: 15px;
    outline: none;
    transition: height .5s ease-in-out;
}

.hide div.calendar-container {
    position: absolute;
    box-shadow: var(--container-shadow);
    z-index: 100;
    top: 40px;
}

div.calendar-button-container.labeled + div.calendar-container {
    top: 60px;
}

div.calendar-container.left {
    justify-self: left;
}

div.calendar-container.center {
    justify-self: center;
}

div.calendar-container.right {
    justify-self: right;
}

div.calendar-container > div.calendar-main-container {
    position: relative;
    display: flex;
    gap: 3em;
}

div.calendar-container div.calendar-table-container {
    position: relative;
    flex: 1;
    padding: .5em;
}

div.calendar-container div.month-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.calendar-container div.month-display {
    text-align: center;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    margin: auto;
    color: black;
}

div.calendar-container div.site-arrow {
    width: 20px;
    height: 20px;
    color: var(--main-accent-color);
    cursor: pointer;
    border-radius: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.calendar-container div.site-arrow > svg {
    font-size: 15px;
}

.landlord div.calendar-container div.site-arrow:not(.disabled) {
    color: var(--tertiary-accent-color);
}

div.calendar-container div.site-arrow.disabled {
    color: var(--light-gray-color);
    cursor: initial;
}

div.calendar-container div.site-arrow:not(.disabled):hover {
    color: white;
    background-color: var(--main-accent-color);
}

.landlord div.calendar-container div.site-arrow:not(.disabled):hover {
    color: white;
    background-color: var(--tertiary-accent-color);
}

div.calendar-container table {
    margin-top: 25px;
    display: table;
}

div.calendar-container tr {
    margin-bottom: 3px;
}

div.calendar-container table td,
div.calendar-container table th {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 30px;
    font-family: urbane-rounded-light, sans-serif;
    color: black;
    padding: 0;
    position: relative;
    font-size: 14px;
}

div.calendar-container table td.cell.day-of-week-marker > div {
    border: 2px solid gray;
}

div.calendar-container table td.cell.between-selected {
    border-right: 1px solid var(--main-accent-color-medium);
    border-left: 1px solid var(--main-accent-color-medium);
}

.landlord div.calendar-container table td.cell.between-selected {
    border-right: 1px solid var(--tertiary-accent-color-medium);
    border-left: 1px solid var(--tertiary-accent-color-medium);
}

div.calendar-container table td.cell.selected::after {
    content: '';
    height: 30px;
    width: 50%;
    position: absolute;
    z-index: 0;
}

div.calendar-container table td.cell.selected.start:not(.end)::after,
div.calendar-container table td.cell.selected.end:not(.start)::after {
    background-color: var(--main-accent-color-medium);
}

.landlord div.calendar-container table td.cell.selected.start:not(.end)::after,
.landlord div.calendar-container table td.cell.selected.end:not(.start)::after {
    background-color: var(--tertiary-accent-color-medium);
}

div.calendar-container table td.cell.selected.start:not(.end) {
    border-right: 1px solid var(--main-accent-color-medium);
}

.landlord div.calendar-container table td.cell.selected.start:not(.end) {
    border-right: 1px solid var(--tertiary-accent-color-medium);
}

div.calendar-container table td.cell.selected.start::after {
    right: 0;
}

div.calendar-container table td.cell.selected.end:not(.start) {
    border-left: 1px solid var(--main-accent-color-medium);
}

.landlord div.calendar-container table td.cell.selected.end:not(.start) {
    border-left: 1px solid var(--tertiary-accent-color-medium);
}

div.calendar-container table td.cell.selected.end::after {
    left: 0;
}

div.calendar-container table th {
    color: darkgray !important;
}

div.calendar-container table tr.row td.cell > div {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2em;
    font-family: urbane-rounded-light, sans-serif;
    z-index: 1;
}

div.calendar-container table tr.row td.cell.selected > div {
    background-color: var(--main-accent-color);
    color: white;
}

.landlord div.calendar-container table tr.row td.cell.selected > div {
    background-color: var(--tertiary-accent-color);
}

div.calendar-container table tr.row td.cell.between-selected > div {
    width: 100%;
    height: 30px;
    border-radius: 0;
    background-color: var(--main-accent-color-medium);
}

.landlord div.calendar-container table tr.row td.cell.between-selected > div {
    background-color: var(--tertiary-accent-color-medium);
}

div.calendar-container table tr.row td.cell.disabled > div {
    color: var(--light-gray-color);
}

div.calendar-container table tr.row td.cell:hover:not(.h):not(.disabled) > div  {
    background-color: var(--main-accent-color);
    color: white;
    cursor: pointer;
}

.landlord .calendar-container table tr.row td.cell:hover:not(.h):not(.disabled) > div  {
    background-color: var(--tertiary-accent-color);
    color: white;
}

div.calendar-container > div.calendar-footer {
    color: darkgray;
}

div.calendar-container:not(.single) > div.calendar-footer {
    text-align: center;
}

div.calendar-container > div.calendar-footer:not(:empty) {
    margin-top: 15px;
    padding: 15px 0;
    border-top: 1px solid var(--light-gray-color);
}

@media only screen and (max-width: 500px) {

    div.calendar-container {
        padding: 10px;
    }

    div.calendar-container > div.calendar-footer:not(:empty) {
        margin-top: 10px;
        padding: 10px 0;
        font-size: var(--small-text);
    }

    div.calendar-button-container div.calendar-button {
        padding-right: 20px;
        padding-left: 5px;
    }

    .calendar-button-container > div.calendar-dash {
        font-size: var(--small-text);
    }

}