/*######################################################################################################################
                                                    IMAGE UPLOADER
######################################################################################################################*/
.image-uploader {
    display: grid;
    position: relative;
    height: max-content;
    width: max-content;
}

.image-uploader .drop-zone {
    display: flex;
    align-items: center;
    justify-content: center;
    float:left;
}

.image-uploader .drop-zone__input {
    display: none;
}

.image-uploader .drop-zone__placeholder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #e2e2e2;
    background-size: cover;
    text-align: center;
    padding: .5em;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.image-uploader .drop-zone__placeholder.disabled {
    cursor: initial;
}

.image-uploader .drop-zone__placeholder > span {
    font-size: 12px;
    color: black;
}

.image-uploader .drop-zone__placeholder > span.image-selector-header-label {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    z-index: 1;
}

.image-uploader .drop-zone__placeholder > span.image-selector-info-label {
    color: gray;
    z-index: 1;
}

.image-uploader .drop-zone__image {
    background-color: #e2e2e2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
}

.image-uploader .drop-zone__image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    background-repeat: no-repeat;
}

.image-uploader button.image-remover {
    position: absolute;
    opacity: 0;
    color: white;
    border: 2px solid white !important;
    transition: .2s ease-in-out;
    width: 60px;
    height: 60px;
    border-radius: 30px;
}

.image-uploader .drop-zone__image:hover > button.image-remover {
    opacity: 1;
}

.image-uploader .drop-zone__image > button.image-remover > svg {
    font-size: 24px;
}

.image-uploader button.image-uploader-edit-button {
    border: none;
    background-color: var(--main-accent-color);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#main-body.landlord .image-uploader button.image-uploader-edit-button {
    background-color: var(--tertiary-accent-color);
}