div.gantt-container {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

div.gantt-container > .no-data-container {
    width: 500px;
    max-width: 100%;
}

div.gantt-container > table {
    height: max-content;
    table-layout: fixed;
    width: max-content;
    border-collapse: collapse;
    position: relative;
    overflow: clip;
}

div.gantt-container > table.gantt-header tr > th {
    text-align: center;
}

div.gantt-container > table.gantt-header tr > th.today {
    background-color: var(--tertiary-accent-color);
    border-radius: 16px;
    color: white;
}

div.gantt-container > table.gantt-header tr > th.past {
    #background-color: var(--light-gray-color);
    color: var(--light-gray-color);
}


div.gantt-container > table tr > *:not(:first-child) {
    width: 45px;
    padding: 0;
}

div.gantt-container > table tr > th {
    height: 45px;
    border-left: 1px solid white;
}

div.gantt-container > table tr > th:last-child {
    border-right: 1px solid white;
}

div.gantt-container > table tr > td {
    height: 65px;
    position: relative;
}

div.gantt-container > table tr > *:first-child > div {
    width: 200px;
}

div.gantt-container > table.gantt-header tr > th:first-child > div {
    display: flex;
}

div.gantt-container > table.gantt-header tr > th:first-child > div > button {
    outline: none;
    background-color: white;
    border: none;
    color: var(--main-accent-color);
    padding: 0;
    width: 10px;
}

.landlord div.gantt-container > table.gantt-header tr > th:first-child > div > button {
    color: var(--tertiary-accent-color);
}

div.gantt-container > table.gantt-header tr > th:first-child > div > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    margin: 0 auto;
}

div.gantt-container > table.gantt-header tr > th:not(:first-child) > div:first-child {
    font-family: urbane-rounded, sans-serif;
}

div.gantt-container > table.gantt-header tr > th:not(:first-child) > div:last-child {
    color: gray;
    font-size: var(--small-text);
    font-family: open-sans, sans-serif;
}

div.gantt-container > table.gantt-header tr > th.past:not(:first-child) > div:last-child {
    color: var(--light-gray-color);
}

div.gantt-container > table.gantt-header tr > th.today > div:last-child {
    color: white;
}

div.gantt-container > table tr > *:first-child {
    white-space: nowrap;
    position: sticky !important;
    position: -webkit-sticky;
    left: 0;
    background-color: white;
    padding: 0 5px;
    z-index: 1;
    width: 210px;
}

div.gantt-container > table tr > *:first-child > div {

}

div.gantt-container > table:not(:first-child) {
    margin-top: 1.5em;
    border-collapse: separate;
    border-spacing: 0;
}

div.gantt-container > table tr.table-data-row > td.border {
    border-top: 1px solid darkgray !important;
    border-left: 1px solid darkgray !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
}

div.gantt-container > table tr.table-data-row > td.border.last {
    border-right: 1px solid darkgray !important;
    overflow: hidden;
}

div.gantt-container > table tr.table-data-row:last-child > td.border {
    border-bottom: 1px solid darkgray !important;
}

div.gantt-container > table tr.table-data-row:first-child > td.border.last {
    -moz-border-radius-topright: 16px;
    -webkit-border-top-right-radius: 16px;
}

div.gantt-container > table tr.table-data-row:last-child > td.border.last {
    -moz-border-radius-bottomright: 16px;
    -webkit-border-bottom-right-radius: 16px;
}

div.gantt-container > table tr.table-data-row > td {
    height: 64px;
    text-align: center;
}

div.gantt-container > table tr.table-data-row > td:not(:first-child) {
    color: white;
}

div.gantt-container > table tr.table-data-row > td:first-child {
    font-size: var(--small-text);
    text-align: left;
}

div.gantt-container > table tr.table-data-row > td:first-child > div:first-child {
    font-family: urbane-rounded, sans-serif;
    display: flex;
}

div.gantt-container > table tr.table-data-row > td:first-child > div:first-child > span {
    font-family: urbane-rounded, sans-serif;
}

div.gantt-container > table tr.table-data-row > td:first-child > div:first-child > button {
    margin-left: auto;
}

div.gantt-container > table tr.table-data-row > td:first-child > div:first-child > .cm-info {
    margin-left: auto;
    color: var(--tertiary-accent-color);
}

div.gantt-container > table tr.table-data-row > td:first-child > div:first-child > .cm-info > svg {
    font-size: 16px;
}

div.gantt-container > table tr.table-data-row > td:first-child > div:nth-child(2) {
    color: gray;
}

div.gantt-container > table tr.table-data-row > td:not(:first-child):not(.inactive):not(.blocked):not(.past).border {
    overflow: clip;
}

div.gantt-container > table tr.table-data-row > td.group-overview-head > div {
    display: flex;
    gap: 5px;
}

div.gantt-container > table tr.table-data-row > td.group-overview-head > div > div {
    flex: 1;
    font-family: urbane-rounded, sans-serif;
}

div.gantt-container > table tr.table-data-row > td.group-overview-head > div > button {
    transition: .2s ease-in-out;
}

div.gantt-container > table tr.table-data-row > td.group-overview-head > div > button:not(.collapsed) {
    transform: rotate(-180deg);
}

div.gantt-container > table tr.table-data-row .group-overview {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

div.gantt-container > table tr.table-data-row .group-overview > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: urbane-rounded, sans-serif;
    flex: 1;
}

div.gantt-container > table tr.table-data-row > .past {
    background-color: var(--light-gray-color);
    filter: saturate(15%);
}

div.gantt-container > table tr.table-data-row > td.inactive {
    background-color: gray;
}

div.gantt-container > table tr.table-data-row > td.blocked:not(.divided) {
    cursor: initial !important;
}

div.gantt-container > table tr.table-data-row > td.blocked:not(.divided)::before {
    content: ' ';
    width: 100%;
    height: 100%;
    display: block;
    background: repeating-linear-gradient(
        25deg,
        transparent,
        transparent 6px,
        white 6px,
        white 10px
    );
}

div.gantt-container > table tr.table-data-row > td.blocked.divided > div > div {
    cursor: initial !important;
    background: repeating-linear-gradient(
            25deg,
            transparent,
            transparent 6px,
            white 6px,
            white 10px
    );
}

div.gantt-container > table tr.table-data-row .free:not(.past) {
    cursor: pointer;
}

div.gantt-container > table tr.table-data-row .booking-block {
    background-color: #cc3333;
    cursor: pointer;
}

div.gantt-container > table tr.table-data-row .external-booking-block {
    background-color: var(--tertiary-accent-color-medium);
}

div.gantt-container > table tr.table-data-row .override {
    cursor: initial !important;
}
/*######################################################################################################################
                                                Booking gantt
######################################################################################################################*/
div.gantt-container > table tr.table-data-row > td.reserved.divided > div.check-in-out-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
}

div.gantt-container > table tr.table-data-row .reserved:not(.divided) {
    background-color: #54ef54;
    cursor: pointer;
}

div.gantt-container > table tr.table-data-row > .reserved.divided div.check-out {
    border-radius: 0 0 1em 1em;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
    cursor: pointer;
    background-color: #54ef54;
}

div.gantt-container > table tr.table-data-row > .reserved.divided div.check-in {
    border-radius: 1em 1em 0 0;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
    background-color: #54ef54;
}

div.gantt-container > table tr.table-data-row > td.reserved.divided div.check-in > svg {
    transform: rotateY(180deg);
}

/*######################################################################################################################
                                                Current price gantt
######################################################################################################################*/
div.gantt-container > table tr.table-data-row > td.standard-price,
div.gantt-container > table tr.table-data-row > td.current-price {
    color: black;
    font-size: 10px;
    cursor: pointer;
}

div.gantt-container > table tr.table-data-row > td.current-price {
    background-color: #1da1f2;
}