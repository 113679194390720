.rating-input {
    display: flex;
    position: relative;
    gap: 5px;
    width: min-content;
}

.rating-input > input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    pointer-events: none;
}

.rating-input > button {
    width: max-content;
    padding: 0;
}

.rating-input > button > svg {
    font-size: 25px;
    color: var(--main-accent-color);
}

.landlord .rating-input > button > svg {
    color: var(--tertiary-accent-color);
}