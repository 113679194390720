div#search-filter-modal div#filter-tab-selector {
    display: flex;
    gap: 1em;
}

div#search-filter-modal div.filter-tab {
    padding: 15px;
    display: grid;
    gap: 15px;
}

div#search-filter-modal .filter-tab {
    display: grid;
    gap: 2em;
}

div#search-filter-modal div.filter-modal-row {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 15px;
}

div#search-filter-modal div.filter-modal-row div.form-group > label {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: gray;
}

div#search-filter-modal div.form-group > input[type=checkbox] + label::after {
    left: 2px;
    top: 0;
}

div#search-filter-modal span.MuiSlider-root {
    margin-top: 30px;
    width: 80%;
}

div#search-filter-modal div.form-group {
    display: grid;
    gap: .5em;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 991px) {

    #search-filter-modal #filter-slider-row {
        display: flex;
        flex-direction: column;
    }

    #search-filter-modal #filter-slider-row > div.form-group {
        width: 100%;
        justify-items: center;
    }

}

@media only screen and (max-width: 500px) {

    div#search-filter-modal div.filter-modal-row:last-child {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;
    }

}

@media only screen and (max-width: 400px) {

    div#search-filter-modal div.filter-modal-row {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
    }

}