:root {
    --din-a4-width: 21;
    --browser-page-width: 793px;
    --din-a4-height: 29.7;
    --din-a4-ratio: calc(var(--din-a4-height) / var(--din-a4-width));

    --browser-page-height: calc(var(--browser-page-width) * var(--din-a4-ratio));

    --px-cm-ratio: calc(var(--browser-page-width) / var(--din-a4-width));

    --din-a4-margin-h: 1.5;
    --browser-page-margin-h: calc(var(--din-a4-margin-h) * var(--px-cm-ratio));

    --din-a4-margin-v: 3;
    --browser-page-margin-v: calc(var(--din-a4-margin-v) * var(--px-cm-ratio));

    --footer-image-height: calc(var(--din-a4-width) / 4.666);
    --browser-footer-image-height: calc(var(--footer-image-height) * var(--px-cm-ratio));

    --logo-width: 3;
    --browser-page-logo-width: calc(var(--logo-width) * var(--px-cm-ratio));
    --logo-height: .975;
    --browser-page-logo-height: calc(var(--logo-height) * var(--px-cm-ratio));
    --logo-rel-pos-x: 1.5;
    --logo-rel-pos-y: 1;
    --browser-page-logo-rel-pos-x: calc(var(--logo-rel-pos-x) * var(--px-cm-ratio));
    --browser-page-logo-rel-pos-y: calc(var(--logo-rel-pos-y) * var(--px-cm-ratio));

    --xxs-font-size-browser: calc(var(--browser-page-width) * 0.008); /*#6px*/
    --xs-font-size-browser: calc(var(--browser-page-width) * 0.01); /*8px*/
    --sm-font-size-browser: calc(var(--browser-page-width) * 0.013);/*10px;*/
    --normal-font-size-browser: calc(var(--browser-page-width) * 0.016); /*12px;*/
    --lg-font-size-browser: calc(var(--browser-page-width) * 0.018); /*14px;*/
    --xl-font-size-browser: calc(var(--browser-page-width) * 0.021); /*16px;*/
}

.print-overview-modal .modal-body {
    background-color: #e2e2e2;
    display: flex;
    justify-content: center;
}

.print-overview-modal .modal-body > div {
    box-shadow: var(--container-shadow-light);
    width: max-content;
    height: max-content;
}

.print-overview-modal .modal-header,
.print-overview-modal .modal-footer {
    z-index: 1;
    box-shadow: var(--container-shadow);
}

div.page * {
    font-size: var(--normal-font-size-browser);
    outline: none;
}

div.page {
    position: relative;
    width: var(--browser-page-width);
    height: var(--browser-page-height);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    font-size: var(--normal-font-size-browser);
}

div.page > .logo {
    background-repeat: no-repeat;
    width: var(--browser-page-logo-width);
    height: var(--browser-page-logo-height);
    position: absolute;
    top: var(--browser-page-logo-rel-pos-y);
    right: var(--browser-page-logo-rel-pos-x);
}

div.page > .footer {
    background-repeat: no-repeat;
    width: var(--browser-page-width);
    height: var(--browser-footer-image-height);
    position: absolute;
    bottom: 0;
    left: 0;
}

div.page > div.todo-body {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: calc(var(--browser-page-width) - 2 * var(--browser-page-margin-h));
    height: calc(var(--browser-page-height) - 2 * var(--browser-page-margin-v));
    margin: 0 var(--browser-page-margin-h) var(--browser-page-margin-h) var(--browser-page-margin-h);
    gap: 20px;
}

div.page > div.invoice-body {
    position: relative;
    padding: calc(.5 * var(--px-cm-ratio)) 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: calc(var(--browser-page-width) - 2 * var(--browser-page-margin-h));
    height: calc(var(--browser-page-height) - 2 * var(--browser-page-margin-v));
    margin: var(--browser-page-margin-v) var(--browser-page-margin-h);
}

div.page > div.invoice-body > div.invoice-header {
    display: flex;
}

div.page > div.invoice-body div.invoice-header-left-col p {
    margin-bottom: 5px;
    line-height: 12px;
}

div.page > div.invoice-body div.invoice-header-left-col > div.roomjack-address,
div.page > div.invoice-body div.invoice-header-left-col > div.landlord-data {
    margin-bottom: var(--px-cm-ratio);
}

div.page > div.invoice-body div.invoice-header-left-col > div.customer-data > p,
div.page > div.invoice-body div.invoice-header-left-col > div.booker-data > p {
    font-size: var(--lg-font-size-browser) !important;
}

div.page > div.invoice-body div.invoice-header-right-col {
    margin-left: auto;
}

div.page > div.invoice-body div.invoice-header-right-col td:first-child {
    padding-right: calc(.5 * var(--px-cm-ratio));
}

div.page > div.invoice-body div.invoice-header-right-col tr.advert-id > td {
    padding-top: calc(.5 * var(--px-cm-ratio));
}

div.page > div.invoice-body > div.invoice-content {
    padding-top: var(--px-cm-ratio);
}

div.page > div.invoice-body > div.invoice-content > div.invoice-title {
    font-size: var(--xl-font-size-browser) !important;
    margin-bottom: var(--px-cm-ratio);
}

.data-table-container {
    border: 1px solid lightgray;
    border-radius: 16px;
    padding: 10px;
    display: grid;
    gap: 10px;
}

table.invoice-detail-table {
    width: 100%;
}

table.invoice-detail-table tr.bottom-padding > * {
    padding-bottom: calc(.5 * var(--px-cm-ratio));
}

table.invoice-detail-table tr.table-sum {
    border-top: 1px solid;
}

div.page:not(.roomjack-invoice) table.invoice-detail-table tr.table-sum td {
    padding-bottom: 0;
    border-bottom: 3px double;
}

table.invoice-detail-table tr.table-sum td {
    padding-top: calc(.5 * var(--px-cm-ratio));
}

table.invoice-detail-table th,
table.invoice-detail-table tr.invoice-amount td {
    font-family: open-sans-bold, sans-serif;
}

table.invoice-detail-table th {
    padding: 0 5px;
    white-space: nowrap;
}

table.invoice-detail-table td {
    vertical-align: top;
    padding: 0 5px;
}

table.invoice-detail-table td:last-child,
table.invoice-detail-table th:last-child{
    text-align: right;
}

div.page:not(.roomjack-invoice) table.invoice-detail-table td:nth-child(5) {
    text-align: right;
    padding-right: .5em;
}

div.page:not(.roomjack-invoice) table.invoice-detail-table td:nth-child(4),
div.page:not(.roomjack-invoice) table.invoice-detail-table td:nth-child(6){
    text-align: center;
}

div.page > div.invoice-body > div.roomjack-fee-information {
    margin-top: auto;
    text-align: center;
    color: gray;
    padding-bottom: 1cm;
    font-size: var(--xs-font-size-browser) !important;
}

div.page > div.invoice-body > div.invoice-greeting {
    margin-top: auto;
    text-align: center;
    color: gray;
    padding-bottom: var(--px-cm-ratio);
    font-size: var(--sm-font-size-browser) !important;
}

div.page > div#todo-title {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--tertiary-accent-color);
    display: flex;
    align-items: center;
    font-size: var(--lg-font-size-browser);
    height: var(--browser-page-margin-v);
    padding-left: var(--browser-page-margin-h);
}

div.page > div.invoice-body table,
div.page > div.todo-body table {
    width: 100%;
}

div.page > div.invoice-body table th,
div.page > div.todo-body table th {
    white-space: nowrap;
}

div.page > div.todo-body table.todo-table td,
div.page > div.todo-body table.todo-table th {
    border: 1px solid;
    text-align: center;
    padding: 0 5px;
}