/*######################################################################################################################
                                            DESKTOP PAGE STYLING
######################################################################################################################*/
#my-desktop {
    min-height: calc(100vh - var(--main-banner-height));
}

div#my-desktop > div.subpage-side-menu {
    width: var(--side-menu-width);
}

#jack-board {
    width: calc(100% - var(--side-menu-width));
    float: left;
    min-height: calc(100vh - var(--main-banner-height));
}

#jack-board > div {
    min-height: calc(100vh - var(--main-banner-height));
    padding: 25px;
}

.subpage-side-menu.collapsed + #jack-board {
    width: calc(100% - var(--side-menu-width-collapsed));
}

#jack-board > div > h1 {
    color: var(--main-accent-color);
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

.landlord #jack-board > div > h1 {
    color: var(--tertiary-accent-color);
}

.second-filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

/*######################################################################################################################
                                                   Dashboard
######################################################################################################################*/
#my-dashboard {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#dashboard {
    height: calc(100vh - var(--main-banner-height) - 75px - 42px);
    position: relative;
}

#dashboard-toolbar {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: right;
    top: -60px;
    gap: 10px;
}

#dashboard-toolbar > button {
    position: relative;
}

#layout-selector.selected {
    background-color: var(--main-accent-color);
    color: white;
}

#layout-selector.selected > svg {
    color: white;
}

.landlord #layout-selector.selected {
    background-color: var(--tertiary-accent-color);
}

#layout-type-container {
    position: absolute;
    top: 16px;
    border-radius: 0 0 16px 16px;
    border-color: var(--main-accent-color);
    border-style: solid;
    border-width: 0 1px 1px 1px;
    height: 60px;
    width: calc(100% + 2px);
    background: linear-gradient(to bottom, transparent, transparent 17px, white 18px) ;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 5px;
    gap: 5px;
    z-index: 1;
}

.layout-type {
    display: grid;
    gap: 2px;
    grid-template-columns: 10px 10px;
    grid-template-rows: 10px 10px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.layout-type.active {
    background-color: var(--main-accent-color);
}

.landlord .layout-type.active {
    background-color: var(--tertiary-accent-color);
}

.layout-type-cell {
    background-color: var(--main-accent-color);
    border-radius: 2px;
}

.layout-type-cell.tl {
    grid-row: 1;
    grid-column: 1;
}

.layout-type-cell.tr {
    grid-row: 1;
    grid-column: 2;
}

.layout-type-cell.bl {
    grid-row: 2;
    grid-column: 1;
}

.layout-type-cell.br {
    grid-row: 2;
    grid-column: 2;
}

.layout-type-cell.w {
    grid-column: 1 / span 2;
}

.landlord div.layout-type-cell {
    background-color: var(--tertiary-accent-color);
}

.layout-type.active > .layout-type-cell {
    background-color: white;
}

.landlord #layout-type-container {
    border-color: var(--tertiary-accent-color);
}

#dashboard-body {
    height: 100%;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

#dashboard-body .roomjack-container {
    padding: 0;
}

#dashboard-body .roomjack-container.tl {
    grid-row: 1;
    grid-column: 1;
}

#dashboard-body .roomjack-container.tr {
    grid-row: 1;
    grid-column: 2;
}

#dashboard-body .roomjack-container.bl {
    grid-row: 2;
    grid-column: 1;
}

#dashboard-body .roomjack-container.br {
    grid-row: 2;
    grid-column: 2;
}

#dashboard-body .roomjack-container.w {
    grid-column: 1 / span 2;
}

#dashboard-body .roomjack-container.editable.edit {
    animation: tilt-shaking .4s infinite;
    transform-origin: right;
}

#dashboard-body .roomjack-container.editable {
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 5px;
}

#dashboard-body .roomjack-container.editable .widget-adder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

#dashboard-body .roomjack-container.editable .widget-adder svg {
    font-size: 40px;
    color: var(--main-accent-color);
}

.landlord #dashboard-body .roomjack-container.editable .widget-adder svg {
    color: var(--tertiary-accent-color);
}

#dashboard-body .roomjack-container.editable:hover .widget-adder svg {
    font-size: 50px;
}

#dashboard-body .roomjack-container.editable .widget-adder > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: 12px;
}


@keyframes tilt-shaking {
    0% { transform: rotate(0deg) translateY(0); }
    25% { transform: rotate(.3deg) translateY(2px); }
    50% { transform: rotate(0deg) translateY(0px); }
    75% { transform: rotate(-.3deg) translateY(-2px); }
    100% { transform: rotate(0deg) translateY(0px); }
}

/*********************************************** widget selector modal ************************************************/
#widget-selector-modal div.modal-dialog {
    max-width: 750px;
}

#widget-selector-modal .modal-body {
    display: grid;
    gap: 20px;
}

#widget-selector-modal div.modal-body > div#widget-selector-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 200px);
    justify-content: center;
}

#widget-selector-modal div.modal-body > div#widget-selector-grid > button.widget-button {
    width: 200px;
    text-align: left;
}

#widget-selector-modal div.modal-body > div#widget-selector-grid > button.widget-button > svg {
    color: var(--secondary-accent-color);
}

#widget-selector-modal div.modal-body > div#widget-selector-grid > button.widget-button:disabled {
    background-color: var(--main-accent-color);
}

.landlord #widget-selector-modal div.modal-body > div#widget-selector-grid > button.widget-button:disabled {
    background-color: var(--tertiary-accent-color);
}

#widget-selector-modal div.modal-body > div#widget-selector-grid > button.widget-button:disabled > span {
    color: white;
}

/*######################################################################################################################
                                                    Adverts
######################################################################################################################*/
#advert-tool {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

.advert-inspector {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr;
}

.advert-inspector > .roomjack-container {
    height: max-content;
}

.advert-inspector > .roomjack-container > .roomjack-container-header {
    color: black;
}

.advert-inspector > .roomjack-container > .data-container {
    display: grid;
    gap: 20px;
    height: max-content;
}

.advert-inspector > .unpublished-adverts > .data-container .housing-overview {
    filter: grayscale(100);
}

.booster-modal-width {
    width: 600px;
    max-width: calc(100% - 40px);
    margin: auto;
}

.booster-modal .desktop-submenu-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.booster-container {
    width: 170px;
}

.booster-tile {
    height: 290px;
    background-color: var(--tertiary-accent-color-light-transparent);
    border-radius: 20px;
    padding: 5px;
    display: grid;
    gap: 20px;
    grid-template-rows: 1fr 3fr .8fr;
}

.booster-tile.disabled,
.booster-tile.active {
    background-color: var(--tertiary-accent-color);
    color: white;
}

.booster-tile:not(.disabled):hover {
    background-color: var(--tertiary-accent-color-medium);
    color: white;
    cursor: pointer;
}

.booster-tile > .roomjack-headline {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
    align-self: center;
}

.booster-tile > .booster-description {
    color: gray;
    height: calc(100% - 100px);
    font-size: 11px;
    text-align: center;
}

.booster-tile:not(.disabled):hover > .booster-description,
.booster-tile.disabled > .booster-description,
.booster-tile.active > .booster-description {
    color: var(--secondary-accent-color);
}

.booster-tile > div.booster-costs {
    border-top: 1px solid gray;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: urbane-rounded, sans-serif;
}

.booster-tile.disabled > .booster-costs,
.booster-tile.active > .booster-costs {
    border-top: 1px solid var(--secondary-accent-color);
    color: white;
}

.booster-tile.active > .booster-costs {
    border-top: 1px solid var(--secondary-accent-color);
    color: white;
}

.booster-tile:not(.disabled):hover > .booster-costs {
    border-top: 1px solid var(--secondary-accent-color);
    color: white;
}

.booster-tile > .booster-costs > svg {
    color: green;
    margin-right: 5px;
}

#advert-editor .screen-size-invalid {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    padding: 20px
}

#advert-editor .screen-size-valid {
    gap: 25px;
}

#advert-editor .screen-size-valid .editor-tab {
    display: grid;
    gap: 25px;
}

#advert-editor .screen-size-valid .editor-tab.disabled {
    pointer-events: none;
}

#advert-editor .screen-size-valid .editor-tab > .roomjack-container {
    width: 50%;
    min-width: 700px;
}

#advert-editor .screen-size-invalid > img {
    width: 20vw;
    max-width: 100px;
}

#advert-editor .screen-size-invalid > div {
    max-width: 500px;
}

#advert-editor .screen-size-invalid > div.roomjack-headline {
    font-size: 13px;
    text-align: center;
}

#advert-editor .screen-size-invalid > div.description-container {
    text-align: justify;
}

.advert-editor-modal-table {
    width: 100%;
}

.advert-editor-modal-table td:first-child {
    padding: 5px 10px 5px 0;
    color: gray;
    font-size: var(--small-text);
}

.advert-editor-modal-table td:first-child > svg {
    margin-left: 3px;
    color: var(--tertiary-accent-color);
}

.advert-editor-modal-table td:last-child {
    padding: 5px 0 5px 10px;
}

.advert-editor-modal-table td:last-child > * {
    margin-left: auto;
}

.advert-editor-modal-table td:last-child > input {
    display: block;
}

/*################################################  custom tab control  ##############################################*/
.tab-button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    border-bottom: 1px solid #e2e2e2;
}

.tab-button-container > button.tab-button {
    position: relative;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--light-gray-color);
    transition: .2s ease-in-out;
    padding-bottom: .5em;
    display: flex;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
}

.tab-button-container > .tab-button:disabled {
    color: lightgray;
}

.tab-button-container > .tab-button:not(.selected):not(:disabled):hover {
    color: gray;
}

.tab-button-container > .tab-button > svg {
    color: lightgray;
}

.tab-button-container > .tab-button.selected.invalid,
.tab-button-container > .tab-button.selected.completed,
.tab-button-container > .tab-button.selected.warning {
    opacity: 1;
}


.tab-button-container > .tab-button::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 0;
    background-color: var(--tertiary-accent-color);
    transition: .2s ease-in-out;
    bottom: -3px;
    border-radius: 3px;
}

.tab-button-container > .tab-button:disabled::after {
    color: lightgray;
}

.tab-button-container > .tab-button.selected {
    color: black;
}

.tab-button-container > .tab-button.selected:disabled {
    color: gray;
}

.tab-button-container > .tab-button.selected::after {
    width: 100%;
}

.tab-button-container > .tab-button > svg {
    position: absolute;
    opacity: .6;
    right: -15px;
    top: 5px;
}

.tab-button-container > .tab-button.completed > svg {
    color: forestgreen;
}

.tab-button-container > .tab-button.invalid > svg {
    color: red;
}

.tab-button-container > .tab-button.warning > svg {
    color: orange;
}

.tab-button-container > .tab-button:disabled > svg {
    color: lightgray;
}

/*############################################  AdvertEditorBlock Styling  ###########################################*/
.advert-editor-block {
    position: relative;
    display: grid;
    height: max-content;
    gap: 20px;
    outline: none;
    border: 3px solid transparent;
    transition: .2s ease-in-out;
    padding-bottom: 25px;
}

.advert-editor-block .description-container {
    text-align: justify;
}

.advert-editor-block > svg.fa-circle-info {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: var(--tertiary-accent-color);
}

.advert-editor-block > svg.fa-triangle-exclamation {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: red;
}

.advert-editor-block.warning {
    border-color: orange;
}

.advert-editor-block.warning > svg.fa-circle-exclamation {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: orange;
}

.advert-editor-block.invalid {
    border-color: red;
}

.advert-editor-block.invalid > svg.fa-warning {
    display: initial;
}

.advert-editor-block div.sub-apartment-container, .advert-editor-block div.sub-room-container {
    margin-top: 2em;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1em;
}

.advert-editor-block div.sub-unit-list:empty {
    display: none;
}

.advert-editor-block div.editor-block-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.advert-editor-table {
    width: 100%;
}

.advert-editor-table tr > td {
    height: 32px;
    padding: 4px 0;
}

.advert-editor-table tr > td:last-child > * {
    margin-left: auto;
}

.advert-editor-table tr > td:last-child > input {
    display: block;
}

.advert-editor-block .form-group.fixed-height {
    height: 40px;
    display: flex;
    align-items: center;
}

.advert-editor-block textarea#advert-title-input {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: black;
}

.editor-tab.location form {
    display: grid;
    gap: 20px;
}

#house-rules.advert-editor-block button.outline-button {
    width: 300px;
}

#house-rules.advert-editor-block .house-rule-selector > form,
#house-rules.advert-editor-block .house-rule-creator > form {
    background-color: #e8e8e8;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 15px;
    display: grid;
    gap: 5px;
}

#house-rules.advert-editor-block .house-rule-creator > form {
    grid-template-columns: 1fr max-content max-content;
}

#house-rules.advert-editor-block .house-rule-selector > form div.form-group {
    display: flex;
    height: 32px;
    align-items: center;
}

.items-selector-modal .modal-content {
    height: calc(100vh - 40px);
}

.items-selector-modal .modal-content .modal-body {
    display: flex;
    gap: 20px;
    flex-direction: column;
    overflow-y: hidden;
}

.items-selector-modal .modal-content .modal-body > input {
    height: 32px !important;
}

.pricing-modal div.modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 1rem;
}

.pricing-modal div.modal-body > div.pricing-model-container {
    display: grid;
    gap: 5px;
    padding-right: 8px;
    height: max-content;
}

.pricing-modal .pricing-tab {
    display: grid;
    gap: 5px;
}

.pricing-modal .pricing-tab .pricing-unit-container {
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
}

.pricing-modal .pricing-tab .pricing-unit-container.disabled {
    border: 1px solid lightgrey;
}

.pricing-modal .pricing-tab .pricing-unit-container .roomjack-headline {
    display: flex;
    align-items: center;
    width: 100%;
}

.pricing-modal .pricing-tab .pricing-unit-container.disabled .roomjack-headline {
    color: lightgrey;
}

.pricing-modal .pricing-tab .pricing-unit-container .roomjack-headline > svg.fa-circle-check {
    margin-left: 5px;
    color: forestgreen;
}

.pricing-modal .pricing-tab .pricing-unit-container .roomjack-headline > svg.fa-triangle-exclamation {
    margin-left: 5px;
    color: red;
}

.pricing-modal .pricing-tab .pricing-unit-container .roomjack-headline > .simple-icon-button {
    margin-left: auto
}

.pricing-modal .pricing-tab .pricing-unit-container .advert-editor-table .advert-editor-table-header {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 20px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.pricing-modal .pricing-tab .pricing-unit-container .advert-editor-table tr td:first-child > label > svg {
    margin-left: 3px;
}

.pricing-modal .pricing-tab .pricing-modal-grid {
    display: grid;
    gap: 8px;
}

.pricing-modal div.modal-body .pricing-modal-grid > button {
    margin-left: auto;
    width: max-content;
}

.pricing-modal div.modal-body .pricing-modal-grid > .fee-editor {
    gap: 5px;
}

.pricing-modal div.modal-body .pricing-modal-grid > .fee-editor:not(.edited) > input[name="name"] {
    border-radius: 2px;
    padding: 2px;
}

.pricing-modal div.modal-body .pricing-modal-grid > .fee-editor > .custom-dropdown,
.pricing-modal div.modal-body .pricing-unit-container > #daily-fee > .custom-dropdown,
.pricing-modal div.modal-body .pricing-unit-container > #monthly-fee > .custom-dropdown,
.pricing-modal div.modal-body .pricing-modal-grid > .fee-editor > .form-group {
    margin-left: auto;
}

.pricing-modal div.modal-body .pricing-modal-grid > .fee-editor > .simple-icon-button {
    width: 20px;
    height: 20px;
    padding: 0;
}

.pricing-modal div.modal-body > .pricing-example {
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-left: 1px solid lightgrey;
}

.pricing-modal div.modal-body > .pricing-example > div.pro-tool-tax-info {
    color: gray;
    font-style: italic;
    margin-bottom: 1em;
}

.pricing-modal div.modal-body > .pricing-example > div.pro-tool-tax-info > svg {
    color: var(--tertiary-accent-color);
}

.pricing-modal div.modal-body > .pricing-example > div.example-container {
    margin-bottom: 30px;
}

.pricing-modal div.modal-body > .pricing-example > div.example-container tr.win > td {
    color: gray;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

.pricing-modal div.modal-body > .pricing-example > div.example-container tr.win > td > a {
    margin-left: 5px;
}

#booking-length-slider {
    margin-top: 30px;
    width: 90%;
    justify-self: center;
}

/*######################################################################################################################
                                              Bookings, Current Price
######################################################################################################################*/
#my-bookings,
#my-bookings > .booking-tool,
#my-bookings > .booking-tool #booking-list-container,
#my-bookings,
#current-price-tool {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#my-bookings > .gantt-container,
#current-price-tool > .gantt-container {
}

/*######################################################################################################################
                                                    ToDo's
######################################################################################################################*/
#my-todos {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#my-todos > .horizontal-form-group {
    flex-wrap: wrap;
}

#my-todos > .horizontal-form-group > button {
    margin-left: auto;
}

#my-todos .roomjack-container > div.todo-data-container {
    overflow-x: auto;
    border-radius: 16px;
    border: 1px solid lightgrey;
}

#my-todos .roomjack-container > div.todo-data-container tr.done {
    text-decoration-line: line-through;
}

#my-todos table {
    width: 100%;
    padding: 0;
    overflow: clip;
}

#my-todos table th {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--small-text);
}

#my-todos table tr > * {
    text-align: left;
    white-space: nowrap;
    padding: 5px 10px;
    background-color: white;
}

#my-todos table tbody tr:not(:last-child) > *,
#my-todos table thead tr > * {
    border-bottom: 1px solid lightgrey !important;
}

#my-todos table tr > *:nth-child(odd) {
    background-color: #e2e2e2;
}

#my-todos table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
}

/*######################################################################################################################
                                                    Messages
######################################################################################################################*/
#my-chat {
    display: flex;
    gap: 25px;
    flex-direction: column;
    height: calc(100vh - var(--main-banner-height));
}

#chat-container {
    display: grid;
    grid-template-columns: 400px auto 270px;
    height: calc(100vh - var(--main-banner-height) - 67px);
    position: relative;
    gap: 20px;
    margin: -25px;
    padding: 25px;
}

.chat-sync-icon {
    margin-left: 5px;
}

#close-conversation {
    display: none;
    color: white;
    background-color: var(--main-accent-color);
    width: 32px;
    height: 32px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
}

.landlord #close-conversation {
    background-color: var(--tertiary-accent-color);
}

#conversation-previews {
    width: 440px;
    overflow-y: auto;
    max-height: calc(100vh - var(--main-banner-height) - 77px);
    gap: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: -20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#conversation-previews::-webkit-scrollbar {
    display: none;
}

div.conversation-preview {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px;
    cursor: pointer;
}

div.conversation-preview:not(:last-child) {
    border-bottom: 1px solid lightgrey;
}

div.conversation-preview > .conversation-status {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4px;
    left: 4px;
    width: 18px;
    height: 18px;
    color: dimgray;
    font-size: 12px;
    border-radius: 9px;
    transition: ease-in-out .2s;
    transform-origin: center;
}

div.conversation-preview > .conversation-status.loading {
    color: var(--main-accent-color);
}

.landlord div.conversation-preview > .conversation-status.loading {
    color: var(--tertiary-accent-color);
}

div.conversation-preview > .conversation-status.interactive {
    transform: rotate(-30deg);
}

div.conversation-preview > .conversation-status.interactive:hover {
    color: white;
    background-color: red;
}

div.conversation-preview.selected {
    color: white;
    background-color: var(--main-accent-color);
    transition: .2s ease-in-out;
}

.landlord div.conversation-preview.selected {
    background-color: var(--tertiary-accent-color);
}

div.conversation-preview:not(.selected):hover {
    background-color: var(--main-accent-color-light);
}

.landlord div.conversation-preview:not(.selected):hover {
    background-color: var(--tertiary-accent-color-light-transparent);
}

div.conversation-preview .avatar,
div.conversation-preview .avatar > .avatar-container {
    width: 64px !important;
    height: 64px  !important;
    border-radius: 32px;
    cursor: default;
    pointer-events: none;
    margin-right: 10px;
    background-repeat: no-repeat;
    position: relative;
}

div.conversation-preview .avatar.deleted > .avatar-container,
div.conversation-preview .avatar.pause > .avatar-container,
div.conversation-preview .avatar.blocked > .avatar-container {
    filter: grayscale(100%) blur(4px);
}

div.conversation-preview .avatar.deleted > svg,
div.conversation-preview .avatar.pause > svg,
div.conversation-preview .avatar.blocked > svg {
    width: 32px;
    height: 32px;
    position: absolute;
    font-size: 32px;
    color: black;
    left: 16px;
    top: 16px;
}

div.conversation-preview .avatar > div.message-type-icon {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    color: white;
    background-color: #D2D2D2;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    right: -4px;
}

div.conversation-preview .conversation-info-container {
    width: calc(100% - 74px);
}

div.conversation-preview .message-info-container {
    display: flex;
    align-items: center;
}

div.conversation-preview span.roomjack-headline {
    font-size: var(--small-text);
}

div.conversation-preview .message-info-container > .description-container {
    margin-left: auto;
    font-size: 11px;
    text-align: right;
    height: min-content;
}

div.conversation-preview.selected .message-info-container > .description-container {
    color: var(--secondary-accent-color)
}

div.conversation-preview div.conversation-preview-container {
    display: flex;
    align-items: center;
}

div.conversation-preview .conversation-info-container > .description-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 11px;
    white-space: nowrap;
    width: 100%;
    margin: 8px 0;
}

div.conversation-preview .conversation-info-container > .description-container > span > svg {
    margin-right: 2px;
}

div.conversation-preview.selected .conversation-info-container > .description-container {
    color: var(--secondary-accent-color) !important;
}

div.conversation-preview div.conversation-preview-control {
    position: relative;
    height: 1em;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

div.conversation-preview div.conversation-preview-control > button {
    display: none;
    font-size: .8em;
    outline: none;
    border: none;
    background-color: transparent;
}

div.conversation-preview.selected div.conversation-preview-control > button {
    color: var(--secondary-accent-color) !important;
}

div.conversation-preview:hover div.conversation-preview-control > button {
    display: initial;
}

div.conversation-preview div.conversation-preview-control > div.conversation-preview-menu {
    position: absolute;
    right: 0;
    top: 25px;
    z-index: 1;
    box-shadow: var(--container-shadow);
    width: max-content;
    border-radius: 16px;
    overflow: hidden;
    display: grid;
}

div.conversation-preview div.conversation-preview-control > div.conversation-preview-menu > * {
    padding: 8px;
    background-color: white;
    text-align: left;
}

div.conversation-preview  div.conversation-preview-control > div.conversation-preview-menu > .pin-control:not(.pinned) > svg {
    transform: rotate(-30deg);
}

div.conversation-preview  div.conversation-preview-control > div.conversation-preview-menu > .pin-control.pinned > svg {
    transform: rotate(30deg);
}

div.conversation-preview  div.conversation-preview-control > div.conversation-preview-menu > :not(:last-child) {
    border-bottom: #ccc 1px solid;
}

div.conversation-preview  div.conversation-preview-control > div.conversation-preview-menu svg {
    color: gray;
    margin-right: 6px;
    font-size: 11px;
    transition: color ease-in-out .1s;
}

div.conversation-preview div.conversation-preview-control > div.conversation-preview-menu > .pin-control.pinned:hover > svg {
    color: var(--main-accent-color);
}

div.conversation-preview  div.conversation-preview-control > div.conversation-preview-menu > .pin-control:not(.pinned):hover > svg {
    color: var(--main-accent-color);
}

div.conversation-preview div.conversation-preview-control > div.conversation-preview-menu > .delete:hover > svg {
    color: #cd5c5cdd;
}

div.conversation-preview div.conversation-preview-control > div.conversation-preview-menu > .report:hover > svg {
    color: #daa520dd;
}

div.conversation-preview div.conversation-preview-control > div.conversation-preview-menu > button > span {
    color: gray !important;
    font-size: 11px;
    font-family: urbane-rounded-light, sans-serif;
    text-transform: uppercase;
}

div.conversation-preview > div.marker {
    position: absolute;
    right: 1.2em;
    top: 3em;
}

#conversation-surface-container {
    max-height: calc(100vh - var(--main-banner-height) - 117px);
    overflow: clip;
    border-radius: 16px;
}

#conversation-surface-container.shadowy {
    box-shadow: var(--container-shadow);
}

#conversation-surface-container.expanded {
    grid-column: 2/4;
}

#conversation-surface {
    display: flex;
    flex-direction: column;
    min-height: 0;
    position: relative;
    height: 100%;
}

#conversation-surface > .overlay {
    position: absolute;
    z-index: 6;
    background: linear-gradient(to bottom, white, white 20px, transparent 40px);
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#conversation-surface > .overlay.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #aaa, #aaa 20px, #6668 40px, #6668 100%);
}

#conversation-surface > .overlay.loading > svg {
    font-size: 50px;
}

#conversation-surface #message-surface {
    flex: 1;
    position: relative;
    padding: 30px 15px 15px 15px;
}

#conversation-surface #message-surface .blur {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(to bottom, red, transparent 25px);
}

#conversation-surface #control-bar {
    min-height: 72px;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    gap: 5px;
    position: relative;
}

#conversation-surface #control-bar > form {
    flex: 1;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

#conversation-surface #control-bar > button > svg,
#conversation-surface #control-bar > form > button > svg{
    color: gray;
    font-size: 20px;
}

#conversation-surface #control-bar > form > textarea {
    resize: none;
    border-radius: 16px;
    background-color: white !important;
}

#conversation-surface #control-bar > .emoji-container {
    display: none;
    position: absolute;
    width: 350px;
    height: 450px;
    background-color: white;
    box-shadow: var(--container-shadow-light);
    z-index: 5;
    left: 5px;
    top: -455px;
    border-radius: 16px;
}

#conversation-surface #control-bar > .emoji-container.show {
    display: block;
}

#conversation-surface > #message-surface > .description-container {
    background-color: #e2e2e2;
    border-radius: 8px;
    width: max-content;
    padding: 5px;
    align-self: center;
    max-width: 500px;
    text-align: justify;
}

#message-surface {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

#message-surface > div.loading {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 200%;
}

#message-surface > div.loading > svg {
    color: gray;
}

#message-surface > div#message-surface-placeholder {
    color: gray;
    font-size: 11px;
    text-align: center;
    font-weight: bold;
}

#message-surface div.info-label {
    background-color: var(--light-gray-color);
    width: max-content;
    padding: 5px 10px;
    border-radius: 8px;
    align-self: center;
    height: max-content;
}

#message-surface div.chat-day.info-label {
    font-size: 11px;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

div.message-container {
    height: max-content;
    text-align: left;
    max-width: 100%;
    display: flex;
    gap: 10px;
}

div.message-container.own {
    flex-direction: row-reverse;
    text-align: left;
}

div.message-container > div.message-background {
    position: relative;
    height: max-content;
    float: left;
    padding: 10px;
    border-radius: 16px;
    border: 2px solid var(--light-gray-color);
    max-width: 60%;
    display: grid;
    gap: 5px;
    min-width: 100px;
}

div.message-container > .message-content-editor {
    color: var(--main-accent-color);
    font-size: var(--button-small-font);
    display: flex;
    align-items: center;
    gap: 5px;
}

div.message-container > .message-content-editor > span {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

.landlord div.message-container > .message-content-editor {
    color: var(--tertiary-accent-color);
}

div.message-container > div.message-background.selected {
    border-color: var(--main-accent-color);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--main-accent-color-medium);
    }

    70% {
        box-shadow: 0 0 0 10px var(--main-accent-color-medium);
    }

    100% {
        box-shadow: 0 0 0 0 var(--main-accent-color-light);
    }
}

.landlord div.message-container > div.message-background.selected {
    border-color: var(--tertiary-accent-color);
    animation: pulse-landlord 2s infinite;
}

@keyframes pulse-landlord {
    0% {
        box-shadow: 0 0 0 0 var(--tertiary-accent-color);
    }

    70% {
        box-shadow: 0 0 0 10px var(--tertiary-accent-color-medium);
    }

    100% {
        box-shadow: 0 0 0 0 var(--tertiary-accent-color-medium);
    }
}

div.message-container > div.message-background.interactive {
    border-color: var(--main-accent-color);
    cursor: pointer;
}

.landlord div.message-container > div.message-background.interactive {
    border-color: var(--tertiary-accent-color);
}

div.message-container > div.message-background > div.message-name-label {
    font-size: .8em;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

div.message-container > div.message-background > div.message-name-label > span {
    color: var(--light-gray-color);
    text-transform: initial;
    margin-left: .5em;
}

div.message-container > div.message-background > div.message-text-container {
    text-align: left;
    word-wrap: anywhere;
    overflow-wrap: anywhere;
    word-break: break-word;
}

div.message-container > div.message-background > div.message-timestamp {
    font-size: .8em;
    color: var(--light-gray-color);
}

div.message-container.own > div.message-background > div.message-text-container {
    text-align: left;
}

div.message-container > div.message-background > img.image-container {
    width: 100%;
}

div.message-container div.marker {
    position: absolute;
    right: -5px;
    bottom: -5px;
    font-size: .5em;
}

div.message-container div.message-metadata-container {
    margin: 5px 0;
    border-radius: 16px;
    font-size: 11px;
    text-align: left;
    color: var(--light-gray-color);
}

div.message-container div.message-metadata-container > svg {
    margin-right: 3px;
}

div.message-container div.message-metadata-container > button {
    margin-top: 15px;
    width: 100%;
}

#booking-overview {
    width: 310px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
    margin: -20px;
    overflow-y: auto;
    max-height: calc(100vh - var(--main-banner-height) - 77px);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#booking-overview::-webkit-scrollbar {
    display: none;
}

#show-overview {
    position: absolute;
    background-color: var(--main-accent-color);
    color: white;
    padding: 8px;
    border-radius: 16px 0 0 16px;
    box-shadow: var(--container-shadow);
    z-index: 7;
    display: flex;
    gap: 5px;
    align-items: center;
    height: 250px;
    right: 25px;
    top: calc((100vh - var(--main-banner-height) - 67px) / 2 - 125px);
    width: 50px;
}

#show-overview > svg {
    font-size: 16px;
}

#show-overview > span {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    writing-mode: vertical-lr;
    letter-spacing: 2px;
}

.landlord #show-overview {
    background-color: var(--tertiary-accent-color);
}

#booking-overview::-webkit-scrollbar {
    display: none;
}

#booking-overview button#close-overview {
    position: absolute;
    right: 32px;
    top: 32px;
    z-index: 7;
    background-color: var(--main-accent-color);
    width: 30px;
    height: 30px;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: var(--container-shadow-light);
    display: none;
}

.landlord #booking-overview button#close-overview {
    background-color: var(--tertiary-accent-color);
}

#booking-overview .image-slider {
    width: 240px;
    height: 160px;
}

#booking-overview .booking-checkout-box {
    border-radius: 16px;
    background-color: var(--main-accent-color);
    display: grid;
    gap: 8px;
}

.landlord #booking-overview .booking-checkout-box {
    background-color: var(--tertiary-accent-color);
}

#booking-overview .roomjack-headline:not(.advert-title) {
    font-size: 11px;
}

#booking-overview .booking-checkout-box > div > .description-container {
    color: white;
    padding-left: 5px;
}

#booking-overview .booking-details {
    display: grid;
    gap: 5px;
}

#booking-overview .booking-details > .accent-icon-button {
    justify-self: center;
}
/*######################################################################################################################
                                                    Finances
######################################################################################################################*/
#my-finances,
#my-finances > #payout-history-tool,
#my-finances > #invoices-tool,
#my-finances > #unpaid-rents-tool {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#my-finances > div > .roomjack-container {
    width: 500px;
    max-width: 100%;
}

#invoices-tool .loading > .roomjack-headline {
    text-align: center;
}

#invoice-container {
    padding: 0 !important;
    width: 100% !important;
}

#invoice-table th,
#invoice-table td {
    font-size: var(--small-text);
    text-align: center !important;
}

#invoice-table {
    width: 100%;
    padding: 0;
    overflow: clip;
    border-radius: 15px;
}

#invoice-table tr > * {
    white-space: nowrap;
    padding: 5px 10px;
    background-color: white;
}

#invoice-table tbody tr:not(:last-child) > *,
#invoice-table thead tr > * {
    border-bottom: 1px solid lightgrey !important;
}

#invoice-table tr > *:nth-child(odd) {
    background-color: #e2e2e2;
}

#invoice-table tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
}

#my-finances > #payout-history-tool > .roomjack-container,
#my-finances > #invoices-tool > .roomjack-container {
    display: grid;
    gap: 25px;
    max-height: 800px;
    height: max-content;
    overflow-y: auto;
}

div.transaction-box {
    width: 100%;
    border: 1px solid gray;
    border-radius: 1em;
    padding: 8px;
}

div.transaction-box > div.transaction-header {
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
}

div.transaction-box table {
    width: 100%;
    color: gray;
    font-size: var(--small-text);
}

div.transaction-box table > tbody > tr > td:first-child {
    font-family: open-sans-bold, sans-serif;
}

div.transaction-box table > tbody > tr > td:last-child {
    text-align: right;
}

/*######################################################################################################################
                                                    Statistics
######################################################################################################################*/
#my-statistics {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#my-statistics div.statistic-container {
    display: grid;
    gap: 25px;
}

div.statistic-title {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

#my-statistics div.statistic-container > div.content-container {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;
}

#my-statistics #occupancy-rate-overview div.roomjack-container,
#my-statistics #cancellation-rate-overview div.roomjack-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: max-content;
}

#my-statistics #occupancy-rate-overview div.roomjack-container div.doughnut-center-label > div:first-child,
#my-statistics #cancellation-rate-overview div.roomjack-container div.doughnut-center-label > div:first-child,
#my-statistics #occupancy-rate-overview div.roomjack-container div.doughnut-center-label > div:last-child,
#my-statistics #cancellation-rate-overview div.roomjack-container div.doughnut-center-label > div:last-child {
    font-size: 25px;
}

#my-statistics #cancellation-rate-overview div.roomjack-container div.doughnut-center-label > div:first-child {
    color: var(--secondary-accent-color);
}

#my-statistics #income-overview .content-container,
#my-statistics #length-of-stay-overview .content-container {
    display: grid;
    gap: 25px;
    max-width: 100%;
}

#my-statistics #income-overview .content-container canvas,
#my-statistics #length-of-stay-overview .content-container canvas {
    max-width: 100%;
}

#my-statistics #income-overview .content-container {
    grid-template-columns: repeat(2, 500px);
}

#my-statistics #income-overview div.statistic-data-container,
#my-statistics #income-overview div.statistic-overview-container {
    height: 100%;
}

#my-statistics #income-overview div.statistic-overview-container > div.roomjack-container {
    display: grid;
    height: 100%;
    justify-items: center;
}

#my-statistics #income-overview div.statistic-data-container > div.roomjack-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

#my-statistics #income-overview div.statistic-data-container > div.roomjack-container > div#no-income-placeholder {
    color: gray;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    text-align: center;
    width: 100%;
}

#my-statistics #income-overview div#income-detail-container table {
    width: 100%;
    margin-top: auto;
}

#my-statistics #income-overview div#income-detail-container table tr:not(:last-child) > td {
    padding-bottom: 10px;
}

#my-statistics #income-overview div#income-detail-container table tr:last-child {
    border-top: 1px solid var(--light-gray-color);
}

#my-statistics #income-overview div#income-detail-container table tr:last-child > td {
    padding-top: 10px;
}

#my-statistics #income-overview div#income-detail-container table tr > td {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

#my-statistics #income-overview div#income-detail-container table tr > td:first-child {
    text-align: left;
}

#my-statistics #income-overview div#income-detail-container table tr > td:last-child {
    text-align: right;
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(1) {
    color: var(--statistics-yellow);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(2) {
    color: var(--main-accent-color);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(3) {
    color: var(--accept-color);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(4) {
    color: var(--statistics-brown);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(5) {
    color: var(--secondary-accent-color);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(6) {
    color: var(--twitter-color);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(7) {
    color: var(--quaternary-accent-color);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(8) {
    color: var(--color-boost);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(9) {
    color: var(--facebook-color);
}

#my-statistics #income-overview div#income-detail-container table tr:nth-child(10) {
    color: var(--main-gray-color);
}

#my-statistics #length-of-stay-overview {
    width: max-content;
    max-width: 100%;
}

#my-statistics #length-of-stay-overview div.statistic-data-container {
    align-self: start;
    display: grid;
}

#my-statistics #length-of-stay-overview div.statistic-data-container > div.roomjack-container {
    width: 500px;
    height: 350px;
}

#my-statistics #length-of-stay-overview .statistic-overview-container {
    display: grid;
    gap: 25px;
    grid-column: 2;
}

div.statistic-display {
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--container-shadow);
    max-width: 270px;
    width: 270px;
    min-width: 200px;
    text-align: center;
}

div.statistic-display > div.statistic-display-headline {
    height: 30px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

div.statistic-display > :not(.statistic-display-headline) {
    height: 80px;
}

div.statistic-display > div.statistic-display-value {
    font-size: 2em;
    color: var(--tertiary-accent-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: urbane-rounded, sans-serif;
}

div.statistic-display > div.statistic-display-value > div.statistic-value {
    font-family: urbane-rounded, sans-serif;
    line-height: 1em;
    margin-top: .5em;
    margin-bottom: .1em;
}

div.statistic-display > div.statistic-display-value > div.statistic-unit {
    font-family: urbane-rounded, sans-serif;
    color: black;
    font-size: var(--small-text);
    line-height: 1em;
    text-transform: uppercase;
}

div#average-stay-length > div.statistic-display-value > div.statistic-value {
    color: var(--statistics-yellow);
}

div#min-stay-length > div.statistic-display-value > div.statistic-value {
    color: var(--main-accent-color) !important;
}

div#max-stay-length > div.statistic-display-value > div.statistic-value {
    color: var(--secondary-accent-color);
}

/*######################################################################################################################
                                                    Reviews
######################################################################################################################*/
#my-reviews {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#my-reviews .desktop-submenu-tile > .tile-icon-container svg.fa-star {
    position: absolute;
    font-size: 14px;
    right: 25%;
    top: 10px;
}

#my-reviews .review-main-container {
    display: grid;
    grid-template-columns: 4fr 7fr;
    gap: 25px;
    max-width: 990px;
}

#my-reviews .review-main-container > .roomjack-grid-container {
    grid-column: 1;
    height: max-content;
}

#my-reviews .review-main-container > #review-scroller {
    grid-column: 2;
    padding: 10px;
    height: max-content;
    max-height: 800px;
    overflow-y: auto;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

#my-reviews  #review-scroller > div.review {
    display: grid;
    border: 1px solid var(--light-gray-color);
    border-radius: 12px;
    padding: 10px;
    gap: 25px;
    margin-bottom: 0;
}

#my-reviews  #review-scroller > div.review > div.review-header {
    position: relative;
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: var(--small-text);
}

#my-reviews  #review-scroller > div.review > div.review-header > div.review-author-name {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

#my-reviews  #review-scroller > div.review > div.review-header > div.review-timestamp {
    color: var(--light-gray-color);
    position: absolute;
    right: 0;
    font-family: open-sans-italic, sans-serif;
}

#my-reviews  #review-scroller > div.review > div.review-body {
    display: grid;
    gap: .5em;
}

#my-reviews  #review-scroller > div.review > div.review-body > div.rating-type-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 5px;
}

#my-reviews  #review-scroller > div.review > div.review-body > div.rating-type-container > div.rating {
    display: flex;

}

#my-reviews  #review-scroller > div.review > div.review-body > div.rating-type-container > div.rating > div.rating-name {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--small-text);
    width: 190px;
}

#my-reviews  #review-scroller > div.review > div.review-body > div.rating-type-container > div.rating > div.rating-value {
    color: var(--statistics-yellow);
    font-size: var(--small-text);
}

#my-reviews  #review-scroller > div.review > div.review-body > div.review-description-container {
    color: gray;
    font-size: var(--small-text);
    margin: 1em 0;
}

#my-reviews  #review-scroller > div.review > div.review-body > button {
    font-size: var(--small-text);
    color: var(--main-accent-color);
    width: max-content;
}

#my-reviews  #review-scroller > div.review > div.review-body > button > span {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    margin-left: 5px;
}

.landlord #my-reviews  #review-scroller > div.review > div.review-body > button {
    color: var(--tertiary-accent-color);
}

/*######################################################################################################################
                                                    Favorites
######################################################################################################################*/
#favorite-housings {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

#favorite-housings > .roomjack-container {
    display: grid;
    gap: 10px;
}

#favorite-housings > .roomjack-container > a {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 16px;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media (hover:none) {
    div.conversation-preview div.conversation-preview-control > button {
        display: initial;
    }
}

@media only screen and (min-width: 1200px) {
    #advert-editor .screen-size-invalid {
        display: none;
    }

    #advert-editor .screen-size-valid {
        display: grid;
    }
}

@media only screen and (max-width: 1450px) {

    #conversation-surface-container {
        grid-column: 2/4;
    }

    #booking-overview {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 7;
        margin: 0;
        padding: 25px;
        width: 320px;
    }

    #booking-overview button#close-overview {
        display: flex;
    }

    #booking-overview > .roomjack-container:not(.booking-checkout-box) {
        background-color: white;
    }

}

@media only screen and (max-width: 1199px) {

    #my-statistics #income-overview .content-container {
        grid-template-columns: repeat(2, 400px);
    }

    #advert-editor .screen-size-invalid {
        display: flex;
    }

    #advert-editor .screen-size-valid {
        display: none;
    }

    #conversation-previews.hide {
        display: none;
    }

    #conversation-previews:not(.hide) + #conversation-surface {
        display: none;
    }

    #chat-container {
        grid-template-columns: 1fr;
        margin: 0;
        padding: 0;
    }

    #conversation-surface-container,
    #conversation-surface-container.expanded {
        grid-column: 1;
    }

    #chat-container.no-chat #conversation-surface-container {
        display: none;
    }

    #message-surface {
        padding: 10px;
    }

    #booking-overview {
        padding: 10px;
        width: 290px;
        margin: -10px;
    }

    #conversation-previews {
        margin: -10px;
        padding: 10px;
        width: unset;
    }

    #close-conversation.show {
        position: absolute;
        z-index: 7;
        display: flex;
        left: 5px;
        top: 5px;
    }

    #show-overview {
        top: calc((100vh - var(--main-banner-height) - 67px) / 2 - 150px);
        right: 0;
    }

}

@media only screen and (max-width: 1100px) {

    #dashboard {
        height: max-content;
    }

    #dashboard-toolbar {
        display: none;
    }

    #dashboard-body {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 20px;
    }

    #dashboard-body .roomjack-container {
        grid-row: auto !important;
        grid-column: auto !important;
        min-height: 300px;
    }

    #dashboard-body .roomjack-container.borderless {
        display: none;
    }

    .advert-inspector {
        grid-template-columns: 1fr;
    }

    #my-statistics #income-overview .content-container,
    #my-statistics #length-of-stay-overview .content-container {
        grid-template-columns: 1fr;
    }

    #my-statistics #length-of-stay-overview .statistic-overview-container {
        grid-column: 1;
        grid-row: 1;
        grid-template-columns: repeat(3, 1fr);
    }

    #my-statistics #length-of-stay-overview .statistic-overview-container > .statistic-display {
        min-width: unset;
        width: 100%;
    }

    #my-statistics #length-of-stay-overview div.statistic-data-container > div.roomjack-container {
        justify-self: center;
    }

}

@media only screen and (max-width: 950px) {

    #jack-board {
        width: 100% !important;
    }

    #jack-board h1 {
        text-align: center;
    }

    #my-chat {
        padding: 25px 0 0 0 !important;
    }

    #chat-container {
        padding: 10px;
    }

    #booking-overview {
        margin: 0;
    }

    #show-overview {
        right: 10px;
    }

    #close-conversation.show {
        left: 15px;
        top: 15px;
    }

    #my-desktop > div.subpage-side-menu.expanded + #jack-board {
        margin-left: calc(var(--side-menu-width) * -1);
    }

    #my-desktop > div.subpage-side-menu:not(.expanded) + #jack-board {
        margin-left: calc(var(--subpage-side-menu-small-screen-collapsed-width) * -1);
    }

    #my-statistics div.statistic-container > div.content-container {
        justify-content: center;
    }

    .second-filter-row {
        justify-content: center;
    }

    div.statistic-title {
        text-align: center;
    }

    #favorite-housings > .roomjack-container {
        max-width: unset;
    }

}

@media only screen and (max-width: 750px) {

    #conversation-previews {
        margin: 0;
    }

    #conversation-surface-container {
        height: inherit;
        margin: 0 -10px;
        box-shadow: none !important;
        border-radius: 0;
        max-height: 100%;
    }

    #chat-container {
        padding: 0;
    }

    #show-overview {
        right: 0;
    }

    #my-statistics #length-of-stay-overview,
    #my-statistics #length-of-stay-overview .content-container {
        width: 100%;
    }

    #my-statistics #length-of-stay-overview .statistic-overview-container {
        padding: 10px;
        gap: 5px;
        box-shadow: var(--container-shadow);
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);
        border-radius: 1em;
    }

    #my-statistics #length-of-stay-overview .statistic-overview-container > .statistic-display {
        display: grid;
        grid-template-columns: 70% 30%;
        box-shadow: none;
        padding: 5px;
        flex-direction: row;
        max-width: unset;
    }

    #my-statistics #length-of-stay-overview .statistic-overview-container > .statistic-display > div,
    #my-statistics #length-of-stay-overview .statistic-overview-container > .statistic-display > div > div {
        font-size: 12px !important;
        height: unset;
        text-align: left;
        margin: 0;
    }

    #my-statistics #length-of-stay-overview .statistic-overview-container > .statistic-display > .statistic-display-value {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: unset;
        gap: 5px;
    }

    #my-statistics #length-of-stay-overview div.statistic-data-container > div.roomjack-container {
        width: 100%;
    }

    #my-statistics #income-overview .content-container {
        grid-template-columns: 1fr;
    }

    #my-reviews .review-main-container {
        grid-template-columns: 1fr;
    }

    #my-reviews .review-main-container > #review-scroller {
        grid-column: 1;
    }

    #favorite-housings .search-result {
        flex-wrap: wrap;
    }

    div#search-result-scroller .search-result div.right-panel > .favorite-marker {
        padding: 0;
    }

}

@media only screen and (max-width: 600px) {

    #my-todos > .horizontal-form-group {
        flex-direction: column;
    }

    #my-todos > .horizontal-form-group > button {
        margin-left: unset;
    }

    #conversation-surface > #message-surface > .description-container {
        max-width: 400px;
    }

}

@media only screen and (max-width: 500px) {

    #jack-board > div {
        padding: 25px 10px;
    }

}
