/*######################################################################################################################
                                         PROFILE PAGE STYLING
######################################################################################################################*/
#profile-page div.roomjack-container > div.roomjack-container-header {
    color: black !important;
}

#profile-page {
    min-height: calc(100vh - var(--main-banner-height));
}

#profile-page h1 {
    color: var(--main-accent-color);
    margin-bottom: 0;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    grid-column: 1 / span 2;
}

#main-body.landlord #profile-page h1 {
    color: var(--tertiary-accent-color)
}

#profile-page div.subpage-side-menu {
    width: var(--profile-menu-width);
}

#profile-page div#profile-editor-surface {
    position: relative;
    width: 100vw;
    padding: 25px;
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 20px;
    grid-template-rows: auto 1fr;
}

#profile-page div#profile-editor-surface > div#profile-editor-helper-buttons {
    grid-row: 2;
    grid-column: 2;
}

#profile-page div#profile-editor-surface > div#profile-editor-tool-container {
    max-width: 900px;
}

#profile-page div#profile-editor-surface > div#profile-editor-tool-container > div {
    display: grid;
    gap: 20px;
}

#profile-page div.modal div.modal-header h4 {
    color: black;
}

/*######################################################################################################################
                                         PROFILE OVERVIEW
######################################################################################################################*/

#avatar-selector-container {
    width: max-content;
}

#profile-page div.drop-zone {
    width: 200px;
    height: 200px;
}

#profile-page .image-uploader .drop-zone {
    border-radius: 9999px;
    padding: 10px;
}

#profile-page .image-uploader .drop-zone__placeholder {
    border-radius: 9999px;
    padding: 20px;
    position: relative;
}

#profile-page .image-uploader .drop-zone div.drop-zone__placeholder > svg {
    font-size: 140px;
    color: white;
    position: absolute;
    z-index: 0;
}

#profile-page .image-uploader .drop-zone.valid div.drop-zone__placeholder > svg {
    color: forestgreen;
}

#profile-page .image-uploader .drop-zone.invalid div.drop-zone__placeholder > svg {
    color: red;
}

#profile-page .image-uploader button.image-uploader-edit-button {
    right: 15%;
    bottom: 15%;
}

#avatar-editor div.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#avatar-editor div.modal-footer {
    justify-content: center;
}

#avatar-editor div.avatar-box {
    height: 400px;
    position: relative;
    width: min-content;
}

#avatar-editor div.avatar-box > div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    cursor: move;
}

#avatar-editor div.avatar-box img {
    height: 100%;
}

#avatar-editor div.zoom-box {
    width: 250px;
    display: flex;
    gap: 1em;
    align-items: center;
    margin-top: 3em;
}

#profile-card {
    padding: 1.5em;
    width: max-content;
}

#profile-card table {
    margin-top: 2em;
    width: 100%;
}

#profile-card tr {
    line-height: 1.5em;
    align-items: center;
    border-top: 1px solid var(--light-gray-color);
    font-size: var(--small-text);
}

#profile-card tr > td {
    padding: 8px;
}

#profile-card tr > td:last-child {
    width: max-content;
}

#profile-card tr > td > input {
    width: 100%;
}

#profile-card td.label {
    font-weight: bolder;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    vertical-align: top;
}

#profile-card td.data {
    color: gray;
    max-width: 400px;
}

#profile-card tr.name-container > td.data > svg {
    color: forestgreen;
    margin-left: 5px;
}

#profile-card td.control {
    vertical-align: top;
}

#profile-card td.control > button {
    border: none;
    background-color: white;
    font-size: .7em;
    outline: none;
}

#profile-card td.control > button > svg {
    color: var(--main-accent-color);
    margin-right: .5em;
}

#profile-card td.control > button > span {
    color: var(--main-accent-color);
    text-transform: uppercase;
}

div#main-body.landlord #profile-card td.control > button > svg {
    color: var(--tertiary-accent-color);
}

div#main-body.landlord #profile-card td.control > button > span {
    color: var(--tertiary-accent-color);
}

#profile-card td.control > button:disabled > span {
    color: gray;
}

#profile-card td.control > button:disabled > svg {
    color: gray;
}

#profile-overview tr.address-container > td.data input {
    margin-bottom: 10px;
}

#profile-card tr.language-container > td.data > div {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3px;
}

/*######################################################################################################################
                                                   ACCOUNT
######################################################################################################################*/
#profile-page #account-settings-description {
    font-size: .8rem;
    display: grid;
    gap: .5em;
}

#profile-page #account-settings div.desktop-submenu-grid {
    margin: 20px 0 0 0;
}

#profile-page #revocation-policy-container {
    display: flex;
    flex-direction: column;
    gap: .5em;
    margin-bottom: 1em;
    background-color: #eeeeee;
    border: 1px solid var(--light-gray-color);
    border-radius: 1em;
    padding: .5em;
}

#profile-page #revocation-policy-description {
    font-size: var(--small-text);
}

#profile-page .revocation-checkbox {
    display: flex;
    color: black;
    font-size: var(--small-text);
    gap: 5px;
    align-items: center;
}

#profile-page div.profile-menu-response-message {
    margin: 1em 0;
}

#stripe-session-link {
    width: max-content;
}

#account-status-change-modal #account-status-change-container {
    display: grid;
    gap: 2em;
}

#account-status-change-modal #account-status-change-description-container {
    display: grid;
    gap: 2em;
    justify-items: center;
    font-size: .8em;
}

#account-status-change-modal #account-status-change-container > #account-status-change-description-container > img {
    height: 150px;
}

#account-status-change-modal #account-status-change-description {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
}

#account-status-change-modal #account-status-change-description-header {
    text-transform: uppercase;
    color: var(--main-accent-color);
    font-family: urbane-rounded, sans-serif;
    text-align: center;
}

#account-status-change-modal #account-status-change-description-content {
    color: gray;
}

#account-status-change-modal #account-status-change-reason-container {
    display: grid;
    gap: .5em;
    padding: 1em;
    border-radius: 1em;
}

#account-status-change-modal #account-status-change-reason-container.invalid {
    border: 2px solid red;
}

#account-status-change-modal #account-status-change-reason-container > form {
    display: grid;
}

#account-status-change-modal #account-status-change-reason-container > form > #security-code-group {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-self: center;
}

#account-status-change-modal #account-status-change-reason-container > form > #security-code-group > input {
    width: 180px;
}

#account-status-change-modal button.outline-button {
    width: max-content;
    justify-self: center;
}

/*######################################################################################################################
                                                   PASSWORD
######################################################################################################################*/
#change-password .secret-input-field {
    width: 250px;
}

/*######################################################################################################################
                                               THIRD PARTY SOFTWARE
######################################################################################################################*/

#profile-page #third-party-software > div.roomjack-container {
    display: grid;
    gap: 25px;
}

#profile-page #third-party-software #third-party-list table {
    width: 100%;
}

#profile-page #third-party-software #third-party-list table th,
#profile-page #third-party-software #third-party-list table td {
    border: 1px solid lightgray;
    padding: 5px;
}

#profile-page #third-party-software #third-party-list table th:last-child,
#profile-page #third-party-software #third-party-list table td:last-child {
    text-align: center;
    width: min-content;
}

#profile-page #third-party-software #third-party-list table td:last-child > button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 auto;
}

#profile-page #third-party-software > div.roomjack-container > button {
    width: max-content;
}

#profile-page #third-party-software #third-party-modal div.modal-body {
    display: grid;
    gap: 10px;
}

#profile-page #third-party-software #third-party-modal div.modal-body #third-party-modal-description {
    font-size: .8rem;
}

#profile-page #third-party-software #third-party-modal div.modal-body > div.form-group {
    padding: 5px;
}

#profile-page #third-party-software #third-party-modal div.modal-body > div.form-group.invalid {
    border: 1px solid red;
}

#profile-page #third-party-software #third-party-modal div.modal-body #public-key-error-message:not(:empty)::before {
    content: '\f071';
    font-family: "Font Awesome 6 Pro", sans-serif;
    color: var(--tertiary-accent-color);
    margin-right: 5px;
}

#profile-page #third-party-software #third-party-modal div.modal-body #public-key-error-message {
    font-size: .8rem;
    color: gray;
}

#profile-page #third-party-software #zimmersoftware-button > .tile-icon-container > div {
    background-image: url('https://roomjack.blob.core.windows.net/roomjack/logos/zimmersoftware_logo.svg');
}

/*######################################################################################################################
                                                   LANGUAGES
######################################################################################################################*/
#my-languages-container .flag-wrapper {
    width: 60px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-accent-color);
    border-radius: 10px;
    box-shadow: var(--container-shadow);
    padding: 0;
    overflow: hidden;
}

div#main-body.landlord #my-languages-container .flag-wrapper {
    background-color: var(--tertiary-accent-color);
}


#my-languages-container .flag-wrapper .flag {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

#my-languages-container .flag-wrapper.selected {
    padding: 4px;
    border-radius: 8px;
}

#my-languages-container div.flag-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding-top: 1em;
    flex-basis: 20%;
}

/*######################################################################################################################
                                                 NOTIFICATIONS
######################################################################################################################*/
#notifications .notification-table {
    width: 100%;
}

#notifications .notification-table tr > td {
    height: max-content;
    margin-top: 1em;
}

#notifications .notification-table tr > td:first-child {
    width: 100%;
}

#notifications table td:last-child {
    width: max-content;
    display: inline-block;
}
/*######################################################################################################################
                                                 CUSTOM SLIDER
######################################################################################################################*/
.custom-slider {
    width: 200px;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-slider > div.slider-bg {
    background-color: var(--light-gray-color);
    height: 4px;
    width: 100%;
}

.custom-slider > div.slider-fg {
    background-color: var(--main-accent-color);
    height: 4px;
    position: absolute;
}

.custom-slider > div.slider-handle {
    background-color: var(--main-accent-color);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: absolute;
    transform-origin: -8px 8px;
}
/*

.custom-slider > div.slider-fg {
    background-color: var(--tertiary-accent-color);
}

.custom-slider > div.slider-handle {
    background-color: var(--tertiary-accent-color);
}*/

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media (min-width: 1200px) {

}

@media only screen and (max-width: 1199px) {

    #profile-page #profile-card td.data {
        max-width: 300px;
    }

}

@media only screen and (max-width: 1100px) {

    #profile-page #profile-card td.data {
        max-width: 250px;
    }

}

@media only screen and (max-width: 1050px) {

    #profile-page #profile-card td.data {
        max-width: 200px;
    }

}

@media only screen and (max-width: 950px) {

    #profile-page h1 {
        text-align: center;
    }

    #profile-page > div.subpage-side-menu + #profile-editor-surface {
        margin-left: calc(var(--profile-menu-width) * -1);
    }

    #profile-page > div.subpage-side-menu:not(.expanded) + #profile-editor-surface {
        margin-left: calc(var(--subpage-side-menu-small-screen-collapsed-width) * -1);
    }
}

@media only screen and (max-width: 850px) {

    #profile-page .roomjack-container {
        width: 100% !important;
    }

    #profile-editor-surface {
        padding: 45px 10px 10px 10px !important;
    }

    #profile-page div#profile-editor-surface > div#profile-editor-tool-container {
        max-width: calc(100vw - 20px) !important;
    }

    #profile-card {
        padding: 10px !important;
        display: grid;
        justify-items: center;
    }

    #profile-page #profile-card td.label {
        font-size: 11px !important;
    }

}

@media only screen and (max-width: 620px) {

    #profile-page #profile-card td.control > button > span {
        display: none;
    }

    #profile-page #profile-card td.control > button > svg {
        font-size: 12px;
        margin-right: 0;
    }

    #profile-page div#profile-editor-surface {
        grid-template-columns: auto;
    }

    #profile-page div#profile-editor-surface > div#profile-editor-tool-container {
        grid-column: 1 / span 2;
    }

    #profile-page div#profile-editor-surface > div#profile-editor-helper-buttons {
        position: absolute;
        top: 10px;
        right: 10px;
        grid-row: unset;
        grid-column: unset;
        width: max-content;
        grid-template-columns: auto;
    }

    #profile-page #profile-editor-helper-buttons .desktop-submenu-tile {
        height:  70px;
        width: 80px;
        background-color: var(--main-accent-color-medium);
    }

    .landlord #profile-page #profile-editor-helper-buttons .desktop-submenu-tile {
        background-color: var(--tertiary-accent-color-medium);
    }

    #profile-page #profile-editor-helper-buttons .desktop-submenu-tile > .tile-icon-container {
        font-size: 20px;
        color: white;
    }

}

@media only screen and (max-width: 500px) {

    #profile-page #profile-card td.label {
        font-size: 10px !important;
    }

}
