/*######################################################################################################################
                                                      PROFILE OVERVIEW
######################################################################################################################*/
div.user-profile {
    position: relative;
    display: block;
    padding: 25px 0;
}

div.user-profile > img {
    opacity: .5;
    height: 330px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    z-index: -1;
}

div.user-profile > div#user-profile-loading {
    width: 100%;
    height: calc(100vh - var(--main-banner-height));
    justify-content: center;
}

div.user-profile > div#user-profile-loading > img {
    width: 15vw;
}

div.user-profile > div.container {
    display: grid;
    gap: 25px;
}

div.user-profile > div.container > div#profile-overview-header > button {
    margin: 0 0 .5em 0;
    color: gray;
}

div.user-profile > div.container > div#profile-overview-header > h1 {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    margin-bottom: 0;
}

div.user-profile > div.container > div#profile-overview-body {
    display: grid;
    gap: 25px;
}

div.user-profile #profile-data-card {
    display: flex;
    padding: 0;
    overflow: hidden;
    background-color: white;
}

div.user-profile  #profile-data-card > div.avatar-data {
    background-color: var(--main-accent-color);
    display: grid;
    padding: 50px 30px 30px 30px;
    margin: -2px;
    justify-items: center;
}

div#main-body.landlord div.user-profile  #profile-data-card > div.avatar-data {
    background-color: var(--tertiary-accent-color);
}

div.user-profile #profile-data-card > div.avatar-data > div.name-container {
    text-align: center;
}

div.user-profile #profile-data-card > div.avatar-data > div.name-container > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: 18px;
    color: white;
    margin: 15px 0 10px 0;
}

div.user-profile #profile-data-card > div.avatar-data > div.name-container > svg {
    color: white;
    margin-left: 5px;
}

div.user-profile #profile-data-card > div.avatar-data > div.subscription-time {
    color: white;
    text-align: center;
    font-size: var(--small-text);
}

div.user-profile  #profile-data-card > div.personal-data {
    padding: 2em;
    display: grid;
    gap: 5px;
    height: max-content;
    width: 70%;
}

div.user-profile #profile-data-card > div.personal-data > div.personal-data-row {
    border-bottom: 1px solid var(--light-gray-color);
    padding-bottom: 1em;
    display: flex;
    align-items: flex-start;
    padding-top: 4px;
}

div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label {
    min-width: 40%;
    white-space: nowrap;
}

div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label > span {
    font-size: 14px;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    width: 100px;
}

div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label > svg {
    margin-right: .5em;
    color: var(--main-accent-color);
    font-size: 20px;
}

div#main-body.landlord div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label > svg {
    color: var(--tertiary-accent-color);
}

div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-container {
    font-size: 14px;
    color: gray;
    max-width: 60%;
}

div.user-profile #profile-data-grid {
    display: flex;
    gap: 25px;
}

div.user-profile #profile-data-grid > div {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

div.user-profile #profile-data-grid > div > div > div.header {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--main-accent-color);
    margin-bottom: 20px;
    width: 100%;
}

div#main-body.landlord div.user-profile #profile-data-grid > div > div > div.header {
    color: var(--tertiary-accent-color);
}

div.user-profile #profile-data-grid > #profile-data-left-col {
    width: 45%;
}

div.user-profile #profile-data-grid > #profile-data-left-col #profile-review-details {
    display: grid;
}

div.user-profile #profile-data-grid > #profile-data-left-col #profile-send-message {
    background-color: var(--main-accent-color-bright);
    cursor: pointer;
    position: relative;
    height: 250px;
    display: flex;
    justify-content: center;
}

div.user-profile #profile-data-grid > #profile-data-left-col #profile-send-message > img {
    height: 230px;
    width: auto;
    position: absolute;
    bottom: 0;
}

div.user-profile #profile-data-grid > #profile-data-right-col:empty {
    display: none;
}

div.user-profile #profile-data-grid > #profile-data-right-col {
    width: 55%;
}

div.user-profile #profile-data-grid > #profile-data-right-col #profile-advert-scroller {
    height: max-content;
    overflow: clip auto;
    display: grid;
    gap: 10px;
}

div.user-profile #profile-data-grid > #profile-data-right-col #profile-advert-scroller div.loading {
    height: 5em;
    justify-content: center;
    width: 100%;
}

div.user-profile #profile-data-grid > #profile-data-right-col #profile-advert-container {
    height: max-content;
}

div.user-profile #profile-data-grid > #profile-data-right-col #profile-superjacker {
    background-color: var(--secondary-accent-color);
    height: max-content;
    margin-top: 1em;
    position: relative;
}

div.user-profile #profile-data-grid > #profile-data-right-col #profile-superjacker > div {
    color: white;
    font-size: .7em;
    width: 50%;
}

div.user-profile #profile-data-grid > #profile-data-right-col #profile-superjacker > img {
    height: 150px;
    position:absolute;
    right: 3em;
    top: -1em;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 1000px) {

    div.user-profile > img {
        height: 200px;
    }

    #profile-data-grid {
        flex-direction: column;
    }

    div.user-profile #profile-data-grid > #profile-data-left-col,
    div.user-profile #profile-data-grid > #profile-data-right-col {
        width: 100%;
    }

    div.user-profile #profile-data-grid > #profile-data-left-col #profile-rating > div.rating-average-container {
        height: max-content;
    }

    div.user-profile #profile-data-grid > div > div > div.header {
        margin-bottom: 10px;
    }

}

@media only screen and (max-width: 800px) {

    div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label {
        min-width: 180px;
    }

    div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-container {
        max-width: unset;
    }

    div.user-profile #profile-data-card {
        flex-direction: column;
    }

    div.user-profile #profile-data-card > div.avatar-data {
        padding: 20px;
        column-gap: 20px;
    }

    div.user-profile #profile-data-card > div.avatar-data > .avatar-container {
        grid-row: 1 / span 2;
        grid-column: 1;
        justify-self: right;
    }

    div.user-profile #profile-data-card > div.avatar-data > .name-container {
        grid-row: 1;
        grid-column: 2;
        justify-self: left;
        height: max-content;
        align-self: end;
    }

    div.user-profile #profile-data-card > div.avatar-data > .subscription-time {
        grid-row: 2;
        grid-column: 2;
        justify-self: left;
        height: max-content;
    }

    div.user-profile #profile-data-card > div.personal-data {
        width: 100%;
    }

}

@media only screen and (max-width: 700px) {

    div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label {
        min-width: 50px;
    }

    div.user-profile #profile-data-card > div.personal-data > div.personal-data-row > div.personal-data-label > span {
        display: none;
    }

    div.user-profile #profile-data-grid > #profile-data-right-col .housing-overview div.advert-preview-information > div.housing-title-label {
        font-size: 10px;
    }

}

@media only screen and (max-width: 500px) {

    div.user-profile #profile-data-grid > #profile-data-left-col #profile-send-message {
        height: 170px;
    }

    div.user-profile #profile-data-grid > #profile-data-left-col #profile-send-message > img {
        height: 150px;
    }

}

@media only screen and (max-width: 500px) {

    div.user-profile #profile-data-card > div.avatar-data > .avatar-container {
        grid-row: 1;
        grid-column: 1;
        justify-self: center;
    }

    div.user-profile #profile-data-card > div.avatar-data > .name-container {
        grid-row: 2;
        grid-column: 1;
        justify-self: center;
    }

    div.user-profile #profile-data-card > div.avatar-data > .subscription-time {
        grid-row: 3;
        grid-column: 1;
        justify-self: center;
    }

}