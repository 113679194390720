/*######################################################################################################################
                                            TYPE: search-result
######################################################################################################################*/

.search-result {
    padding: 10px;
    display: flex;
    height: max-content;
    gap: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none !important;
}

.search-result.color-boost {
    background-color: var(--main-accent-color);
    color: white;
}

.search-result.gold {
    background-color: var(--tertiary-accent-color);
    color: white;
}

.search-result.no-match {
    display: none;
}

.search-result.color-boost div.housing-type-label,
.search-result.gold div.housing-type-label,
div.search-result.color-boost div.housing-type-label,
div.search-result.gold div.housing-type-label {
    color: black !important;
}

.search-result.color-boost div.accent-divider,
.search-result.gold div.accent-divider {
    background-color: white !important;
}

.search-result.fade-out:not(.map-hover) {
    opacity: 0.2;
}

.search-result .main-information-container {
    padding: 10px;
    display: flex;
    height: max-content;
    gap: 10px;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none !important;
}

.search-result div.left-panel > div.image-slider {
    width: 12vw;
    height: 8vw;
    /*background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    overflow: hidden;*/
}

.search-result div.middle-panel {
    height: max-content;
    flex: 8;
}

.search-result div.middle-panel > div.housing-type-label {
    color: dimgray;
    font-weight: 50;
    font-size: 80%;
}

.search-result div.middle-panel > div.housing-title-label {
    font-size: 110%;
    line-height: 110%;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    overflow: hidden;
    word-wrap: anywhere;
    color: black !important;
}

.search-result.color-boost div.middle-panel > div.housing-title-label,
.search-result.gold div.middle-panel > div.housing-title-label {
    color: white !important;
    text-decoration: none !important;
}

.search-result div.middle-panel > div.accent-divider {
    height: 3px;
    width: 20%;
    background-color: var(--main-accent-color);
    border-radius: 3px;
    margin: 5% 0;
}

.search-result div.middle-panel > div.housing-description-container {
    color: gray;
    margin-bottom: .5em;
    font-size: 80%;
    line-height: 1.2em;
    word-wrap: anywhere;
    height: calc(3 * 1.2em);
    overflow-y: hidden;
}

.search-result.color-boost div.middle-panel > div.housing-description-container,
.search-result.gold div.middle-panel > div.housing-description-container {
    color: black;
}

.search-result div.middle-panel > div.housing-preview-property-container {
    display: flex;
    gap: .5em;
    align-items: center;
    color: gray;
}

.search-result.color-boost div.middle-panel > div.housing-preview-property-container,
.search-result.gold div.middle-panel > div.housing-preview-property-container {
    color: black;
}

.search-result.color-boost div.middle-panel > div.housing-preview-property-container > svg,
.search-result.gold div.middle-panel > div.housing-preview-property-container > svg {
    color: white;
}

.search-result div.middle-panel > div.housing-preview-property-container > span.label {
    font-size: 70%;
    text-transform: uppercase;
    font-family: urbane-rounded-light, sans-serif;
}

.search-result div.middle-panel > div.housing-preview-property-container > span.data {
    font-family: urbane-rounded, sans-serif;
    font-size: 80%;
    color: black;
}

.search-result.color-boost div.middle-panel > div.housing-preview-property-container > span.data,
.search-result.gold div.middle-panel > div.housing-preview-property-container > span.data {
    color: white;
}

.search-result div.right-panel {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.favorite-marker {
    width: max-content;
    position: relative;
    padding: 0 !important;
}

.favorite-marker > svg.outline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 24px;
    color: var(--main-accent-color);
}

div#main-body.landlord .favorite-marker > svg.outline {
    color: var(--tertiary-accent-color);
}

.favorite-marker > svg.outline + svg.inner {
    color: rgba(0,0,0,0);
    position: absolute;
    font-size: 22px;
    transition: .1s ease-in-out;
    top: 1px;
    left: 1px;
}

.favorite-marker:hover > svg.outline + svg.inner {
    color: var(--main-accent-color);
    opacity: .6;
}

div#main-body.landlord .favorite-marker > svg.outline + svg.inner:hover {
    color: var(--tertiary-accent-color);
}

.favorite-marker > svg.outline + svg.inner.selected {
    color: var(--main-accent-color);
}

div#main-body.landlord .favorite-marker > svg.outline + svg.inner.selected {
    color: var(--tertiary-accent-color);
}

.search-result div.right-panel > .favorite-marker {
    align-self: end;
    -webkit-align-self: flex-end;
}

.search-result.color-boost div.right-panel > .favorite-marker > svg.outline,
.search-result.gold div.right-panel > .favorite-marker > svg.outline {
    color: white;
}

.search-result.color-boost div.right-panel > .favorite-marker > svg.outline + svg.inner:hover,
.search-result.gold div.right-panel > .favorite-marker > svg.outline + svg.inner:hover {
    color: white;
    opacity: .4;
}

.search-result.color-boost div.right-panel > .favorite-marker > svg.outline + svg.inner.selected,
.search-result.gold div.right-panel > .favorite-marker > svg.outline + svg.inner.selected {
    color: white;
}

.search-result div.right-panel > div.price-container {
    text-align: right;
    margin-top: auto;
}

.search-result div.right-panel > div.price-container > div.label {
    color: gray;
    font-size: 80%;
    white-space: nowrap;
}

.search-result.color-boost div.right-panel > div.price-container > div.label,
.search-result.gold div.right-panel > div.price-container > div.label {
    color: black;
}

.search-result div.right-panel > div.price-container > div.data {
    color: var(--main-accent-color);
    font-size: 130%;
    display: inline-block;
    line-height: 120%;
    overflow: visible;
    font-family: urbane-rounded, sans-serif;
    letter-spacing: -1px;
    white-space: nowrap;
}

.search-result.color-boost div.right-panel > div.price-container > div.data,
.search-result.gold div.right-panel > div.price-container > div.data {
    color: white;
}

/*######################################################################################################################
                                            TYPE: optional-result
######################################################################################################################*/
.optional-result {
    width: 260px;
    padding: 5px;
    cursor: pointer;
    text-decoration: none !important;
}

.optional-result > div.image-slider {
    height: 166px;
    width: 250px;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.optional-result .favorite-marker {
    position: absolute;
    right: 15px;
    top: 15px;
}

.optional-result .favorite-marker > svg.outline {
    color: white;
    font-size: 15px;
}

.optional-result .favorite-marker > svg.inner:hover {
    color: white;
    opacity: .4;
}

.optional-result .favorite-marker > svg.inner.selected {
    color: white;
}

.optional-result > div.rating > svg {
    color: var(--main-accent-color);
    font-size: 12px;
}

.optional-result > div.rating > span:nth-child(2) {
    text-transform: uppercase;
    font-size: 11px;
    color: gray;
    margin: 0 5px;
}

.optional-result > div.rating > span:last-child {
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
    color: black !important;
}

.optional-result > div.advert-type {
    font-size: 12px;
    color: gray;
}

.optional-result > div.housing-title {
    font-size: 12px;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    color: black !important;
}

/*######################################################################################################################
                                            TYPE: overview
######################################################################################################################*/
.housing-overview {
    display: flex;
    padding: 15px;
    gap: 10px;
    border-radius: 15px;
    border: 1px solid var(--light-gray-color);
    position: relative;
    text-decoration: none !important;
    width: 100% !important;
}

.housing-overview.border-bottom {
    border-radius: 0 !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid lightgray !important;
}

.housing-overview > .advert-main-info {
    display: flex;
    gap: 15px;
    height: max-content;
}

.housing-overview > .advert-additional-info {
    display: grid;
    height: max-content;
}

.housing-overview div.advert-registration-no {
    font-size: 10px;
    color: gray;
    position: absolute;
    top: 15px;
    right: 20px;
}

.housing-overview div.last-edit-label {
    font-size: 10px;
    color: gray;
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 15px
}

.housing-overview div.image-slider {
    width: 180px;
    height: 120px;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
}

.housing-overview div.advert-preview-information {
    display: flex;
    flex-direction: column;
    gap: .2em;
}

.housing-overview div.advert-preview-information > div.housing-type-label {
    color: gray;
    font-size: var(--small-text);
}

.housing-overview div.advert-preview-information > div.housing-title-label {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    font-size: var(--small-text);
    color: black !important;
}

.housing-overview div.advert-preview-information > div.housing-rooms-label {
    color: gray;
    font-size: var(--small-text);
}

.advert-editor-container {
    display: grid;
    gap: 2px;
}

.advert-editor-container > .controls {
    display: flex;
    gap: 5px;
    min-height: 20px;
}

.advert-editor-container > .controls.right {
    justify-content: flex-end;
}

.advert-editor-container > .controls > button {
    text-transform: uppercase;
    display: flex;
    gap: 3px;
    font-size: 12px;
    align-items: center;
    font-family: open-sans-bold, sans-serif;
}

.advert-editor-container > .controls > button.booster {
    color: var(--color-boost);
}

.advert-editor-container > .controls > button.duplication {
    color: var(--accept-color);
}

.advert-editor-container > .controls > button.pro-tool {
    color: var(--tertiary-accent-color);
}

.advert-editor-container > .controls > button.delete {
    color: gray;
}

.advert-editor-container > .controls > button.edit {
    color: var(--main-accent-color);
}

.advert-editor-container > .controls > button.deactivate {
    color: indianred;
}

.advert-editor-container > .controls > button.activate {
    color: olivedrab;
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 950px) {

    .search-result div.left-panel > div.image-slider {
        width: 24vw;
        height: 16vw;
    }

}

@media only screen and (max-width: 750px) {

    .search-result {
        flex-wrap: wrap;
    }

    .housing-overview {
        padding: 10px;
    }

    .search-result .main-information-container {
        flex-direction: column;
        width: 100%;
    }

    .search-result div.left-panel > div.image-slider {
        width: 100%;
        height: 0;
        padding-top: 75%;
    }

    .search-result div.left-panel > div.image-slider .main-image {
        margin-top: -75%;
        height: unset !important;
    }

    .search-result .main-information-container .housing-description-container {
        display: none;
    }

    .search-result .right-panel {
        position: absolute;
        height: calc(100% - 40px);
        top: 20px;
        right: 20px;
    }

    .search-result .right-panel > button {
        margin: 10px 10px 0 0;
    }

    .search-result div.middle-panel > div.housing-title-label,
    .search-result div.right-panel > div.price-container > .data {
        font-size: 90%;
    }

    .housing-overview {
        flex-direction: column;
    }

    .housing-overview div.advert-registration-no {
        position: relative;
        text-align: right;
        top: 0;
        right: 0;
    }

    .housing-overview div.last-edit-label {
        position: relative;
        bottom: 0;
        right: 0;
    }

}

@media only screen and (max-width: 500px) {

    .housing-overview .advert-main-info {
        flex-direction: column;
    }

    .housing-overview .image-slider {
        width: 100% !important;
        height: 0 !important;
        padding-top: 75%;
    }

    .housing-overview .image-slider > .main-image {
        height: unset !important;
        margin-top: -75%;
    }

    div.user-profile #profile-data-grid > #profile-data-right-col .housing-overview div.advert-preview-information > div.housing-title-label {
        font-size: var(--small-text) !important;
    }

}