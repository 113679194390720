/*######################################################################################################################
                                                      REVIEW VIEWER
######################################################################################################################*/
div.review-viewer {
    width: 100%;
    height: max-content;
}

div.review-viewer > div.review-grid {
    display: grid;
    width: 100%;
    height: max-content;
    gap: 25px;
}

div.review-viewer div.no-review-placeholder {
    text-align: center;
    width: 100%;
}

div.review-viewer div.general-rating {
    display: flex;
    align-items: center;
    gap: .5em;
}

div.review-viewer div.general-rating > svg {
    color: var(--main-accent-color);
    font-size: var(--small-text);
}

div.review-viewer div.general-rating > span:nth-child(2) {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
}

div.review-viewer div.general-rating > span:last-child {
    font-family: urbane-rounded-light, sans-serif;
    font-size: var(--small-text);
}

div.review-viewer div.rating-value-container,
div.review-viewer div.text-review-container {
    display: grid;
    column-gap: 25px;
    row-gap: 5px;
    grid-template-columns: 1fr 1fr;
}

div.review-viewer div.rating-value-bar {
    display: grid;
    align-items: center;
    gap: 15px;
    grid-template-columns: max-content 1fr max-content;
}

div.review-viewer div.rating-value-bar > div.rating-type {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
    width: 220px;
    grid-column-start: 1;
}

div.review-viewer div.rating-value-bar > div.statistic-bar-bg {
    width: auto;
    grid-column-start: 2;
}

div.review-viewer div.rating-value-bar > div.rating-value {
    font-size: var(--small-text);
    font-family: open-sans-bold, sans-serif;
    grid-column-start: 3;
    width: max-content;
}

div.review-viewer div.text-review-item {
    border: 1px solid lightgrey;
    padding: 8px;
    border-radius: 16px;
}

div.review-viewer div.text-review-item > div.text-review-header {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 1em;
}

div.review-viewer div.text-review-item > div.text-review-header > div.header-info > div.author-name {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
}

div.review-viewer div.text-review-item > div.text-review-header > div.header-info > div.timestamp {
    color: var(--light-gray-color);
    font-style: italic;
    font-size: var(--small-text);
}

div.review-viewer div.text-review-item > div.text-review-content {
    color: gray;
    font-size: var(--small-text);
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media only screen and (max-width: 750px) {

    div.review-viewer div.rating-value-container,
    div.review-viewer div.text-review-container {
        grid-template-columns: 1fr;
    }

}