div.room-tree-box,
div.child-container {
    display: grid;
    gap: var(--room-box-gap);
    width: calc(2 * var(--room-box-width) + 1 * var(--room-box-gap));
    grid-template-columns: repeat(2, var(--room-box-width));
    height: max-content;
    position: relative;
}

div.child-container {
    grid-row: 2;
}

div.room-tree-box.apartment > div.child-container {
    grid-column: 1 / span 2;
}

div.room-tree-box.room {
    width: var(--room-box-width);
}

div.room-tree-box:empty {
    display: none !important;
}

.room-tree-box .room-box {
    display: grid;
    background-color: var(--tertiary-accent-color);
    border-radius: 15px;
    position: relative;
    height: var(--room-box-height);
    padding: 8px;
    overflow: hidden;
}

.room-tree-box .room-box.clickable {
    cursor: pointer;
}

.room-tree-box.sort-mode > .room-box {
    opacity: .4;
    filter: grayscale(100%);
}

.room-box > .room-box-overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: white;
    font-family: urbane-rounded, sans-serif;
    font-size: 40px;
    background-color: #00000066;
    display: flex;
    justify-content: center;
    align-items: center;
}

.room-tree-box.sort-mode > .child-container > .room-tree-box {
    cursor: pointer;
}

.room-tree-box > .child-container .room-sorter {
    position: absolute;
    left: calc(100% + var(--room-box-gap));
    top: 0;
    width: max-content;
}

.room-tree-box > .child-container .sort-description {
    position: absolute;
    left: calc(100% + var(--room-box-gap));
    top: 50px;
    width: 80%;
    max-width: calc(700px - (2 * var(--room-box-width) + 1 * var(--room-box-gap)) - 25px);
}

button.hierarchy-room-adder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--tertiary-accent-color-light-transparent);
    height: var(--room-box-height);
    border-radius: 1em;
    border: none;
    outline: none;
    gap: 1em;
}

button.hierarchy-room-adder:hover {
    background-color: var(--tertiary-accent-color-medium);
}

button.hierarchy-room-adder > svg {
    color: var(--tertiary-accent-color);
    font-size: 50px;
}

button.hierarchy-room-adder > span {
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
}

button.hierarchy-room-adder.apartment {
    width: calc(2 * var(--room-box-width) + 1 * var(--room-box-gap));
    grid-column-start: 1;
    grid-column-end: 3;
}

button.hierarchy-room-adder.room {
    width: var(--room-box-width);
}

div.room-box > .bookable-sign {
    position: absolute;
    bottom: 5px;
    right: 7px;
    color: white;
    font-size: var(--small-text);
}

div.room-tree-box.house > div.room-box.house {
    width: calc(3 * var(--room-box-width) + 2 * var(--room-box-gap));
    grid-column-start: 1;
    grid-column-end: 4;
}

div.room-tree-box div.room-box.apartment {
    width: calc(2 * var(--room-box-width) + 1 * var(--room-box-gap));
    grid-column-start: 1;
    grid-column-end: 3;
}

div.room-tree-box div.room-box.room {
    width: var(--room-box-width);
}

div.room-tree-box div.room-box.invalid {
    border: 2px solid var(--tertiary-accent-color);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        box-shadow: 0 0 0 0 var(--tertiary-accent-color-medium);/*rgba(255, 82, 82, 0.7);*/
    }

    70% {
        box-shadow: 0 0 0 10px var(--tertiary-accent-color-medium);
    }

    100% {
        box-shadow: 0 0 0 0 var(--tertiary-accent-color-light);
    }
}

div.room-box > div.room-box-header {
    display: flex;
    gap: 5px;
    align-items: center;
    height: max-content;
    min-height: 25px;
    max-height: 50px;
    overflow-y: hidden;
}

div.room-box > div.room-box-header > span {
    text-transform: uppercase;
    font-size: 11px;
    font-family: urbane-rounded, sans-serif;
}

div.room-box > div.room-box-header > button {
    color: white;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: var(--small-text);
}

div.room-box > div.room-box-header > button.edit-room-box {
    margin-left: auto;
}

div.room-box > div.room-box-body {
    display: flex;
    gap: 1em;
}

div.room-box > div.room-box-body > div.icon-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.room-box > div.room-box-body > div.icon-container > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    background-repeat: no-repeat;
}

div.room-box > div.room-box-body > div.icon-container > svg {
    color: white;
    font-size: 38px;
}

div.room-box div.room-box-properties {
    text-transform: initial;
}

div.room-box div.room-box-properties > table {
    font-size: var(--small-text);
}

div.room-box div.room-box-properties td:first-child {
    color: white;
    padding-right: 5px;
}

div.room-box div.room-box-properties td:last-child {
    padding-left: 5px;
}