.file-uploader {
    display: grid;
    position: relative;
    border-radius: 10px;
    border: 3px dashed lightgrey;
}

.file-uploader .drop-zone__input {
    display: none;
}

.file-uploader .drop-zone__placeholder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    text-align: center;
    gap: 8px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.file-uploader .drop-zone__placeholder > svg {
    font-size: 50px;
    margin-bottom: 20px;
    color: gray;
}

.file-uploader .drop-zone__placeholder > svg.fa-circle-check {
    color: forestgreen;
}

.file-uploader .drop-zone__placeholder > svg.fa-ban {
    color: red;
}

.file-uploader .drop-zone__placeholder > span.file-selector-header-label {
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
    z-index: 1;
    font-size: 12px;
}

.file-uploader .drop-zone__placeholder > span.description-container {
    max-width: 300px;
    text-align: justify;
}