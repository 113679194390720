/*######################################################################################################################
                                                 HOUSING PROPERTY
######################################################################################################################*/
div.housing-property {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

div.housing-property > svg {
    color: var(--main-accent-color);
    font-size: 20px;
}

div.housing-property > div {
    font-family: urbane-rounded, sans-serif;
    font-size: var(--small-text);
}

div.housing-property > button {
    margin-left: auto;
}

@media only screen and (max-width: 500px) {

    div.housing-property > svg {
        font-size: 14px;
    }

    div.housing-property > div {
        font-size: 11px;
    }

}