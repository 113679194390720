div.subpage-side-menu {
    float: left;
    background-color: var(--main-accent-color-light);
    position: relative;
    transition: .2s ease-in-out;
    padding: 8px 24px 24px 24px;
    z-index: 9;
}

div.subpage-side-menu.collapsed {
    padding: 8px 8px 16px 8px !important;
    width: var(--side-menu-width-collapsed) !important;
}

div.subpage-side-menu > button.small-screen-menu-opener {
    display: none;
    color: var(--main-accent-color);
}

.landlord div.subpage-side-menu > button.small-screen-menu-opener {
    color: var(--secondary-accent-color);
}

div.subpage-side-menu > div.sticky-menu-button-container {
    top: calc(var(--main-banner-height) + .5em);
    position: sticky;
    display: flex;
    gap: 1.5em;
    flex-direction: column;
}

#main-body.landlord div.subpage-side-menu {
    background-color: var(--tertiary-accent-color);
}

div.sticky-menu-button-container > button.menu-collapse {
    color: var(--main-accent-color);
    transition: .2s ease-in-out;
    width: 18px;
    height: 18px;
    display: flex;
    padding: 0 !important;
    justify-content: center;
    align-items: center;
    align-self: end;
    margin-bottom: -1em;
}

div.subpage-side-menu.collapsed button.menu-collapse {
    transform: rotate(180deg);
    right: .9em;
}

div#main-body.landlord div.sticky-menu-button-container > button.menu-collapse {
    color: white;
}

div.subpage-side-menu button.subpage-menu-entry {
    width: 100%;
    background-color: transparent;
    white-space: nowrap;
    text-align: left;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    height: 2em;
    opacity: .3;
    transition: opacity .2s ease-in-out;
    z-index: 1;
    font-size: .8em;
}

div.subpage-side-menu button.subpage-menu-entry > span {
    transition: color .2s ease-in-out;
    font-family: urbane-rounded, sans-serif;
    text-transform: uppercase;
}

div.subpage-side-menu.collapsed button.subpage-menu-entry {
    justify-content: center;
}

div.subpage-side-menu.collapsed button.subpage-menu-entry > span {
    width: 0;
    overflow: hidden;
}

div.subpage-side-menu.collapsed button.subpage-menu-entry > svg {
    margin: 0;
    text-align: center;
}

#main-body.landlord div.subpage-side-menu button.subpage-menu-entry > span {
    color: #ffffff;
}

div.subpage-side-menu button.subpage-menu-entry > svg {
    color: var(--main-accent-color);
    margin-right: .6em;
    transition: color .5s ease-in-out;
}

#main-body.landlord div.subpage-side-menu button.subpage-menu-entry {
    opacity: .4;
}

div.subpage-side-menu button.subpage-menu-entry:hover {
    opacity: .7;
}

#main-body.landlord div.subpage-side-menu button.subpage-menu-entry:hover {
    opacity: .8;
}

div.subpage-side-menu button.subpage-menu-entry.selected {
    opacity: 1 !important;
}

#main-body.landlord div.subpage-side-menu button.subpage-menu-entry > svg {
    color: var(--secondary-accent-color);
}

/*######################################################################################################################
                                            RESPONSIVE SETTINGS
######################################################################################################################*/
@media (min-width: 1200px) {

}

@media only screen and (max-width: 1199px) {


}

@media only screen and (max-width: 950px) {

    div.subpage-side-menu {
        transition: none;
        box-shadow: var(--container-shadow);
        padding: 16px;
    }

    div.subpage-side-menu.collapsed {
        padding: 8px 8px 16px 8px !important;
        width: max-content !important;
    }

    div.subpage-side-menu:not(.expanded) {
        width: max-content !important;
    }

    div.subpage-side-menu:not(.expanded) {
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 12px 0;
        height: max-content;
    }

    div.subpage-side-menu:not(.expanded) > button.small-screen-menu-opener {
        display: initial;
        width: var(--subpage-side-menu-small-screen-collapsed-width) !important;
        height: var(--subpage-side-menu-small-screen-collapsed-width) !important;
    }

    div.subpage-side-menu > div.sticky-menu-button-container > button.menu-collapse {
        display: none;
    }

    div.subpage-side-menu:not(.expanded) > div.sticky-menu-button-container {
        display: none;
    }

    div.subpage-side-menu button.subpage-menu-entry {
        justify-content: left !important;
    }

    div.subpage-side-menu button.subpage-menu-entry > span {
        width: var(--side-menu-width) !important;
    }

    div.subpage-side-menu button.subpage-menu-entry > svg {
        margin-right: .6em !important;
        text-align: left !important;
    }

}

@media only screen and (max-width: 750px) {

}

@media only screen and (max-width: 500px) {


}