.ai-assistant-wrapper {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
}

.ai-assistant {
    position: sticky;
    bottom: 20px;
    padding: 10px;
    display: grid;
    gap: 15px;
    border-radius: 15px;
    background-color: white;
    animation: pulse-red 2s infinite;
    border: 1px solid lightgray;
}

.ai-toggle {
    border: none;
    outline: none;
    color: black;
    justify-self: end;
}

.ai-assistant > .description-container {
    text-align: justify;
}

.ai-assistant > button {
    width: max-content;
}

.ai-assistant > .outline-button {
    justify-self: center;
}