.image-upload-modal div.overlay {
    width: 100%;
    height: 100%;
    background-color: #00000055;
    position: absolute;
    z-index: 1;
    margin: 0;
    top: 0;
    left: 0;
}

.image-upload-modal .modal-body {
    display: grid;
    gap: 20px;
}

.image-upload-modal .image-uploader {
    justify-self: center;
    max-width: 100% !important;
    width: 600px;
    height: 400px;
}

.image-uploader > div.drop-zone {
    border-radius: 20px;
    border: none;
    overflow: hidden;
    width: 600px;
    height: 400px;
}

.image-uploader > div.drop-zone img {
    width: 600px;
    height: 400px;
}

.image-uploader div.drop-zone__placeholder {
    width: 100%;
    background-image: none;
    cursor: pointer;
    position: relative;
    border-radius: 1em;
    background-color: white !important;
    border: 3px lightgrey dashed;
}

.image-uploader .drop-zone__placeholder > svg {
    font-size: 80px;
    line-height: 1.2em;
    color: lightgrey;
    margin-bottom: 20px;
}

.image-uploader .drop-zone__placeholder > svg.fa-ban {
    color: red;
    opacity: .5;
}

.image-uploader .drop-zone__placeholder > svg.fa-circle-check {
    color: forestgreen;
    opacity: .5;
}

.image-uploader .drop-zone__image {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center !important;
}

.image-upload-modal .image-preview-row {
    display: grid;
    gap: 10px;
    grid-auto-columns: 180px;
    grid-auto-flow: column;
    height: max-content;
    overflow-x: auto;
}

.imagePreviewContainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-end;
}

.image-upload-modal .image-preview-row .image-preview {
    width: 180px;
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2e2e2;
    border-radius: 16px;
    overflow: clip;
    gap: 10px;
}

.image-preview.title {
    border: 3px solid var(--tertiary-accent-color);
}

.imageTitle {
    text-align: center;
    text-transform: uppercase;
    font-family: urbane-rounded, sans-serif;
    color: var(--tertiary-accent-color);
    width: 100%;
}

.image-upload-modal .image-preview-row .image-preview > img {
    width: 180px;
    height: 120px;
    object-fit: contain;
    object-position: center;
    background-repeat: no-repeat;
    position: absolute;
}

.image-upload-modal .image-preview-row .image-preview > button {
    display: none;
    z-index: 1;
    border: 1px solid white;
    color: white;
}

.image-upload-modal .image-preview-row .image-preview > button.delete {
    border: 1px solid red;
    color: red
}

.image-upload-modal .image-preview-row .image-preview:hover > img,
.image-upload-modal .image-preview-row .image-preview.sort > img {
    filter: blur(2px) brightness(90%);
}

.image-upload-modal .image-preview-row .image-preview:hover > button {
    display: initial;
}

.image-upload-modal .image-preview-row .image-preview > svg {
    font-size: 2em;
    line-height: 1.2em;
    font-weight: 300;
    color: white;
}

.image-upload-modal .image-preview-row .image-preview > .sort-label {
    color: white;
    font-family: urbane-rounded, sans-serif;
    font-size: 40px;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    background-color: #00000088;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.image-upload-modal .modal-footer .roomjack-headline {
    margin-right: auto;
}

.adder {
    align-self: end;
}